import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-bootstrap';
import './Section2.scss';
import SectionComponent from '../SectionComponent';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import api from '../../services/api';
import { faLess } from '@fortawesome/free-brands-svg-icons';

let DATA = window.localStorage.getItem("Section2") || { headerField: "Youth Volunteers" }

export default class SectionBanner extends SectionComponent {
    constructor(props) {
        super(props)
        this.state = {
            visible: false
        }
    }

    componentDidMount = async () => {
        let res = await api.get(`/api/front/GetBanner`)
        if (res.data.StatusCode == 1) {
            console.log(res.data.Data)
            if (res.data.Data.length > 0) {
                let data = res.data.Data
                this.setState({
                    visible: true,
                    text: data[0].text,
                    video: data[0].video
                })
            }
        }
    }

    handleResize = (e) => {
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;

        return (
            <>
                {this.state.visible && <div style={{ marginTop: "20px" }}>
                    <Container >
                        <iframe width="100%" height="500" src={this.state.video} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
                    </Container>
                </div>}
            </>
        )
    }
}
