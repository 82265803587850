import React, { Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner'

export default class LoaderOverlay extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isReady: false,
            parent: props.parent,
        }
    }
    componentDidMount() {
        this.setState({isReady: true})
    }
    
    render(){
        const state = this.state.parent.state
        return (
            <>
            {state.showLoadingOverlay &&
            <div style={{width: "100vw",height: "100vh",opacity: 0.8,background: "#6f6a6a",zIndex: "99999!important;"}}>
            <Spinner animation="border" style={{marginLeft: "50%",marginTop: "20%"}} />
            </div>
            }
            </>
        )
    }
}