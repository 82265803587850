import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import "./News.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faNewspaper, faStar } from '@fortawesome/free-solid-svg-icons';

import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import StandardHeader from '../components/StandardHeader';
import 'antd/dist/antd.css';
import { Divider, Layout, Menu, Row, Col } from 'antd';
import BasePageComponent from '../components/BasePageComponent'
import qs from 'querystring'
import api from '../services/api';
import { Link, Route } from 'react-router-dom';
import NewsCardList from './NewsCardList'
import moment from 'moment'


export default class News extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            data: [],
            // textEditor
            sections: [],
            sectionName: "News",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            }
            //eof textEditor
        }
    }
    componentDidMount(...args) {
        super.componentDidMount.apply(this,args);
        // this.setState({ isLoaded: true })
        // let url = "http://localhost:62114/api/front/GetNewsInnews";
        // api.get(url).then((res) => {
        //     this.setState({
        //         data: res.data.Data
        //     })
        // })
    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;
        const { Content, Sider } = Layout;
        // let data = this.state.data;

        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />
                    <Container style={{ marginTop: "3%" }} className="newspage-container">
                        <Layout style={{ backgroundColor: "#fff" }}>
                            {!isMobile &&
                            <Sider
                                width='150px'
                                style={{
                                    marginTop: '50px',
                                }}
                            >
                                
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['sub1']}
                                    style={{ height: '100%' }}
                                >
                                    <Menu.Item key="1" icon={<FontAwesomeIcon icon={faNewspaper} />}>
                                        {/* <Link to={`/news/news`}>News</Link> */}
                                        <Link to={`/news/article`}>Articles</Link>
                                    </Menu.Item>
                                    <Menu.Item key="2" icon={<FontAwesomeIcon icon={faFlag} />}>
                                        <Link to={`/news/campaign`}>Campaigns</Link>
                                    </Menu.Item>
                                    <Menu.Item key="3" icon={<FontAwesomeIcon icon={faStar} />}>
                                        <Link to={`/news/newsletter`}>Newsletters</Link>
                                    </Menu.Item>

                                </Menu>
                            </Sider>
                            }
                             <Content style={isMobile ? {padding: '0 0px', backgroundColor: "#fff"} : {padding: '0 24px', backgroundColor: "#fff" }}>
                                <Row>
                                    <Col lg={24} xs={24}>
                                        <div className="newspage-header">LATEST ARTICLES</div>
                                        <Route path="/news/:category" component={NewsCardList} />
                                    </Col>
                                    {/* <Col lg={7} xs={24}>
                                        <div className="newspage-header">IN THE NEWS</div>
                                        <div className="newspage-newslist">
                                            {
                                                Array.from(Array(data.length).keys()).map((i) => (
                                                    <div className="newspage-newslist-card newspage-card-box-shadow" key={i} style={{ marginBottom: "20px" }}>
                                                        <h3>
                                                            <Link to={`/newsdetail?postid=${data[i].postid}`}>
                                                                {data[i].posttitle}
                                                            </Link>
                                                        </h3>
                                                        <Divider style={{ margin: "0" }} />
                                                        <div className="newspage-post-card-bottom">
                                                            <div>{moment(data[i].postmodified).format("MMM Do YYYY")} | Author</div>
                                                        </div>
                                                    </div>

                                                ))
                                            }
                                        </div>

                                    </Col> */}
                                </Row>
                            </Content>
                        </Layout>
                    </Container>

                </div>


            </>
            }
            </>

        )
    }
}