import React, { Component} from 'react';
// import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Section9.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Globals from '../../Globals'


export default class Section9 extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isMobile: window.innerWidth<1024,
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize)   
    }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    render(){
        let isMobile = this.state.isMobile;
        return (
            <>
                <div className="Section9">
                    <div className="section-container">
                        <div className="section-div-header-6">Our Gallery</div>
                        <Carousel 
                            responsive={Globals.carouselEvents.responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            className="carousel-events"
                        >
                            {
                                [0,1,2,3,4,5,6,7,8].map((i) => (
                                    <div className="section-gallery-container" key={i}>
                                        <img className="section-img" src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />
                                        <div className="section-gallery-text">Picture Description</div>
                                    </div>
                                ))
                            }
                            
                        </Carousel>
                    </div>
                
                </div>
            </>
        )
    }
}