import axios from 'axios';
import { getEnvVars,oAuthConfig } from '../Environment.js' // '../../Environment';
import Globals from '../Globals'
// import EventBus from 'react-native-event-bus'

const { apiUrl } = getEnvVars();

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  timeout: 20000
});

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // config.headers.post = {"Content-Type": "application/json"}
  // console.log(JSON.stringify(config))
//   EventBus.getInstance().fireEvent("showLoading")

  if (Globals.userdata.jwt){
    config.headers.post = {"Authorization": Globals.userdata.jwt}
    config.headers.get = {"Authorization": Globals.userdata.jwt}    
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log(response)
//   EventBus.getInstance().fireEvent("hideLoading")
  return Promise.resolve(response)
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // console.log("MIKE", error)
  return Promise.reject(error);
});


export default axiosInstance;

export function ApiGet(apiEndpoint) {
  apiEndpoint = apiUrl + "/api" + apiEndpoint;
  console.log("ApiGet",apiEndpoint)
  return axiosInstance({
    method: 'GET',
    url: apiEndpoint,
    baseURL: apiUrl,
  })
}
