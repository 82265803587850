import React, { Component, Fragment,useEffect, useRef } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Button,Collapse } from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Globals from '../Globals'
import { getEnvVars } from '../Environment'

const { oAuthConfig } = getEnvVars();



export default class MyDrawer extends Component  { 
    constructor(props){
        super(props)
        // console.log("MIKE",props)
        this.state = {
            drawerOpen: props.drawerOpen ? props.drawerOpen : false,
            drawerAboutUs: false,
            drawerServices: false,
            showAboutUs: false,
            showServices: false,
            showPartners: false,
            isMobile: window.innerWidth<992,
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }
    componentWillReceiveProps = (props)=>{
        if (props.hasOwnProperty('drawerOpen')){
            this.setState({drawerOpen: props.drawerOpen})
        }
    }
    openMenu=()=>{
        this.setState({drawerOpen:!this.state.drawerOpen})
        this.props.openMenu()
    }
    openDrawerAboutUs=()=>{
        this.setState({drawerAboutUs:!this.state.drawerAboutUs})
    }
    openDrawerServices=()=>{
        this.setState({drawerServices:!this.state.drawerServices})
    }
    handleResize =(e)=>{
        // console.log(window.innerWidth)
        this.setState({isMobile:window.innerWidth<992})
    }
    openUrl = (e, svc)=>{
        // console.log(e.target.innerText)
        if (e.target.innerText=="Home"){
            window.location.href = '/'
        }else if (e.target.innerText=="About Us"){
            window.location.href = '/aboutus'
        }else if (e.target.innerText=="Programmes"){
            window.location.href = '/events'
        }else if (e.target.innerText=="Workshops"){
            window.location.href = '/workshops'
        }else if (e.target.innerText=="Latest Happenings"){
            window.location.href = '/news/article'
        }else if (svc){
            window.location.href = `${svc}`
        }
        else{
            window.location.href = '/home'
        }
    }
    logOut = ()=>{
        window.localStorage.removeItem("@user")
        window.location.reload()
    }
    openAdmin = ()=>{
        window.location.href = oAuthConfig.adminUrl + `/login?t=${Globals.userdata.token}&p=/app/account/volunteeredit&redirect=1`
    }
    openMyAssessment = ()=>{
        window.location.href = `/questionnaire`
    }
    render(){
        const classes = makeStyles({});
        let drawerOpen = this.state.drawerOpen;
        let link1 = "/youthbank_logo3-4.png";
        return (
            <>
            {this.state.isMobile && 
            <Drawer
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                >
                <Backdrop className={classes.backdrop} open={drawerOpen} onClick={this.openMenu}>
                </Backdrop>
                <img style={{width:"80%",marginTop:30,marginLeft:20}} src={link1} />
                <List>
                    <ListItem>
                        <ListItemText primary="Home" onClick={(e) => this.openUrl(e)}/>
                    </ListItem>
                    <ListItem onClick={this.openUrl}>
                        <ListItemText primary="About Us"  />
                    </ListItem>

                    {/* <ListItem>
                        <ListItemText primary="About Us" onClick={this.openDrawerAboutUs}/>
                        {this.state.drawerAboutUs ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.drawerAboutUs} timeout="auto" unmountOnExit style={{marginTop:"50px"}}>
                        <List component="div" disablePadding>
                            <ListItem onClick={this.openUrl}>
                                <ListItemText primary="About Us" className="indent-menu" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Our Team" className="indent-menu"/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Corporate Partners" className="indent-menu"/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Social Community Partners" className="indent-menu"/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="School Partners" className="indent-menu"/>
                            </ListItem>
                        </List>
                    </Collapse> */}
                    <ListItem>
                        <ListItemText primary="Services" onClick={this.openDrawerServices}/>
                        {this.state.drawerServices ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.drawerServices} timeout="auto" unmountOnExit style={{marginTop:"50px"}}>
                    
                        <List component="div" disablePadding>
                            {Globals.servicesMenu.map((d)=>(
                                 <ListItem>
                                 <ListItemText primary={d.text} className="indent-menu" onClick={(e) => this.openUrl(e, d.url)} />
                                </ListItem>
                            ))}
                            {/* <ListItem>
                                <ListItemText primary="[HWTP] Heartware Tuition Programme" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-tuition-programme")} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWLTP] Heartware Learning Together Programme" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-learning-together-programme")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWSOP] Heartware Support Our Pioneers" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-support-our-pioneers-programme")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWSOP (DLW)] Digital Learning Workshop" className="indent-menu" onClick={(e) => this.openUrl(e, "digital-learning-workshop")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWSOP (MDC)] Mobile Digital Clinic" className="indent-menu" onClick={(e) => this.openUrl(e, "mobile-digital-clinic")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-NDP] Heartware - National Day Parade" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-national-day-parade")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-RHB] Heartware - River Hong Bao" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-river-hong-bao")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-PC] Heartware - President's Challenge" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-presidents-challenge")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-SGCares] Youth Meet You" className="indent-menu" onClick={(e) => this.openUrl(e, "youth-meet-you")}/>
                            </ListItem>
                            

                            <ListItem>
                                <ListItemText primary="[HWA] Volunteer Facilitator Associate Club" className="indent-menu" onClick={(e) => this.openUrl(e, "volunteer-facilitator-associate-club")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWA] Field Leader Associate Club" className="indent-menu" onClick={(e) => this.openUrl(e, "field-leader-associate-club")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWA] Media Associate Club" className="indent-menu" onClick={(e) => this.openUrl(e, "media-associate-club")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWA] Mentorship Associate Club" className="indent-menu" onClick={(e) => this.openUrl(e, "mentorship-associate-club")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HWA] Education Fund" className="indent-menu" onClick={(e) => this.openUrl(e, "education-fund")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-CCE] Heartware - CCE Leadership Programme" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-cce-leadership-programme")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[HW-CCE] Heartware Apprenticeship" className="indent-menu" onClick={(e) => this.openUrl(e, "heartware-apprenticeship")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="[YBS] Youth Business Singapore" className="indent-menu" onClick={(e) => this.openUrl(e, "youth-business-singpore")}/>
                            </ListItem>                                                        
                            <ListItem>
                                <ListItemText primary="[YE] Internship" className="indent-menu" onClick={(e) => this.openUrl(e, "internship")}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Others" className="indent-menu" onClick={(e) => this.openUrl(e, "others")}/>
                            </ListItem> */}
                        </List>
                    </Collapse>
                    <ListItem>
                        <ListItemText primary="Programmes" onClick={(e) => this.openUrl(e)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Workshops" onClick={(e) => this.openUrl(e)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Latest Happenings" onClick={(e) => this.openUrl(e)}/>
                    </ListItem>
                    { Globals.userdata.accountid && 
                    <>
                    <ListItem>
                        <ListItemText primary={Globals.userdata.role == 'VLT' ? 'My Profile' : 'My Account'} onClick={this.openAdmin} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="My Assessment" onClick={this.openMyAssessment} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Logout" onClick={this.logOut}/>
                    </ListItem>
                    </>
                    }
                </List>
                {/* <ListItemText primary="Services" />
                        <ListItemText primary="Events" />
                        <ListItemText primary="Trainings" />
                        <ListItemText primary="Latest Happenings" /> */}

                {/* <List>
                    {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                        <ListItem button key={text} onClick={this.openMenu}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <InboxIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List> */}
            </Drawer>
            }
            </>
        )
    }
}