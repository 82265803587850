import BasePageComponent from '../components/BasePageComponent'
import Button from 'react-bootstrap/Button';
import api from '../services/api';
import { nominationData } from '../data/nominationData';
import Globals from '../Globals'
import { getEnvVars } from '../Environment'
import { Modal, Select } from 'antd';
import "./Statement.scss"

const { apiUrl } = getEnvVars();
let temporaryFilesStorage = {}

export default class Statement extends BasePageComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 1024,
            modules: [],
            eventid: null
        }
    }

    async componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        const params = new URLSearchParams(window.location.search)

        let acctGuid = Globals.userdata.guid  //"f40bb6ea-11db-4d08-ad5a-3302cdc493ec" 
        // alert(acctGuid)
        let res = await api.get(`/api/Front/GetAttendedChildEvent?accountguid=${acctGuid}`)
        this.setState({ modules: res.data.Data })
        console.log(res.data.Data)
    }

    SendEmail = async (e) => {
        e.preventDefault();
        var info = {
            reportnum: "VIA_01",
            params: [
                {
                    paraname: "@eventid",
                    parareplace: this.state.eventid,
                    type: "int"
                },
                {
                    paraname: "@volunteerid",
                    parareplace: Globals.userdata.accountid,
                    type: "int"
                }
            ],
            givenname: Globals.userdata.givenname,
            email: Globals.userdata.email
        }
        var result = await api.post('/api/Front/SendVIALetter', info);
        if (result) {
            if (result.data.StatusCode == 1) {
                this.setState({
                    MessageModalVisible: true,
                    ModalMessage: "VIA letter has been sent to your email address."
                })
            }
            else {
                window.alert(result.data.Message);
            }
        }
    }

    View = async (e) => {
        e.preventDefault()
        var info = {
            reportnum: "VIA_01",
            params: [
                {
                    paraname: "@eventid",
                    parareplace: this.state.eventid,
                    type: "int"
                },
                {
                    paraname: "@volunteerid",
                    parareplace: Globals.userdata.accountid,
                    type: "int"
                }
            ]
        };
        var result = await api.post(`api/report/GetFormReport`, info);
        if (result) {
            const bytes = atob(result);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            const file = new Blob([out], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    }

    render() {
        return super.render(
            <>
                <div class="Statement">


                    <h1>My Statement</h1>
                    <h4>VIA Letter</h4>
                    <Select
                        placeholder="Please select event"
                        allowClear
                        onChange={(e) => { this.setState({ eventid: e }) }}
                    >
                        {this.state.modules.map((d) => {
                            return <Select.Option value={d.eventid}>{d.eventname}</Select.Option>
                        })}
                    </Select>
                    {/* <Button style={{marginLeft:"10px", marginRight:"10px"}} onClick={this.View}>View</Button> */}
                    <Button style={{ marginLeft: "10px", marginRight: "10px" }} onClick={this.SendEmail}>Send To Email</Button>

                </div >
                <Modal
                    visible={this.state.MessageModalVisible}
                    footer={[
                        <Button key="back" onClick={() => { this.setState({ MessageModalVisible: false }) }}>OK</Button>]}
                    onCancel={() => { this.setState({ isMessageModalVisible: false }) }}
                >
                    <p>{this.state.ModalMessage}</p>
                </Modal>
            </>

        )
    }
}