import BasePageComponent from '../components/BasePageComponent'
import qs from 'querystring'
import api from '../services/api';
import "./Disclaimer.scss"


export default class EventDisclaimer extends BasePageComponent {
    constructor(props) {
        super(props);
        this.state={
            data: {
                disclaimer: ''
            }
        }
    }

    componentDidMount(...args) {

        super.componentDidMount.apply(this, args);
        // let account = JSON.parse(window.atob(window.localStorage.getItem("@user"))).accountid
        // const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
        // console.log('test', account);
        // console.log('testdecode',window.atob(test))
        this.setState({ isLoaded: true })
        // Restore coming from API
        const { search } = this.props.location;
        const { eventid } = qs.parse(search.slice(1))
        let url = "/api/front/GetEventDetailDisclaimer?eventid=" + eventid;
        console.log('url', url)
        api.get(url).then((res) => {
            this.setState({
                data: res.data.Data[0],
                eventid: eventid,
            })
            console.log('componentDidMount', res.data.Data)
        })       
    }

    render() {
        const { isMobile,data } = this.state;
        return super.render(
            <div className="Disclaimer">
            <h1>Disclaimer</h1>
            <div dangerouslySetInnerHTML={{ __html: data.disclaimer }}></div>
            </div>
        )
    }
}