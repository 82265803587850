import React, { Component } from 'react';
// import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-bootstrap';
import './Section8.scss';
import SectionComponent from '../SectionComponent';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Globals from '../../Globals'


let DATA = window.localStorage.getItem("Section8") || {headerField: "Youth Volunteers"}

export default class Section8 extends SectionComponent {
    constructor(props) {
        super(props)
        this.state = {
            sectionName: "HomeSection8",
            initialized: false,
            isMobile: window.innerWidth < 1024,
            bindSectionOnInput: props.BindSectionOnInput,
            data: typeof DATA == "string" ? JSON.parse(DATA) : DATA,
            parent: props.parent,
            attributes: {
                data1: [{ id: 1, headerText: "", contentText: "", image: "" }], // Contents
            } // Structure ONLY if no backend
        }
    }

    handleResize = (e) => {
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    openURL = (e,header) => {
        if (header.includes("HWTP"))
            window.location.href=`service/heartware-tuition-programme`;
        else if (header.includes("HWSOP"))
            window.location.href=`service/heartware-support-our-pioneers-programme`;
        else if (header.includes("HWA"))
            window.location.href=`service/heartware-academy`;
        else if (header.includes("YBS"))
            window.location.href=`service/youth-business-singpore`;
        else if (header.includes("CCE"))
            window.location.href=`service/heartware-cce-leadership-programme`;
        else if (header.includes("Learning Together"))
            window.location.href=`service/heartware-learning-together-programme`
        else
            window.location.href=`service/others`;
    }
    render() {
        let isMobile = this.state.isMobile;
        // Binding
        const thisSection = this;
        console.log(this.state.attributes.data1)
        // eof binding
        return (
            <>
                <div className="Section8">
                    <div className="section-container">
                        <div className="section-div-header-6">Services</div>
                        <Carousel 
                            responsive={Globals.carouselEvents.responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            className="carousel-events"
                        >
                            {
                               this.state.attributes.data1.map((d,i) => (
                                    <div className="section-service-card" key={i}>
                                        <img src={d.image} />
                                        <div className="section-service-card-header"> {d.headerText} </div>
                                        <p className="section-service-card-description">{d.contentText}</p>
                                        <a onClick={(e)=>this.openURL(e,d.headerText)}>Learn More &gt;&gt;</a>
                                    </div>
                                ))
                            }

                        </Carousel>
                    </div>

                </div>
            </>
        )
    }
}