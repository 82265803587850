import React, { Component} from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope,faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Footer.scss';
import api from '../services/api'
import _ from 'underscore/underscore'


export default class Footer extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isLoaded: false,
            isMobile: window.innerWidth<1024,
            sectionName: "FooterSection",
            attributes: { data1: [{ id: 1, text: "",url: "","type":"" }] }
        }
        let sectionName = this.state.sectionName
        let sections = this.props.parent.state.sections 
        if (sections){
            sections[sectionName] = this
            this.props.parent.setState({sections:sections})
        }
    }
    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)   

        let res = await api.get(`/api/Common/Getpage?pagename=FooterSection`)
        if (res.data.StatusCode==1){
            if (res.data.Data.length>0){
                console.log("componentDidMount",res.data.Data[0].jsonpage)
                let data = JSON.parse(res.data.Data[0].jsonpage)
                this.setState({
                    attributes: data,
                    isLoaded: true
                })
            }
        }

    }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    getFieldFromType =(type,field) =>{
        return _.where(this.state.attributes.data1,{type:type})[0][field]
    }
    render(){
        let isMobile = this.state.isMobile;
        return (
            <>
            {this.state.isLoaded && 
            <>
                <div className="Footer">
                <Container className="container-nopad-nomargin footer">
                <Row>
                        <Col lg="3" xs="12" className="footer-column">
                            <img style={{width:"50%"}} src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/youthbank_logo3-4-300x76.png" />
                            <div className="section-footer-icon-text"><a href={"tel:" + this.getFieldFromType('tel','text').replace(' ','')} style={{color:"#cacaca"}}><FontAwesomeIcon icon={faPhone}/> {this.getFieldFromType('tel','text')}</a></div>
                            <div className="section-footer-icon-text"><a href={"mailto:" + this.getFieldFromType('email','text')} style={{color:"#cacaca"}}> <FontAwesomeIcon icon={faEnvelope}/> {this.getFieldFromType('email','text')} </a></div>
                            <div className="section-footer-icon-text"><FontAwesomeIcon icon={faMapMarkerAlt}/> {this.getFieldFromType('address','text')}</div>
                        </Col>
                        <Col lg="3" xs="6" className="footer-column">
                            <div className="section-footer-subheader">GENERAL</div>
                            <a href={this.getFieldFromType('aboutus','url')}><div className="section-footer-links">{this.getFieldFromType('aboutus','text')}</div></a>
                            <a href={this.getFieldFromType('contactus','url')}><div className="section-footer-links">{this.getFieldFromType('contactus','text')}</div></a>
                            <a href={this.getFieldFromType('faq','url')}><div className="section-footer-links">{this.getFieldFromType('faq','text')}</div></a>
                            <a href={this.getFieldFromType('privacypolicy','url')}><div className="section-footer-links">{this.getFieldFromType('privacypolicy','text')}</div></a>
                        </Col>
                        <Col lg="3" xs="6" className="footer-column">
                            <div className="section-footer-subheader">WHAT WE DO</div>
                            {/* <a href={this.getFieldFromType('services','url')}><div className="section-footer-links">{this.getFieldFromType('services','text')}</div></a> */}
                            <a href="/serviceslist"><div className="section-footer-links">{this.getFieldFromType('services','text')}</div></a>
                            <a href={this.getFieldFromType('programmes','url')}><div className="section-footer-links">{this.getFieldFromType('programmes','text')}</div></a>
                            <a href={this.getFieldFromType('workshops','url')}><div className="section-footer-links">{this.getFieldFromType('workshops','text')}</div></a>
                            <a href={this.getFieldFromType('latesthappening','url')}><div className="section-footer-links">{this.getFieldFromType('latesthappening','text')}</div></a>
                        </Col>
                        <Col lg="3" xs="12" className="footer-column">
                            <div className="section-footer-subheader">Download Youthbank from</div>
                            <a href="https://apps.apple.com/sg/app/youthbank-v2/id1589713212">
                            <img src="https://ydms-public.s3.ap-southeast-1.amazonaws.com/assets/download-app-store.png" className="img-apple-download"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.app.youthbank">
                            <img src="https://ydms-public.s3.ap-southeast-1.amazonaws.com/assets/download-google-play.png" className="img-android-download"/>
                            </a>
                        </Col>
                        
                        {/* <Col lg="3" xs="12" className="footer-column">
                            <div className="section-footer-subheader">NEWSLETTER</div>
                            <div className="section-footer-div-input"><input placeholder="Name" className="news-letter-input" /> </div>
                            <div className="section-footer-div-input"><input placeholder="Your Email" className="news-letter-input" /> </div>
                            <button className="button-yellow-subscribe">SUBSCRIBE</button>
                        </Col> */}
                    </Row>
                </Container>
                
                </div>
            </>
            }
            </>
        )
    }
}