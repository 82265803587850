import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Events.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faChalkboardTeacher, faBookOpen, faFlag, faHandHoldingHeart, faStar, faStarOfLife, faAngleRight, faSearch, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import StandardHeader from '../components/StandardHeader';
import api from '../services/api';
import { Typography, Form, Input, Button, DatePicker, Select, Tooltip, Radio } from 'antd';
import BasePageComponent from '../components/BasePageComponent'
import { Link } from 'react-router-dom';
import { offset } from 'dom-helpers';
import { S3_PUBLIC_URL } from './common'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class Events extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            offset: 6,
            query: "",
            tabquery: "",
            category: [{ itemname: 'All', itemcode: 'event01' }],
            defaultTab: 'event01',
            activeTab: 'event01',
            filteredStatus: 'recruiting',
            // textEditor
            sections: [],
            sectionName: "ProgrammesListing",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            },
            searchEventname: "",
            searchStartdate: "",
            searchEnddate: ""
            //eof textEditor
        }
    }
    
    componentDidMount(...args) {
        super.componentDidMount.apply(this,args);
        // Restore coming from API
        const { category } = this.state;
        api.get("/api/front/GetEventCategory").then((res) => {
            console.log(res.data.Data);
            var result = res.data.Data.sort(function(cat1,cat2) {
                var cat1name = cat1.itemname.toUpperCase();
                var cat2name = cat2.itemname.toUpperCase();
                if(cat1name == 'OTHERS')
                    return 1;
                if(cat2name == 'OTHERS')
                    return -1;
                if(cat1name < cat2name)
                    return -1;
                if(cat1name > cat2name)
                    return 1;
                return 0;
            })
            console.log('category',res.data.Data);
            this.setState({
                category: [...category, ...result]
            })
            console.log('category',this.state.category);
        })

        api.get("/api/front/GetEvents?limit=6&query="+ btoa("eventtype = 'VOLUNTEER'")+"&filteredStatus="+this.state.filteredStatus).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data,
                query: "eventtype = 'VOLUNTEER'"
            })
        })
    }
    componentWillReceiveProps = (props) => {

    }
    onFinish = (values) => {
        console.log("values...",values)
        
        let typequery = "eventtype = 'VOLUNTEER'"
        let query = ""
        const isMobile = window.innerWidth < 1024
        if (isMobile){
            let eqry = this.state.searchEventname == "" ? " and eventname like '%%'" : " and LOWER(eventname) like LOWER('%" + this.state.searchEventname + "%')";
            let sdateqry = this.state.searchStartdate == "" ? "" : " and startdate >='" + this.state.searchStartdate.format("YYYYMMDD") + "'";
            let edateqry = this.state.searchEnddate == "" ? "" : " and enddate <='" + this.state.searchEnddate.format("YYYYMMDD") + "'";
            query = typequery + eqry + sdateqry + edateqry;
        }else{
            let eqry = values.eventname == null ? " and eventname like '%%'" : " and LOWER(eventname) like LOWER('%" + values.eventname + "%')";
            let sdateqry = values.startdate == null ? "" : " and startdate >='" + values.startdate.format("YYYYMMDD") + "'";
            let edateqry = values.enddate == null ? "" : " and enddate <='" + values.enddate.format("YYYYMMDD") + "'";
            query = typequery + eqry + sdateqry + edateqry;
        }
        // alert(query)
        let url = "/api/front/GetEvents?limit=6&query=" + btoa(query) + "&filteredStatus="+this.state.filteredStatus;
        console.log(query);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data,
                query: query,
                offset: 6,
                activeTab: 'event01'
            })
        })
    };

    onLoadMore = () => {
        console.log('loadmore');
        let { data, offset, query, tabquery} = this.state;
        let originalQry = query;
        query += tabquery
        let url = "/api/front/GetEvents?limit=6&offset=" + offset + "&query=" + btoa(query) + "&filteredStatus="+this.state.filteredStatus;
        console.log('loadmoreQry',query);
        console.log('loadmoreUrl',url);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: [...data, ...res.data.Data],
                offset: offset + 6,
                query: originalQry,
                tabquery: tabquery,
            })
        })
    }

    onTabClick = (e) => {
        console.log('onTabClick', e);
        let { query, tabquery } = this.state;
        let originalQry = query;
        tabquery = (e == "event01") ? "" : " and category='" + e + "'";
        query += tabquery;
        console.log('onTabClick-tabquery', tabquery);
        console.log('onTabClick-query', query);
        let url = "/api/front/GetEvents?limit=6&query=" + btoa(query) + "&filteredStatus="+this.state.filteredStatus;
        console.log(url);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data,
                query: originalQry,
                tabquery: tabquery,
                activeTab: e,
                offset: 6
            })
        })
    }

    handleSearchChange = (e)=>{
        let name = e.target.name
        this.setState({[name]: e.target.value});
    }

    handleSearchDateChange = (name,v)=>{
        this.setState({[name]: v});
    }

    handlefilteredStatusChange = (e) =>{
        let { query } = this.state;
        /* if (e.target.value == "recruiting")
        {
            query = query + " and recruitmentenddate > now()";
        }
        else
        {
            query = query.replace("recruitmentenddate > now()", "");
        } */
        let url = "/api/front/GetEvents?limit=6&query=" + btoa(query)+"&filteredStatus="+e.target.value;
        console.log(url);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                filteredStatus: e.target.value,
                data: res.data.Data,
                offset: 6
            })
        })
    }

    render() {
        let { data, category, defaultTab, activeTab } = this.state;
        console.log(data, defaultTab, activeTab);
        const { Text } = Typography;

        // const [ellipsis, setEllipsis] = React.useState(true);
        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />
                    <div className="event-filter-search-form">
                        <Form
                            layout="inline"
                            size="large"
                            onFinish={this.onFinish}
                        // form={form}
                        >
                            <Radio.Group value={this.state.filteredStatus} onChange={this.handlefilteredStatusChange} style={{marginRight:"10px"}}>
                                <Radio.Button value="recruiting">Ongoing Recruitment</Radio.Button>
                                <Radio.Button value="all">All</Radio.Button>
                            </Radio.Group>
                            <Form.Item name="eventname">
                                <Input placeholder="Programme Name" />
                            </Form.Item>
                            <Form.Item name="startdate">
                                <DatePicker placeholder="Start Date" />
                            </Form.Item>
                            <Form.Item name="enddate">
                                <DatePicker placeholder="End Date" />
                            </Form.Item>
                            <Form.Item>
                                <Tooltip title="search">
                                    <Button type="primary" htmlType="submit" icon={<FontAwesomeIcon icon={faSearch} />}
                                        style={{ background: "#54595F", borderColor: "#54595F" }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </div>
                    
                    <div className="event-filter-search-form-mobile">
                        <Row className="event-filter-search-form-row">
                            <Col xs="10" className='event-filter-search-form-col'>
                                <Input placeholder="Programme Name" name="searchEventname" value={this.state.searchEventname} onChange={this.handleSearchChange}/>
                            </Col>
                            <Col xs="1" className='event-filter-search-form-col'>
                                <Button type="primary" htmlType="submit" icon={<FontAwesomeIcon icon={faSearch} />}
                                        style={{ background: "#54595F", borderColor: "#54595F" }} onClick={this.onFinish}
                                    />
                            </Col>
                        </Row>
                        <Row className="event-filter-search-form-row">
                            <Col xs="5" className='event-filter-search-form-col'>
                                <DatePicker placeholder="Start Date" value={this.state.searchStartdate} onChange={(value, e) => this.handleSearchDateChange("searchStartdate",value)}/>
                            </Col>
                            <Col xs="5" className='event-filter-search-form-col'>
                                <DatePicker placeholder="End Date" name="searchEnddate" value={this.state.searchEnddate} onChange={(value, e) => this.handleSearchDateChange("searchEnddate",value)}/>
                            </Col>
                        </Row>
                        
                    </div>
                    

                    <Container style={{ marginTop: "2%" }} className='section-div-event-title-text-container'>
                        <div className="section-div-event-title-text">Programmes</div>
                    </Container>


                    <Container className="event-category-container">
                        <Tabs
                            defaultActiveKey={defaultTab}
                            activeKey={activeTab}
                            tabPosition='top'
                            onTabClick={this.onTabClick}
                            className='event-category-tabs'
                        >
                            {
                                // [...Array.from({ length: 18 }, (v, i) => i)].map(i => (
                                category.map(item => (
                                    <TabPane
                                        key={item.itemcode}
                                        tab={
                                            <div className="event-category-tab-card">
                                                <FontAwesomeIcon icon={faChalkboardTeacher} className="event-category-icon" />
                                                <div className="event-category-text">{item.itemname}</div>
                                            </div>
                                        }
                                    // disabled={index === 28}
                                    >
                                        {/* Section main listing */}
                                        <Container style={{ padding: '0 0' }}>
                                            <Row>
                                                {
                                                    // [0,1,2,3,4,5,6,7,8,9,10].map((i) => (
                                                    Array.from(Array(data.length).keys()).map((i) => (
                                                        <Col lg="6" xs="12" key={i} style={{ marginTop: "5%" }}>
                                                            <Container>
                                                                <Row className="section-div-image-list-row">
                                                                    <Col lg="7" xs="12" className="col-no-gap section-div-image-list-col">
                                                                        <div className="section-div-image-list">
                                                                            {/* <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Copy-of-NDP5-Youth-Leadership-p9t7crjxwm80oywqpsmpa86huqn4m6a6wirn5modcw.jpg" /> */}
                                                                            <img src={data[i].imageurl == null ? "https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Copy-of-NDP5-Youth-Leadership-p9t7crjxwm80oywqpsmpa86huqn4m6a6wirn5modcw.jpg" : S3_PUBLIC_URL + data[i].imageurl} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="5" xs="12" className="col-no-gap section-div-image-list-col">
                                                                        <div className="section-div-text-list" style={i % 3 == 0 ? { background: "#d41b1a" } : i % 3 == 1 ? { background: "#2c6eac" } : { background: "#eec108" }}>   {/*background:"#2c6eac"(blue) background:"#eec108"(yellow) */}
                                                                            <div className="section-div-header-list">
                                                                                {/* River Hongbao */}
                                                                                {data[i].eventname}
                                                                            </div>
                                                                            <div
                                                                                className="section-div-content-list"
                                                                            // dangerouslySetInnerHTML={{ __html: data[i].eventdescription }}
                                                                            >
                                                                                {/* <Text
                                                            style={ellipsis ? { width: 100 } : undefined}
                                                            ellipsis={ellipsis ? { tooltip: 'I am ellipsis now!' } : false}
                                                        >
                                                            {data[i].eventdescription}
                                                        </Text> */}
                                                                                {data[i].eventdescription.replace('</p>','\n').replace(/<[^>]+>/g, '').replace(/&nbsp;/g,'')}
                                                                            </div>
                                                                            <div className="section-div-content-list-button-container">
                                                                                {/* <a className="section-div-content-list-button" href="/eventdetail">See Details <FontAwesomeIcon icon={faAngleRight} /></a> */}
                                                                                <Link
                                                                                    className="section-div-content-list-button"
                                                                                    to={data[i].ptype == 'opp' ? `/opportunitydetail?oppid=${data[i].eventid}` : `/eventdetail/?eventid=${data[i].eventid}`}
                                                                                >
                                                                                    See Details <FontAwesomeIcon icon={faAngleRight} />
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Container>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Container>

                                        <div className="col-center-content" style={{ marginTop: "60px", marginBottom: "60px" }}>
                                            <button onClick={this.onLoadMore} className="button-load-more-orange">Load More</button>
                                        </div>

                                    </TabPane>
                                ))
                            }
                        </Tabs>

                    </Container>

                    {/* Section category button */}
                    {/* <Container className="section-event-category-container">
                    <Row>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faChalkboardTeacher} className="section-event-category-icon" />
                                <div className="section-event-category-text"> Tuition Program</div>
                            </a>
                        </Col>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faBookOpen} className="section-event-category-icon" />
                                <div className="section-event-category-text"> Learning Together</div>
                            </a>
                        </Col>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faHandHoldingHeart} className="section-event-category-icon" />
                                <div className="section-event-category-text"> Support of Pioneers</div>
                            </a>
                        </Col>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faFlag} className="section-event-category-icon" />
                                <div className="section-event-category-text"> National Day Parade</div>
                            </a>
                        </Col>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faStarOfLife} className="section-event-category-icon" />
                                <div className="section-event-category-text"> River Hongbao</div>
                            </a>
                        </Col>
                        <Col lg="2" xs="4" className="section-event-category-col">
                            <a href="">
                                <FontAwesomeIcon icon={faStar} className="section-event-category-icon" />
                                <div className="section-event-category-text"> President's Challenge</div>
                            </a>
                        </Col>
                    </Row>
                </Container> */}

                </div>


            </>
            }
            </>

        )
    }
}