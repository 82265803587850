const ENV = {
  dev: {
    apiUrl: 'http://188.188.189.92:62114',
      // apiUrl: 'http://192.168.18.68:62114',
        // apiUrl: 'http://localhost:62114',
    // apiUrl: 'https://devapi.youthbank.sg',
    // apiUrl: 'https://ydmsapi.bizpoint-intl.com',
    oAuthConfig: {
      // issuer: 'https://ydmsapi.bizpoint-intl.com',
      issuer: 'http://188.188.189.92:62114',
      // issuer: 'http://192.168.18.68:62114',
        // issuer: 'http://localhost:62114',
      // issuer: 'https://devapi.youthbank.sg',
      clientId: 'BookStore_App',
      clientSecret: '1q2w3e*',
      scope: 'offline_access ABPBlazorServer',
      adminUrl: 'http://localhost:8081'
    },
  },
  prod_biz: {
    apiUrl: 'https://api-test.youthbank.sg',
    oAuthConfig: {
      issuer: 'https://api-test.youthbank.sg',
      clientId: 'BookStore_App',
      clientSecret: '1q2w3e*',
      scope: 'offline_access ABPBlazorServer',
      adminUrl: 'https://admin-test.youthbank.sg'
    },
  },
  prod_ydms: {
    apiUrl: 'https://devapi.youthbank.sg',
    oAuthConfig: {
      issuer: 'https://devapi.youthbank.sg',
      clientId: 'BookStore_App',
      clientSecret: '1q2w3e*',
      scope: 'offline_access ABPBlazorServer',
      adminUrl: 'https://devadmin.youthbank.sg'
    },
  },
};

export const getEnvVars = () => {
  // eslint-disable-next-line no-undef
  // let env = __DEV__ ? ENV.dev : ENV.prod
  let apiEndpoint = ENV.dev
  if (window.location.href.includes("youthbank.sg")){
    apiEndpoint = ENV.prod_ydms
  }
  if (window.location.href.includes("web-test")) {
    apiEndpoint = ENV.prod_biz
  }
  // console.log("Environment",env)
  return apiEndpoint;
};

export const isMaintenance = false;