import React, { Component, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Routes from './Routes'
import api from './services/api';
import { GlobalContext } from './Globals'

// Remove the console if production mode
if (window.location.href.includes("youthbank.sg")) {
  console.log = function () {};
}


// export default function App() {
export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appConst: []
    }
  }
  componentDidMount = () => {
    this.FnGetConstants();
  }

  FnGetConstants = () => {
    api.get("api/Mobile/GetAppConst").then((res) => {
      this.setState({
        appConst: res.data.Data
      })

    })
  }

  render() {
    return (
      <div>
        <GlobalContext.Provider value={this.state.appConst}>
        <Router>
          <Routes />
        </Router>
        </GlobalContext.Provider>
      </div>
    );
  }

}


window.mySession = {
  secondsToExpire: 1800, //1800=>30 minutes
  timer: null,
  key: "@mysessionvalidator",
  start(reset) {
    localStorage.setItem(this.key,Date.parse(new Date())/1000)
    if (reset && this.timer!=null){
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(()=> {
      localStorage.removeItem(this.key);
      clearTimeout(this.timer)
      window.location.reload()
    }, this.secondsToExpire*1000)
  },
  reset(){
    this.start(true)
  },
  isSessionValid(){
    let start = localStorage.getItem(this.key)
    if (start==null){
      return false
    }
    let now = (Date.parse(new Date())/1000) - parseInt(start)
    if (now>=this.secondsToExpire){
      localStorage.removeItem(this.key)
      return false;
    }
    return true; 
  },
}