import React, { Component, Fragment,useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown,faSignInAlt,faCaretRight,faUser } from '@fortawesome/free-solid-svg-icons'
import zIndex from '@material-ui/core/styles/zIndex';
import Globals from '../Globals'
import {Container,Navbar,Nav,NavDropdown, OverlayTrigger, Tooltip} from 'react-bootstrap';
import "./MyMenu.scss"
import { getEnvVars } from '../Environment'
import api from '../services/api'
import _ from 'underscore/underscore'

const { oAuthConfig } = getEnvVars();


export default class MyMenu extends Component  { 
    
    constructor(props){
        super(props)
        this.state = {
            isScrolling: false,
            showAboutUs: false,
            showServices: false,
            showPartners: false,
            isMobile: window.innerWidth<992,
            parent: props.parent,
            sectionName: "MenuServices",
            attributes: { data1: [{ id: 1, text: "",url: "",type:"" }] },
            youthdeveloment: [],
            youthleadership: [],
            youthentrepreneur: []
        }

        let sectionName = this.state.sectionName
        let sections = this.props.parent.state.sections 
        if (sections){
            sections[sectionName] = this
            this.props.parent.setState({sections:sections})
        }

    }

    async componentDidMount() {
        document.addEventListener('scroll', this.handleScroll)
        window.addEventListener('resize', this.handleResize)

        let res = await api.get(`/api/Common/Getpage?pagename=MenuServices`)
        if (res.data.StatusCode==1){
            if (res.data.Data.length>0){
                console.log("componentDidMount",res.data.Data[0].jsonpage)
                let data = JSON.parse(res.data.Data[0].jsonpage)
                Globals.servicesMenu = data.data1;
                let youthdeveloment = _.where(data.data1,{type:"youthdevelopment"})
                let youthleadership = _.where(data.data1,{type:"youthleadership"})
                let youthentrepreneur = _.where(data.data1,{type:"youthentrepreneurship"})
                this.setState({
                    attributes: data,
                    youthdeveloment: youthdeveloment,
                    youthleadership: youthleadership,
                    youthentrepreneur: youthentrepreneur
                })
                console.log("datatdadfsfadsf",Globals.servicesMenu)
            }
        }

    }

    handleScroll =(e)=>{
        //console.log(e)
        if (window.pageYOffset>200){
            this.setState({isScrolling:true})
        }else{
            this.setState({isScrolling:false})
        }
        
    }

    onMouseEnterAboutUs=()=>{
        this.setState(
            {
                showAboutUs:true,
                showServices:false
            })
    }
    onMouseEnterServices=()=>{
        this.setState(
            {
                showAboutUs:false,
                showPartners:false,
                showServices:true
            })
    }
    onMouseEnterPartners=()=>{
        this.setState(
            {
                showPartners:true
            })
    }
    onShowAboutUs=()=>{
        if (!this.state.showPartners){
            setTimeout(()=>{
                this.setState({showAboutUs:false})
            },0)
        }
    }
    onShowServices=()=>{
        setTimeout(()=>{
            this.setState({showServices:false})
        },0)
    }
    onShowPartners=()=>{
        setTimeout(()=>{
            this.setState({showAboutUs:false,showPartners:false})
        },0)
    }

    handleResize =(e)=>{
        // console.log(window.innerWidth)
        this.setState({isMobile:window.innerWidth<992})
    }
    Login = ()=>{
        this.state.parent.setState({modalLogin:true})
    }
    Signup = ()=>{
        this.state.parent.setState({modalSignup:true})
    }
    openUrl = (e, svc)=>{
        // console.log(typeof e.target)
        this.setState(
        {
            showAboutUs:false,
            showPartners:false,
            showServices:false
        })
        if (e.target.innerText=="About Us"){
            window.location.href = '/aboutus'
        }else if (e.target.innerText=="Programmes"){
            window.location.href = '/events'
        }else if (e.target.innerText=="Workshops"){
            window.location.href = '/workshops'
        }else if (e.target.innerText=="Latest Happenings"){
            window.location.href = '/news/article'
        }else if (e.target.innerText=="Interested?"){
            window.location.href = '/interestsurvey'
        }else if (svc){
            //window.location.href = `/service/${svc}`
            window.location.href = `${svc}`
        }
        else{
            window.location.href = '/home'
        }
    }
    logOut = ()=>{
        api.get(`/api/mobile/DestroyToken`).then(res => {
            if (res.data.StatusCode==1){
                window.localStorage.removeItem("@user")
                window.location.reload()
            }
        })
    }
    openAdmin = ()=>{
        window.location.href = oAuthConfig.adminUrl + `/login?t=${Globals.userdata.token}&p=/app/account/volunteeredit&redirect=1`
    }
    openMyAssessment = ()=>{
        window.location.href = `/questionnaire`
    }
    openMyStatement = () =>{
        window.location.href = `/statement`
    }
    render(){
        const isScrolling = this.state.isScrolling;
        const isNotHomepage = !window.location.pathname.includes('home') && window.location.pathname != "/"
        let styles = {
            headerContainer:{
                width:"100%",
                height: "40px",
                background:"transparent",
                textAlign:"center",
                paddingLeft:"25%",
                position:"absolute",
                top:"35px",
                zIndex:9999,
                color:"white",
                fontSize:"16px"
            },
            headerContainerFixed:{
                width:"100%",
                height: "88px", 
                background:"white",
                textAlign:"center",
                paddingLeft:"25%",
                position:"fixed",
                top:"0px",
                zIndex:9999,
                color:"black",
                paddingTop:"30px", 
                boxShadow: "0px 0px 10px 0px rgb(0 0 0 / 29%)",
                transition: "background-color 0.5s ease,box-shadow 0.5s ease",
            },
            headerMenuFixed: {
                display: "inline-block",
                marginRight:"20px",
                textShadow: "none",
                cursor:"url(hand.cur), pointer"
            },
            menuAboutUs:{
                position:"absolute",
                top:"60px",
                left: document.getElementById("headerMenuAboutUs") ? document.getElementById("headerMenuAboutUs").getBoundingClientRect().x : "1070px",
                zIndex:9999,
                background:"white",
                width:"250px",
                height:"109px",
                padding: "0px 0px 0px 0px",
                borderTop: "4px solid #fc0",
                borderLeft: "0px solid #fc0",
                borderRight: "0px solid #fc0",
                borderBottom: "0px solid #fc0",
                background: "#fdfdfd",
                borderRadius: 0,
                fontFamily: "'ProximaNova', Sans-serif !important",
                fontSize:16,
                paddingLeft:10,
                paddingTop:5,
                lineHeight:"30px",
                color:"grey",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)"
            },
            menuServices:{
                position:"absolute",
                top:"60px",
                left:"10%",
                zIndex:99999,
                background:"white",
                width:"70%",
                // height:"293px",
                padding: "0px 0px 0px 0px",
                borderTop: "4px solid #fc0",
                borderLeft: "0px solid #fc0",
                borderRight: "0px solid #fc0",
                borderBottom: "0px solid #fc0",
                background: "#fdfdfd",
                borderRadius: 0,
                fontFamily: "'ProximaNova', Sans-serif !important",
                fontSize:16,
                paddingLeft:10,
                paddingTop:5,
                lineHeight:"30px",
                color:"grey",
                paddingLeft:"20px",
                paddingTop:"15px",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)",
                marginLeft:"10%",
                paddingBottom:"20px"
            },
            menuServicesColumn: {
                color:"red",
                display:"inline-block",
                width: "33%",
                fontWeight:"600",
                verticalAlign:"top"
            },
            menuServicesHR:{
                margin: "5px",
                width: "90%",
                color: "grey"
            },
            headerMenu: {
                display: "inline-block",
                marginRight:"20px",
                textShadow: "rgb(98 98 98) 3px 3px 2px",
                cursor:"url(hand.cur), pointer"
            },
            headerMenuProfile:{
                display: "inline-block",
                marginRight:"20px",
                textShadow: "rgb(98 98 98) 3px 3px 2px",
                cursor:"url(hand.cur), pointer",
                marginLeft:"10px"
            },
            subMenu: {
                cursor:"url(hand.cur), pointer",
                color:"grey",
                fontFamily: "'ProximaNova', Sans-serif !important",
                fontSize:16,
                fontWeight: 300,
                lineHeight:"32px",
            },
            arrowDown:{
                marginLeft:"10px",
                position:"relative",
                top:"0px",
            },
            subMenuRed: {
                cursor:"url(hand.cur), pointer",
                color:"red",
                fontFamily: "'ProximaNova', Sans-serif !important",
                fontSize:16,
                fontWeight: 300,
                lineHeight:"32px",
            },
            buttonYellowLogin:{
                top:"30px"
            },
            buttonRedSignup:{
                top:"30px"
            },
            arrowRight: {
                position:"relative",
                right:"-130px",
                top:"3px"
            },
            menuPartners:{
                position:"absolute",
                top:"127px",
                left: document.getElementById("headerMenuAboutUs") ? document.getElementById("headerMenuAboutUs").getBoundingClientRect().x + 250 : "1320px",
                zIndex:9999,
                background:"white",
                width:"250px",
                height:"109px",
                padding: "0px 0px 0px 0px",
                borderTop: "4px solid #fc0",
                borderLeft: "0px solid #fc0",
                borderRight: "0px solid #fc0",
                borderBottom: "0px solid #fc0",
                background: "#fdfdfd",
                borderRadius: 0,
                fontFamily: "'ProximaNova', Sans-serif !important",
                fontSize:16,
                paddingLeft:10,
                paddingTop:5,
                lineHeight:"30px",
                color:"grey",
                boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 50%)"
            },
        }
        styles.menuAboutUs.position = isScrolling ? "fixed" : "absolute";
        styles.menuServices.position = isScrolling ? "fixed" : "absolute";
        styles.menuPartners.position = isScrolling ? "fixed" : "absolute";
        
        return (
            <>
            {!this.state.isMobile && 
            <Fragment>
            <div className="headerMenuContainer" style={isScrolling || isNotHomepage ? styles.headerContainerFixed : styles.headerContainer}>
                {(isScrolling || isNotHomepage) && 
                    <img style={{width:"15%",top:"10px",cursor:"url(hand.cur), pointer",}} className="ydmslogo-menu" src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/youthbank_logo3-4.png" onClick={this.openUrl}/>
                }
                {/* {(isScrolling || isNotHomepage) && !Globals.userdata.accountid && <React.Fragment>
                    <button className="button-red-signup-menu" style={styles.buttonRedSignup} onClick={this.Signup}>Sign Up</button>
                    <button className="button-yellow-login-menu" style={styles.buttonYellowLogin} onClick={this.Login}>
                        Login
                        <FontAwesomeIcon icon={faSignInAlt}/>
                    </button>
                    </React.Fragment>
                } */}
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onClick={this.openUrl}>About Us</div>
                {/* <div id="headerMenuAboutUs" style={isScrolling ? styles.headerMenuFixed : styles.headerMenu} onMouseEnter={this.onMouseEnterAboutUs} >
                        About Us<FontAwesomeIcon style={styles.arrowDown} icon={faCaretDown}/></div> */}
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onMouseEnter={this.onMouseEnterServices}>Services
                    <FontAwesomeIcon style={styles.arrowDown} icon={faCaretDown}/></div>
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onClick={this.openUrl}>Programmes</div>
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onClick={this.openUrl}>Workshops</div>
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onClick={this.openUrl}>Latest Happenings</div>
               {/*  <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenu} onClick={this.openUrl}>Interested?</div> */}
                {Globals.userdata.givenname && 
                <div style={isScrolling || isNotHomepage ? styles.headerMenuFixed : styles.headerMenuProfile}>
                    <Navbar  expand="lg" className="profileNavBar">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="navbar-dark-example" />
                        <Navbar.Collapse>
                        <Nav >
                            <NavDropdown
                            title={<><FontAwesomeIcon icon={faUser} style={{color:"#ffcb0a"}}/> <span style={{
                                color: isScrolling || isNotHomepage ? "black" : "white"
                            }}>Welcome {Globals.userdata.givenname}</span></>}>
                            <NavDropdown.Item onClick={this.openAdmin} data-toggle="tooltip" title="Manage your account">{Globals.userdata.role == 'VLT' ? 'My Profile' : 'My Account'}</NavDropdown.Item>
                            <NavDropdown.Item onClick={this.openMyAssessment} data-toggle="tooltip" title="Access your surveys and assessments">My Assessment</NavDropdown.Item>
                            <NavDropdown.Item onClick={this.openMyStatement} data-toggle="tooltip" title="Access your VIA report">My Statement</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={this.logOut}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                    </Navbar>
                </div>
                }
            </div>
            {this.state.showAboutUs && 
                <div style={styles.menuAboutUs} onMouseLeave={this.onShowAboutUs} >
                    <div style={styles.subMenu} onClick={this.openUrl}>About Us</div>
                    <div style={styles.subMenu}>Our Team</div>
                    <div style={styles.subMenu} onMouseEnter={this.onMouseEnterPartners}>
                        Our Partners
                        <FontAwesomeIcon style={styles.arrowRight} icon={faCaretRight}/>
                    </div>
                </div>
            }
            {this.state.showServices && 
                <div style={styles.menuServices} onMouseLeave={this.onShowServices} >
                    <div style={styles.menuServicesColumn}>
                        YOUTH DEVELOPMENT
                        <hr style={styles.menuServicesHR}/>

                        {this.state.youthdeveloment.map((d)=>(
                            <div style={styles.subMenu} onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
                        ))}
                        {/* <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-tuition-programme")}>[HWTP] Heartware Tuition Programme</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-learning-together-programme")}>[HWLTP] Heartware Learning Together Programme</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-support-our-pioneers-programme")}>[HWSOP] Heartware Support Our Pioneers</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "digital-learning-workshop")}>[HWSOP (DLW)] Digital Learning Workshop</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "mobile-digital-clinic")}>[HWSOP (MDC)] Mobile Digital Clinic</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-national-day-parade")}>[HW-NDP] Heartware - National Day Parade</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-river-hong-bao")}>[HW-RHB] Heartware - River Hong Bao</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-presidents-challenge")}>[HW-PC] Heartware - President's Challenge</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "youth-meet-you")}>[HW-SGCares] Youth Meet You</div> */}
                    </div>
                    <div style={styles.menuServicesColumn}>
                        YOUTH LEADERSHIP
                        <hr style={styles.menuServicesHR}/>
                        {this.state.youthleadership.map((d)=>(
                            <div style={styles.subMenu} onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
                        ))}

                        {/* <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "volunteer-facilitator-associate-club")}>[HWA] Volunteer Facilitator Associate Club</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "field-leader-associate-club")}>[HWA] Field Leader Associate Club</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "media-associate-club")}>[HWA] Media Associate Club</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "mentorship-associate-club")}>[HWA] Mentorship Associate Club</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "education-fund")}>[HWA] Education Fund</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-cce-leadership-programme")}>[HW-CCE] Heartware - CCE Leadership Programme</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-apprenticeship")}>[HW-CCE] Heartware Apprenticeship</div> */}
                        
                        {/* <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "heartware-academy")}>Heartware - Academy</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "training")}>Training</div>    
                       */}
                    </div>
                    <div style={styles.menuServicesColumn}>
                        YOUTH ENTREPRENEURSHIP
                        <hr style={styles.menuServicesHR}/>
                        {this.state.youthentrepreneur.map((d)=>(
                            <div style={styles.subMenu} onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
                        ))}
                        {/* <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "youth-business-singpore")}>[YBS] Youth Business Singapore</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "internship")}>[YE] Internship</div>
                        <div style={styles.subMenu} onClick={(e) => this.openUrl(e, "others")}>Others</div> */}
                    </div>
                </div>
            }
            {this.state.showPartners &&
             <div style={styles.menuPartners} onMouseLeave={this.onShowPartners}>
                 <div style={styles.subMenu}>Corporate Partners</div>
                 <div style={styles.subMenu}>Social Community Partners</div>
                 <div style={styles.subMenu}>School Partners</div>
             </div>
            }
          </Fragment>
          }
          </>
        )
    }
}

