import React, { Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Section5.scss';
import SectionComponent from '../SectionComponent';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Globals from '../../Globals'


let DATA = window.localStorage.getItem("Section5") || {headerField: "Youth Volunteers"}

export default class Section5 extends SectionComponent  { 
    constructor(props){
        super(props)
        this.state = {
            sectionName: "HomeSection5",
            initialized: false,
            isMobile: window.innerWidth<1024,
            bindSectionOnInput: props.BindSectionOnInput,
            data: typeof DATA == "string" ? JSON.parse(DATA) : DATA,
            parent: props.parent,
            attributes: {
                data1: [{id:1,headerText: "",contentText: "", image:""}], // Contents
                data2: [{id:1,image: "",line1Text: "",line2Text:""}] // Circle Image
            } // Structure ONLY if no backend
        }
    }
    // componentDidMount() {
    //     // require('../../pages/Home.css')
    //     window.addEventListener('resize', this.handleResize) 

    //     console.log("componentDidMount",this)

    //     let sectionName = this.state.sectionName
    //     let sections = this.props.parent.state.sections 
    //     sections[sectionName] = this
    //     this.props.parent.setState({sections:sections})
    // }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    render(){
        let isMobile = this.state.isMobile;
        for (let i in document.querySelectorAll(".section-div-text-1")){
            if (document.querySelectorAll(".section-div-text-1")[i].style!==undefined){
                if (isMobile){
                    // document.querySelectorAll(".section-div-text-1")[i].style.height = (document.getElementsByClassName("section-div-text-1")[i].scrollHeight + 10) + "px"
                    // document.querySelectorAll(".section-div-text-1")[i].style.maxHeight = (document.getElementsByClassName("section-div-text-1")[i].scrollHeight + 10) + "px"

                }else{
                    // document.querySelectorAll(".section-div-text-1")[i].style.height = document.querySelectorAll(".section-div-image img")[i].offsetHeight + "px"
                    // document.querySelectorAll(".section-div-text-1")[i].style.maxHeight = document.querySelectorAll(".section-div-image img")[i].offsetHeight + "px"
                }
                
            }
        }

        // Binding
        const thisSection = this;
        // eof binding
        return (
            <>
                <div className="Section5">
                 <Container >
                    <Row>
                        <Col lg="6" xs="12" style={{padding:"0px"}}>
                            <div className="section-div-image">
                                <img src={this.state.attributes.data1[0].image}/>
                            </div>
                        </Col>
                        <Col lg="6" xs="12" style={{padding:"0px"}}>
                            <div className="section-div-text-1" style={{height:"auto"}}>
                                <div className="section-div-header" >
                                    {this.state.attributes.data1[0].headerText}
                                </div>
                                <div className="section-div-content">
                                    {this.state.attributes.data1[0].contentText} If you wish to donate to our cause, you may do so via this <a href="https://heartware.org/get-involved/make-an-online-donation/make-a-donation/">link</a>.
                                </div>
                                <Carousel 
                                        responsive={Globals.carouselCircle.responsive}
                                        // infinite={true}
                                        // autoPlay={true}
                                        // autoPlaySpeed={1000}
                                    >
                                        {this.state.attributes.data2.map((d,i)=>(
                                            <div key={i}>
                                                <img src={d.image} className="section-img" />
                                                <div className="section-div-text1">{d.line1Text}</div>
                                                <div className="section-div-text2">{d.line2Text}</div>
                                            </div>
                                        ))}
                                    </Carousel>
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
                </div>
            </>
        )
    }
}