import React, { Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import api from '../services/api';
import { getEnvVars } from '../Environment'

const { oAuthConfig } = getEnvVars();

export default class ModalMessage extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isReady: false,
            parent: props.parent,
        }
    }
    componentDidMount() {
        this.setState({isReady: true})
    }
    handleClose = ()=>{
        this.props.parent.setState({modalMessage: false})
    }
    
    handleOK = ()=>{
        this.handleClose()
    }
    render(){
        const state = this.state.parent.state
        return (
            <>
            {this.state.isReady && 
            <Modal show={state.modalMessage} onHide={this.handleClose} dialogClassName="ModalMessage" centered>
              {/* <Modal.Header closeButton>
                <Modal.Title>Sign Up Successfully</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <Form>
                {state.msg}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleOK}>
                    Got It!
                </Button>
              </Modal.Footer>
            </Modal>
            }
            </>
        )
    }
}