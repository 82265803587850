import BasePageComponent from '../components/BasePageComponent'
import "./Imsg.scss"
import Button from 'react-bootstrap/Button';
import api from '../services/api';
import moment from 'moment';

export default class Imsg extends BasePageComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 1024,
            message: ""
        }
    }
    
    async componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        const params = new URLSearchParams(window.location.search)
        let m = params.get("m");
        m = atob(m)
        this.setState({message:m})
    }

    render() {
        const { isMobile,message } = this.state;
        return super.render(
            <div className="Imsg">
                <p>{message}</p>
            </div>
        )
    }
}