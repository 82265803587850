import BasePageComponent from '../components/BasePageComponent'
import "./AttendanceTaking.scss"
import Button from 'react-bootstrap/Button';
import api from '../services/api';
import moment from 'moment';

export default class AttendanceTaking extends BasePageComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 1024,
            at:"",
            eventid:"",
            accountid: "",
            data:{},
            error: true,
            showButton: false
        }
    }
    
    async componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        const params = new URLSearchParams(window.location.search)
        const at = params.get("at");
        const eventid = params.get("eventid");
        const accountid = params.get("accountid");
        if (at==null || eventid==null || accountid==null){
            alert("Invalid QR Code Link")
            return
        }
        let res = await api.get(`/api/Mobile/getStaticQRInfo?qrcode=${at}&eventid=${eventid}&accountid=${accountid}`)
        if (res.data.StatusCode==1){
            var now = moment(); 
            var end = moment(moment().format("YYYY-MM-DD " + res.data.Data[0]['starttime']));
            var duration = moment.duration(now.diff(end));
            var minutes = duration.asMinutes();
            // console.log(minutes)
            this.setState({
                at:at,
                eventid:eventid,
                data:res.data.Data[0],
                accountid: accountid,
                error: false,
                showButton: (minutes>=-30)
            })
        }else{
            alert(res.data.Message)
        }
    }

    clockIn = async ()=>{
        if (this.state.error){
            alert("There is no action/function available")
            return
        }
        let param = {
            activityid: this.state.data.activityid,
            eventid: parseInt(this.state.eventid),
            accountid: parseInt(this.state.accountid)
        }
        let res = await api.post(`/api/Mobile/clockInOut`,param)
        console.log(res.data)
        if (res.data.StatusCode==1){
            alert("Successfully clock in/out")
        }else{
            alert(res.data.Message)
        }
    }

    render() {
        const { isMobile,data } = this.state;
        return super.render(
            <div className="AttendanceTaking">
                <h1>Attendance Taking</h1>
                <div className="welcome-header">{"Welcome " + data.givenname}!</div>
                <div className="sub-header">Actvity </div><div className="sub-header-text">: {data.activityname}</div><br/>
                <div className="sub-header">Event </div><div className="sub-header-text">: {data.eventname}</div><br/>
                <div className="sub-header">Time </div><div className="sub-header-text">: {data.starttime} - {data.endtime}</div><br/>
                <hr/>
                {this.state.showButton && 
                <>
                <p>Please click clock in/out for your attendance</p>
                <Button onClick={this.clockIn}>Clock IN/OUT</Button>
                </>
                }
                {!this.state.showButton &&
                <>
                <p>Clock IN time starts at 30minutes before the Activity starts</p>
                </>
                }
            </div>
        )
    }
}