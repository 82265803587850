import React, { Component} from 'react';
import Modal from 'react-bootstrap/Modal'
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalFooter from "react-bootstrap/ModalFooter";
// import ModalTitle from "react-bootstrap/ModalTitle";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import api from '../services/api';
import { getEnvVars } from '../Environment'
import { GlobalContext } from '../Globals'
import { data } from '@jsonforms/examples/lib/arrays';

const { oAuthConfig } = getEnvVars();

export default class ModalLogin extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isReady: false,
            parent: props.parent,
            email: "",
            pasword: "",
            invalidLogin: false,
            isForgotpass: false,
            resetPasswordMessage:"",
            password1:"",
            password2:"",
            passwordShown: false,
            ui: "login",
            pwdotpstep : 0,
            pwtotp: "",
            time: {},
            seconds: 100,
            twoFA: "",
            twoFAMessage: ""
        }
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

    }

    secondsToTime(secs){
        let hours = Math.floor(secs / (60 * 60));
    
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
    
        let obj = {
          "h": hours,
          "m": minutes,
          "s": seconds
        };
        return obj;
      }

    componentDidUpdate(prevProps) {
        // console.log("componentDidUpdate",this.state.parent)
        if (prevProps.parent.state.resetPasswordToken){
            if (prevProps.parent.state.resetPasswordToken.length>0 && this.state.ui!=="resetpass"){
                this.setState({ui:"resetpass"})
            }
        }
    }
    componentDidMount() {
        this.setState({isReady: true})

        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    startTimer() {
        this.setState({seconds: 100});
        clearInterval(this.timer);
        this.timer = 0;
        if (this.timer == 0 && this.state.seconds > 0) {
          this.timer = setInterval(this.countDown, 1000);
        }
      }

      countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds == 0) { 
          clearInterval(this.timer);
        }
      }

    handleClose = ()=>{
        this.props.parent.setState(
            {
                modalLogin: false,
            }
        )
        this.setState({ui:"login"})
    }
    togglePassword = (e) => {
        e.preventDefault();
        this.setState({passwordShown: !this.state.passwordShown})
    };
    Login = async ()=>{
        let email = this.state.email;
        let password = this.state.password;
        let param = { email: email, password: password}
        let url = oAuthConfig.issuer + "/api/Authenticate/Login"
        try{
            this.props.parent.setState({showLoadingOverlay: true})
            this.props.parent.setState({modalLogin: false})
            setTimeout(() => {
                let res = api.post(url, param)
                    .then((d) => {
                        this.props.parent.setState({ showLoadingOverlay: false })
                        if (d.data.StatusCode == 1) {
                            window.mySession.start();
                            if (d.data.Message == "success#True") {
                                let accountData = d.data.Data[0]
                                accountData = btoa(JSON.stringify(accountData))
                                window.localStorage.setItem("@user--", accountData)
                                this.setState({ ui: "2fa" })
                                this.props.parent.setState({ modalLogin: true })
                            } else {
                                let accountData = d.data.Data[0]
                                accountData = btoa(JSON.stringify(accountData))
                                window.localStorage.setItem("@user", accountData)
                                this.state.parent.setState({ modalLogin: false })
                                this.setState({ invalidLogin: false })
                                window.location.reload()
                            }
                        }
                        else {
                            this.setState({ invalidLogin: true })
                        }
                    })
                    .then((res2) => {
                        this.props.parent.setState({ showLoadingOverlay: false })
                    })
                    .catch((err) => {
                        this.props.parent.setState({ showLoadingOverlay: false })
                        console.log("ERROR-MIKE3: ", err)
                        this.setState({ invalidLogin: true })
                        this.props.parent.setState({ modalLogin: true })
                    })
                console.log(res)
            }, 300)
            
        }catch(err){
            console.log("ERROR-MIKE: ",err)
            this.props.parent.setState({invalidLogin: true})
        }
        
        // if (res.data.access_token){
        //     url = oAuthConfig.issuer + `/api/Mobile/GetAccountByEmail?email=${email}`
        //     res = await api.get(url)
        //     let accountData = res.data.Data[0]
        //     accountData = btoa(JSON.stringify(accountData))
        //     window.localStorage.setItem("@user",accountData)
        //     this.props.parent.setState({modalLogin: false,invalidLogin:false})
        // }else{
        //     this.props.parent.setState({invalidLogin: true})
        // }


        // api({
        //     method: 'POST',
        //     url: '/connect/token',
        //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        //     data: `grant_type=password&username=${email}&password=${password}&client_id=${oAuthConfig.clientId}&client_secret=${oAuthConfig.clientSecret}`,
        //     baseURL: oAuthConfig.issuer,
        //   }).then(({ data })=>{
        //     console.log(data)
        //   }).catch((err)=>{
        //     console.log("ERR",err.response.status)
        //     if (err.response.status = "400"){
             
        //     }
        //   })

    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    forgotPass = ()=>{
        // this.setState({isForgotpass: !this.state.isForgotpass})
        this.setState({ui:"forgotpass"})
    }
    sendRequestPassword = ()=>{
        let email = this.state.email;
        // this.props.parent.setState({showLoadingOverlay: true})
        // this.props.parent.setState({modalLogin: false})

        // setTimeout(()=>{
        //     let url = oAuthConfig.issuer + `/api/Mobile/ResetPassword?email=${email}`
        //     api.get(url).then((d)=>{
        //         this.props.parent.setState({showLoadingOverlay: false})
        //         if (d.data.StatusCode==0){
        //             this.props.parent.setState({
        //                 modalLogin: true,
        //             })
        //             this.setState({ resetPasswordMessage: d.data.Message})
        //         }else{
        //             this.props.parent.setState({modalLogin: false})
        //             alert("Please check your email and click on the link to change password")
        //         }
        //     })
        // },300)
        

        this.setState({ pwdotpstep: 1});
        this.startTimer();

        let url = oAuthConfig.issuer + `/api/Mobile/GetPwdOTP?email=${email}`
        api.get(url).then((d)=>{
            console.log(d);

            /*window.mySession.start();
            if (d.data.Message=="success#True"){
                let accountData = d.data.Data[0]
                accountData = btoa(JSON.stringify(accountData))
                window.localStorage.setItem("@user--",accountData)
                this.setState({ui:"2fa"})
                this.props.parent.setState({modalLogin: true})
            }else{
                let accountData = d.data.Data[0]
                accountData = btoa(JSON.stringify(accountData))
                window.localStorage.setItem("@user",accountData)
                this.state.parent.setState({modalLogin: false})
                this.setState({invalidLogin:false})
                window.location.reload()
            }*/
        })
    }

    submitOTPPassword = ()=>{
        let email = this.state.email;
        let otp = this.state.pwtotp;
        let url = oAuthConfig.issuer + `/api/Mobile/SubmitOTP?email=${email}&otp=${otp}`
        api.get(url).then((d)=>{
            console.log(d);
            if (d.data.StatusCode != 1){
                this.setState({resetPasswordMessage: d.data.Message});
            }
            else{
                var base_url = window.location.origin;
                window.location.href = `/?reset=${d.data.Message}`;
                //this.props.history.push(`?reset=${d.data.Message}`);
            }
            /*window.mySession.start();
            if (d.data.Message=="success#True"){
                let accountData = d.data.Data[0]
                accountData = btoa(JSON.stringify(accountData))
                window.localStorage.setItem("@user--",accountData)
                this.setState({ui:"2fa"})
                this.props.parent.setState({modalLogin: true})
            }else{
                let accountData = d.data.Data[0]
                accountData = btoa(JSON.stringify(accountData))
                window.localStorage.setItem("@user",accountData)
                this.state.parent.setState({modalLogin: false})
                this.setState({invalidLogin:false})
                window.location.reload()
            }*/
        })
    }

    resetPassword = ()=>{
        if (this.state.password1!=this.state.password2){
            alert("Password and Confirm password is not thesame please check")
            return
        }
        // let email = atob(this.state.parent.state.resetPasswordToken)
        // email = email.split("#")[1]
        let key = this.state.parent.state.resetPasswordToken
        let url = oAuthConfig.issuer + `/api/Mobile/UpdateAccountPassword?k=${key}`
        let param = {newpass: this.state.password2}
        api.post(url,param)
            .then((res)=>{
                if (res.data.StatusCode==1){
                    alert("Password Successfully Updated, please try to login")
                }else{
                    alert("Token already expire, please try to request again")
                }
                this.state.parent.setState({resetPasswordToken:"",modalLogin:false})
                var base_url = window.location.origin;
                window.location.href = base_url;
            })
    }

    twoFASubmit = ()=>{
        if (this.state.twoFA==""){
            alert("Please enter your OTP 2FA")
            return
        }
        const email = this.state.email
        const url = oAuthConfig.issuer + `/api/Mobile/Verify2fa?email=${email}&otp=${this.state.twoFA}` 
        api.get(url).then((d)=>{
            if(d.data.StatusCode==0){
                this.setState({twoFAMessage:d.data.Message})
            }else{
                const accountData = window.localStorage.getItem("@user--")
                window.localStorage.setItem("@user",accountData)
                window.localStorage.removeItem("@user--")
                this.state.parent.setState({modalLogin: false})
                this.setState({invalidLogin:false})
                window.location.reload()
            }
        })
    }
    submitDoNothing = (event, callback)=>{
        event.preventDefault();
    }

    render(){
        const state = this.state.parent.state
        const globalContext = this.context;
        return (
            <>
            {this.state.isReady && 
            <Modal show={state.modalLogin} onHide={this.handleClose} dialogClassName="ModalLogin" variant="primary" backdrop="static">
              <Modal.Header closeButton>
                {this.state.ui=="login" &&  
                <Modal.Title>Please login your credentials</Modal.Title>
                }
                {this.state.ui=="forgotpass" &&  
                <Modal.Title>Request reset password</Modal.Title>
                }
                {this.state.ui=="resetpass" &&  
                <Modal.Title>Reset Password</Modal.Title>
                }
                 {this.state.ui=="2fa" &&  
                <Modal.Title>2 Factor Authentication</Modal.Title>
                }
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={this.submitDoNothing}>
                    {/* {!this.state.isForgotpass && !state.resetPasswordToken && */}
                    {this.state.ui=="login" &&
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" type="email" placeholder="Enter email" onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Password</Form.Label>
                            <div style={{display:"flex"}}>
                            <Form.Control name="password" type={this.state.passwordShown ? "text" : "password"} placeholder="Password" onChange={this.handleChange}/>
                            {this.state.passwordShown ? <button className="eye-button" onClick={this.togglePassword}><i className="fa fa-eye"></i></button>:<button className="eye-button" onClick={this.togglePassword}><i className="fa fa-eye-slash"></i></button>}
                            </div>
                        </Form.Group>
                        {(globalContext.length >0 && globalContext.find(element => element.syscode == 'LOGINTEXT')) &&
                        <p>{globalContext.find(element => element.syscode == 'LOGINTEXT').value}</p>}
                        <Button variant="link" style={{textDecoration:"none"}} onClick={this.forgotPass}>Forgot password</Button>
                        {this.state.invalidLogin && 
                            <p style={{color:"red"}}>Invalid Login, please try again</p>
                        }
                    </>
                    }
                    {/* {this.state.isForgotpass && !state.resetPasswordToken && */}
                    {this.state.ui=="forgotpass" &&
                        <>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" type="email" placeholder="Enter email" onChange={this.handleChange}/>
                            {this.state.pwdotpstep >= 1 &&
                                <p style={{color:"blue",marginTop:"10px"}}>If a matching account is found, an OTP will be sent to this email.</p> 
                            }
                            {this.state.pwdotpstep >= 1 && <>
                            <Form.Label>OTP:</Form.Label>
                            <Form.Control name="pwtotp" placeholder="Enter OTP" onChange={this.handleChange}/>
                            <p style={{color:"blue",marginTop:"10px"}}><h4>Time remaining: {this.state.time.m} minute(s), {this.state.time.s} second(s)</h4></p> 
                            </>}
                            {this.state.resetPasswordMessage.length>0 && 
                                <p style={{color:"red",marginTop:"10px"}}>{this.state.resetPasswordMessage}</p>    
                            }
                        </Form.Group>
                        </>
                    }
                    {/* {state.resetPasswordToken &&  */}
                    {this.state.ui=="resetpass" &&
                        <>
                        <Form.Group className="mb-3" >
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password1" type="password" placeholder="Password" onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control name="password2" type="password" placeholder="Confirm Password" onChange={this.handleChange}/>
                        </Form.Group>
                        {/* {state.resetPasswordToken} */}
                        </>
                    }
                    {/* {this.state.isForgotpass && !state.resetPasswordToken && */}
                    {this.state.ui=="2fa" &&
                        <>
                        <Form.Group className="mb-3">
                            <Form.Label>Enter your OTP 2FA</Form.Label>
                            <Form.Control name="twoFA" type="text" placeholder="" onChange={this.handleChange}/>
                            {this.state.twoFAMessage.length>0 && 
                                <p style={{color:"red",marginTop:"10px"}}>{this.state.twoFAMessage}</p>    
                            }
                        </Form.Group>
                        </>
                    }
                </Form>
              </Modal.Body>
              <Modal.Footer>
                {/* {!this.state.isForgotpass && !state.resetPasswordToken &&  */}
                {this.state.ui=="login" &&
                <Button variant="secondary" onClick={this.Login}>
                    Login
                </Button>
                }
                {/* {this.state.isForgotpass && !state.resetPasswordToken && */}
                {this.state.ui=="forgotpass" &&
                <>
                <Button variant="secondary" onClick={this.sendRequestPassword}>
                    {this.state.pwdotpstep >= 1 ? 'Resend OTP' : 'Send OTP'}
                </Button>
                {this.state.pwdotpstep >= 1 && this.state.seconds > 0 && <Button variant="secondary" onClick={this.submitOTPPassword}>
                    Submit
                </Button>
                }
                </>
                }
                {this.state.ui=="resetpass" &&
                <Button variant="secondary" onClick={this.resetPassword}>
                    Submit
                </Button>
                }
                {this.state.ui=="2fa" &&
                <Button variant="secondary" onClick={this.twoFASubmit}>
                    Submit
                </Button>
                }
              </Modal.Footer>
            </Modal>
            }
            </>
        )
    }
}
ModalLogin.contextType = GlobalContext;