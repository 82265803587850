import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Events.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faChalkboardTeacher, faBookOpen, faFlag, faHandHoldingHeart, faStar, faStarOfLife, faAngleRight, faSearch, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import StandardHeader from '../components/StandardHeader';
import api from '../services/api';
import { Typography, Form, Input, Button, DatePicker, Select, Tooltip } from 'antd';
import BasePageComponent from '../components/BasePageComponent'
import { Link } from 'react-router-dom';
import { offset } from 'dom-helpers';
import { S3_PUBLIC_URL } from './common'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export default class Training extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            offset: 6,
            query: "",
            // textEditor
            sections: [],
            sectionName: "Workshops",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            }
            //eof textEditor
            // tabquery: "",
            // category: [{ itemname: 'All', itemcode: 'event01' }],
            // defaultTab: 'event01',
            // activeTab: 'event01'
        }
    }
    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        // this.setState({ isLoaded: true })
        // Restore coming from API
        // const { category } = this.state;
        // api.get("/api/front/GetEventCategory").then((res) => {
        //     console.log(res.data.Data);
        //     this.setState({
        //         category: [...category, ...res.data.Data],
        //     })
        // })

        api.get("/api/front/GetEvents?limit=6&query=" + btoa("eventtype = 'TRAINING'")).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data,
                query: "eventtype = 'TRAINING'"
            })
        })
    }
    componentWillReceiveProps = (props) => {

    }
    onFinish = (values) => {
        console.log('Success:', values);
        let typequery = "eventtype = 'TRAINING'"
        let eqry = values.eventname == null ? " and eventname like '%%'" : " and LOWER(eventname) like LOWER('%" + values.eventname + "%')";
        let sdateqry = values.startdate == null ? "" : " and startdate >='" + values.startdate.format("YYYYMMDD") + "'";
        let edateqry = values.enddate == null ? "" : " and enddate <='" + values.enddate.format("YYYYMMDD") + "'";
        let query = typequery + eqry + sdateqry + edateqry;
        let url = "/api/front/GetEvents?limit=6&query=" + btoa(query);
        console.log(query);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data,
                query: query,
                offset: 6,
            })
        })
    };

    onLoadMore = () => {
        console.log('loadmore');
        const { data, offset, query } = this.state;
        let url = "/api/front/GetEvents?limit=6&offset=" + offset + "&query=" + btoa(query);
        console.log(url);
        api.get(url).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: [...data, ...res.data.Data],
                offset: offset + 6,
                query: query
            })
        })
    }

    // onTabClick = (e) => {
    //     console.log('onTabClick', e);
    //     let { query, tabquery } = this.state;
    //     let originalQry = query;
    //     tabquery = (query == "") ? "category='" + e + "'" : " and category='" + e + "'";
    //     query += tabquery;
    //     console.log('onTabClick-tabquery', tabquery);
    //     console.log('onTabClick-query', query);
    //     let url = "/api/front/GetEvents?limit=6&query=" + btoa(query);
    //     console.log(url);
    //     api.get(url).then((res) => {
    //         console.log(res.data.Data);
    //         this.setState({
    //             data: res.data.Data,
    //             query: originalQry,
    //             tabquery: "",
    //             activeTab: e
    //         })
    //     })
    // }


    render() {
        let { data } = this.state;
      
        const { Text } = Typography;

        // const [ellipsis, setEllipsis] = React.useState(true);
        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />
                    <div className="event-filter-search-form">
                        <Form
                            layout="inline"
                            size="large"
                            onFinish={this.onFinish}
                        // form={form}
                        >
                            <Form.Item name="eventname">
                                <Input placeholder="Workshop Name" />
                            </Form.Item>
                            <Form.Item name="startdate">
                                <DatePicker placeholder="Start Date" />
                            </Form.Item>
                            <Form.Item name="enddate">
                                <DatePicker placeholder="End Date" />
                            </Form.Item>
                            <Form.Item>
                                <Tooltip title="search">
                                    <Button type="primary" htmlType="submit" icon={<FontAwesomeIcon icon={faSearch} />}
                                        style={{ background: "#54595F", borderColor: "#54595F" }}
                                    />
                                </Tooltip>
                            </Form.Item>
                        </Form>
                    </div>

                    <Container style={{ marginTop: "2%" }}>
                        <div className="section-div-event-title-text">Workshops</div>
                    </Container>


                    <Container className="event-category-container">
                        {/* [...Array.from({ length: 18 }, (v, i) => i)].map(i => ( */}
                        {/* Section main listing */}
                        <Container style={{ padding: '0 0' }}>
                            <Row>
                                {  // [0,1,2,3,4,5,6,7,8,9,10].map((i) => (
                                    Array.from(Array(data.length).keys()).map((i) => (
                                        <Col lg="6" xs="12" key={i} style={{ marginTop: "5%" }}>
                                            <Container>
                                                <Row className="section-div-image-list-row">
                                                    <Col lg="7" xs="12" className="col-no-gap section-div-image-list-col">
                                                        <div className="section-div-image-list">
                                                            {/* <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Copy-of-NDP5-Youth-Leadership-p9t7crjxwm80oywqpsmpa86huqn4m6a6wirn5modcw.jpg" /> */}
                                                            <img src={data[i].imageurl == null ? "https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Copy-of-NDP5-Youth-Leadership-p9t7crjxwm80oywqpsmpa86huqn4m6a6wirn5modcw.jpg" : S3_PUBLIC_URL + data[i].imageurl} />
                                                        </div>
                                                    </Col>
                                                    <Col lg="5" xs="12" className="col-no-gap section-div-image-list-col">
                                                        <div className="section-div-text-list" style={i % 3 == 0 ? { background: "#d41b1a" } : i % 3 == 1 ? { background: "#2c6eac" } : { background: "#eec108" }}>   {/*background:"#2c6eac"(blue) background:"#eec108"(yellow) */}
                                                            <div className="section-div-header-list">
                                                                {/* River Hongbao */}
                                                                {data[i].eventname}
                                                            </div>
                                                            <div
                                                                className="section-div-content-list"
                                                            // dangerouslySetInnerHTML={{ __html: data[i].eventdescription }}
                                                            >
                                                                {/* <Text
                                                            style={ellipsis ? { width: 100 } : undefined}
                                                            ellipsis={ellipsis ? { tooltip: 'I am ellipsis now!' } : false}
                                                        >
                                                            {data[i].eventdescription}
                                                        </Text> */}
                                                                {data[i].eventdescription.replace(/<[^>]+>/g, '')}
                                                            </div>
                                                            <div className="section-div-content-list-button-container">
                                                                {/* <a className="section-div-content-list-button" href="/eventdetail">See Details <FontAwesomeIcon icon={faAngleRight} /></a> */}
                                                                <Link
                                                                    className="section-div-content-list-button"
                                                                    to={`/eventdetail/?eventid=${data[i].eventid}`}
                                                                >
                                                                    See Details <FontAwesomeIcon icon={faAngleRight} />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                        <div className="col-center-content" style={{ marginTop: "60px", marginBottom: "60px" }}>
                            <button onClick={this.onLoadMore} className="button-load-more-orange">Load More</button>
                        </div>
                    </Container>
                </div>
            </>
            }
            </>

        )
    }
}