import React, { Component } from 'react';
import "./News.css"
import 'antd/dist/antd.css';
import { Divider, Row, Col } from 'antd';
import BasePageComponent from '../components/BasePageComponent'
import qs from 'querystring'
import api from '../services/api';
import { Link } from 'react-router-dom';
import moment from 'moment'

export default class NewsCardList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            data: [],
        }
    }
    componentDidUpdate(prevProps, prevState) {
        console.log('NewsCardList -- componentDidUpdate', prevProps, prevState)
        // Restore coming from API
        const { category } = this.props.match.params
        const prevCategory = prevProps.match.params.category
        console.log('NewsCardList -- url', prevCategory, category)
        if (category !== prevCategory) {
            let url = "/api/front/GetNews?category=" + category;
            console.log('NewsCardList -- url', url)
            api.get(url).then((res) => {
                this.setState({
                    data: res.data.Data
                })
                console.log('NewsCardList -- componentDidMount', res.data.Data)
            })

        }
    }
    componentDidMount() {
        const { category } = this.props.match.params
        let url = "/api/front/GetNews?category=" + category;
        console.log('NewsCardList -- url', url)
        api.get(url).then((res) => {
            this.setState({
                data: res.data.Data
            })
            console.log('NewsCardList -- componentDidMount', res.data.Data)
        })


    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        console.log('NewsCardList -- render', this.state.data)
        const { isMobile, data } = this.state;
        return (
            <>

                <Row>
                    {
                        Array.from(Array(data.length).keys()).map((i) => (
                            <Col xxl={8} xl={12} lg={12} sm={12} md={12} xs={24} key={i} style={{ marginBottom: "40px" }}>
                                <div className="newspage-post-card newspage-card-box-shadow">
                                    <img src={data[i].imageurl} />
                                    <div className="newspage-post-card-content">
                                        <h3>
                                            <Link to={`/newsdetail?postid=${data[i].postid}`}>
                                                {data[i].posttitle}
                                            </Link>
                                        </h3>
                                        <p style={{height:"100px", overflow: "hidden"}}>{data[i].shortcontent.replace(/<[^>]+>/g, '').replace('Read more','')}</p>
                                        <Link to={`/newsdetail?postid=${data[i].postid}`}>
                                            READ MORE
                                        </Link>
                                    </div>
                                    <Divider style={{ margin: "0" }} />
                                    <div className="newspage-post-card-bottom">
                                        <div>{moment(data[i].postmodified).format('DD MMM YYYY')}</div>
                                    </div>

                                </div>
                            </Col>

                        ))
                    }
                </Row>



            </>

        )
    }
}