import React, { Component} from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope,faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Section11.scss';

export default class Section11 extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isMobile: window.innerWidth<1024,
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize)   
    }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    render(){
        let isMobile = this.state.isMobile;
        return (
            <>
                <div className="Section11">
                <Container className="container-nopad-nomargin footer">
                    <Row>
                        <Col lg="4" xs="12" className="footer-column">
                            <img style={{width:"50%"}} src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/youthbank_logo3-4-300x76.png" />
                            <div className="section-footer-icon-text"><FontAwesomeIcon icon={faPhone}/> 6509 4414</div>
                            <div className="section-footer-icon-text"><FontAwesomeIcon icon={faEnvelope}/> youthbank@heartware-network.org</div>
                            <div className="section-footer-icon-text"><FontAwesomeIcon icon={faMapMarkerAlt}/> 568 Ganges Ave #02-100 Singapore 160568</div>
                        </Col>
                        <Col lg="4" xs="6" className="footer-column">
                            <div className="section-footer-subheader">GENERAL</div>
                            <a href="/aboutus"><div className="section-footer-links">About Us</div></a>
                            <a href="/contactus"><div className="section-footer-links">Contact Us</div></a>
                            <a href="/faq"><div className="section-footer-links">FAQ</div></a>
                            <a href="/privacypolicy"><div className="section-footer-links">Terms of Use and Privacy Policy</div></a>
                        </Col>
                        <Col lg="4" xs="6" className="footer-column">
                            <div className="section-footer-subheader">WHAT WE DO123</div>
                            <a href="/"><div className="section-footer-links">Services</div></a>
                            <a href="/events"><div className="section-footer-links">Events</div></a>
                            <a href="/workshops"><div className="section-footer-links">Workshops</div></a>
                            <a href="news/article"><div className="section-footer-links">Latest Happenings</div></a>
                        </Col>
                       
                        
                        {/* <Col lg="3" xs="12" className="footer-column">
                            <div className="section-footer-subheader">NEWSLETTER</div>
                            <div className="section-footer-div-input"><input placeholder="Name" className="news-letter-input" /> </div>
                            <div className="section-footer-div-input"><input placeholder="Your Email" className="news-letter-input" /> </div>
                            <button className="button-yellow-subscribe">SUBSCRIBE</button>
                        </Col> */}
                    </Row>
                </Container>
                
                </div>
            </>
        )
    }
}