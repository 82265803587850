import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Newsdetail.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin, faPinterest, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import 'antd/dist/antd.css';
import qs from 'querystring'
import api from '../services/api';
import BasePageComponent from '../components/BasePageComponent'
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton, TelegramShareButton } from "react-share"
import moment from 'moment'

export default class NewsDetail extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            data: [{ postid: 1, posttitle: "posttitle", fullcontent: "" }],
        }
    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }

    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        this.setState({ isLoaded: true })
        // Restore coming from API
        const { search } = this.props.location;
        const { postid } = qs.parse(search.slice(1))
        let url = "/api/front/GetNewsDetail?postid=" + postid;
        console.log('url', url)
        api.get(url).then((res) => {
            this.setState({
                data: res.data.Data
            })
            console.log('componentDidMount', res.data.Data)
        })

        console.log(window.location.href);

    }

    render() {
        let isMobile = this.state.isMobile;
        let data = this.state.data;
        console.log('render', data)
        return super.render(
            <>
                <div className="otherpagebody">

                    <Container style={{ marginTop: "5%" }} className="newsdetail-header-container">
                        <h1>{data[0].posttitle}</h1>
                        <div className="newsdetail-header-info">
                            <FontAwesomeIcon icon={faCalendar} />{moment(data[0].postmodified).format ('DD MMM YYYY')}
                            {/* <span> | </span>
                            <FontAwesomeIcon icon={faUserCircle} />ydmsdesign_qnqwsi */}
                        </div>
                        <img src={data[0].imageurl} />
                    </Container>

                    <Container style={{ marginTop: "5%" }} className="newsdetail-content-container">
                        <Row>
                            <Col lg="12" xs="12" style={isMobile? {paddingRight: "0px"}:{ paddingRight: "50px" }}>
                                <div className="newsdetail-content">
                                    {/* <h3>subtitle</h3>
                                    <p>Remember that snow fight you had with your friend Rose one winter? That was fun. Look forward a bit with me into the next year, the next two years. What do you see? A long, winding path shrouded in fog and darkness? An adventure into the great unknown? Probably the former. You’re uncertain of your future, uncertain of where life will take you now. I get that. Life doesn’t look so easy anymore, and you don’t even know what you’re getting yourself into. Luckily, I have a few tips that may help you along the way. First, tenacity. Starting a new life on the opposite side of the world, away from all that you’ve been so comfortable with, is never easy. I won’t lie to you: transitioning into an entirely new education system will be tough. You will miss America and the easier life you had there, you will miss the friends you made, and there will be days when you cry yourself to sleep. There will be plenty of times when you want to give up because you keep failing in a subject, when you’re starving but don’t know how to order from the cai fun stall at the Kopitiam, when you feel alone and lost in a busy, people-filled city. I have one word for you: persevere. Keep pushing everyday, keep getting up in the mornings even though you feel like collapsing back in bed, keep showing up. Because it is in these moments when you find your true strength, when you prove to yourself that you can do it. Things will start to look up soon, never fret. Stay positive, and keep reminding yourself of your family who will always cheer you on, both on the rainy days and the sunny ones.</p>
                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/08/c9491d328a1573761aab6a8b9ccfb42.png" />
                                    <h3>subtitle</h3>
                                    <p>Remember that snow fight you had with your friend Rose one winter? That was fun. Look forward a bit with me into the next year, the next two years. What do you see? A long, winding path shrouded in fog and darkness? An adventure into the great unknown? Probably the former. You’re uncertain of your future, uncertain of where life will take you now. I get that. Life doesn’t look so easy anymore, and you don’t even know what you’re getting yourself into. Luckily, I have a few tips that may help you along the way. First, tenacity. Starting a new life on the opposite side of the world, away from all that you’ve been so comfortable with, is never easy. I won’t lie to you: transitioning into an entirely new education system will be tough. You will miss America and the easier life you had there, you will miss the friends you made, and there will be days when you cry yourself to sleep. There will be plenty of times when you want to give up because you keep failing in a subject, when you’re starving but don’t know how to order from the cai fun stall at the Kopitiam, when you feel alone and lost in a busy, people-filled city. I have one word for you: persevere. Keep pushing everyday, keep getting up in the mornings even though you feel like collapsing back in bed, keep showing up. Because it is in these moments when you find your true strength, when you prove to yourself that you can do it. Things will start to look up soon, never fret. Stay positive, and keep reminding yourself of your family who will always cheer you on, both on the rainy days and the sunny ones.</p>
                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/08/c9491d328a1573761aab6a8b9ccfb42.png" />
                                    <h3>subtitle</h3>
                                    <p>Remember that snow fight you had with your friend Rose one winter? That was fun. Look forward a bit with me into the next year, the next two years. What do you see? A long, winding path shrouded in fog and darkness? An adventure into the great unknown? Probably the former. You’re uncertain of your future, uncertain of where life will take you now. I get that. Life doesn’t look so easy anymore, and you don’t even know what you’re getting yourself into. Luckily, I have a few tips that may help you along the way. First, tenacity. Starting a new life on the opposite side of the world, away from all that you’ve been so comfortable with, is never easy. I won’t lie to you: transitioning into an entirely new education system will be tough. You will miss America and the easier life you had there, you will miss the friends you made, and there will be days when you cry yourself to sleep. There will be plenty of times when you want to give up because you keep failing in a subject, when you’re starving but don’t know how to order from the cai fun stall at the Kopitiam, when you feel alone and lost in a busy, people-filled city. I have one word for you: persevere. Keep pushing everyday, keep getting up in the mornings even though you feel like collapsing back in bed, keep showing up. Because it is in these moments when you find your true strength, when you prove to yourself that you can do it. Things will start to look up soon, never fret. Stay positive, and keep reminding yourself of your family who will always cheer you on, both on the rainy days and the sunny ones.</p>
                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/08/c9491d328a1573761aab6a8b9ccfb42.png" />
                                    <h3>subtitle</h3>
                                    <p>Remember that snow fight you had with your friend Rose one winter? That was fun. Look forward a bit with me into the next year, the next two years. What do you see? A long, winding path shrouded in fog and darkness? An adventure into the great unknown? Probably the former. You’re uncertain of your future, uncertain of where life will take you now. I get that. Life doesn’t look so easy anymore, and you don’t even know what you’re getting yourself into. Luckily, I have a few tips that may help you along the way. First, tenacity. Starting a new life on the opposite side of the world, away from all that you’ve been so comfortable with, is never easy. I won’t lie to you: transitioning into an entirely new education system will be tough. You will miss America and the easier life you had there, you will miss the friends you made, and there will be days when you cry yourself to sleep. There will be plenty of times when you want to give up because you keep failing in a subject, when you’re starving but don’t know how to order from the cai fun stall at the Kopitiam, when you feel alone and lost in a busy, people-filled city. I have one word for you: persevere. Keep pushing everyday, keep getting up in the mornings even though you feel like collapsing back in bed, keep showing up. Because it is in these moments when you find your true strength, when you prove to yourself that you can do it. Things will start to look up soon, never fret. Stay positive, and keep reminding yourself of your family who will always cheer you on, both on the rainy days and the sunny ones.</p>
                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/08/c9491d328a1573761aab6a8b9ccfb42.png" /> */}
                                    {/* {data[0].fullcontent} */}
                                    <div dangerouslySetInnerHTML={{ __html: data[0].fullcontent }}></div>

                                </div>
                                {/* <div className="newsdetail-social-media-share" style={{ margin: "8% 0" }}>
                                    <div>Share:</div>
                                    <div className="newsdetail-share-button-list">
                                        <div className="newsdetail-share-button" style={{ backgroundColor: "#3b5998" }}>
                                            <a href="javascript:;">
                                                <FontAwesomeIcon icon={faFacebook} />Facebook
                                            </a>
                                        </div>
                                        <div className="newsdetail-share-button" style={{ backgroundColor: "#1da1f2" }}>
                                            <a href="javascript:;">
                                                <FontAwesomeIcon icon={faTwitter} />Twitter
                                            </a>
                                        </div>
                                        <div className="newsdetail-share-button" style={{ backgroundColor: "#bd081c" }}>
                                            <a href="javascript:;">
                                                <FontAwesomeIcon icon={faPinterest} />Pinterest
                                            </a>
                                        </div>
                                        <div className="newsdetail-share-button" style={{ backgroundColor: "#0077b5" }}>
                                            <a href="javascript:;">
                                                <FontAwesomeIcon icon={faLinkedin} />Linkedin
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="newsdetail-social-media-share" style={{ margin: "8% 0" }}>
                                    <div>Share:</div>
                                    <div className="newsdetail-share-button-list">
                                        <FacebookShareButton
                                            url={window.location.href}
                                        >
                                            <div className="newsdetail-share-button" style={{ backgroundColor: "#3b5998" }}>
                                                <FontAwesomeIcon icon={faFacebook} />Facebook
                                            </div>
                                        </FacebookShareButton>
                                        <LinkedinShareButton
                                            url={window.location.href}
                                        >
                                            <div className="newsdetail-share-button" style={{ backgroundColor: "#0077b5" }}>
                                                <FontAwesomeIcon icon={faLinkedin} />Linkedin
                                            </div>
                                        </LinkedinShareButton>
                                        <WhatsappShareButton
                                            url={window.location.href}
                                        >
                                            <div className="newsdetail-share-button" style={{ backgroundColor: "#24D366" }}>
                                                <FontAwesomeIcon icon={faWhatsapp} />Whatspp
                                            </div>
                                        </WhatsappShareButton>
                                        <TelegramShareButton
                                            url={window.location.href}
                                        >
                                            <div className="newsdetail-share-button" style={{ backgroundColor: "#37AEE2" }}>
                                                <FontAwesomeIcon icon={faTelegram} />Telegram
                                            </div>
                                        </TelegramShareButton>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg="4" xs="12">
                                <div className="newsdetail-suggest-news-list">
                                    <div>You Might Also Enjoy</div>
                                    {
                                        [0, 1, 2].map((i) => (
                                            <div className="newsdetail-suggest-news-list-item" key={i}>
                                                <a href="javascript:;">
                                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/08/c38dbc982acdbd1059f603038b6d4bf-300x200.png" />
                                                </a>
                                                <div className="newsdetail-suggest-news-list-item-text">
                                                    <a href="javascript:;">
                                                        Businesses deliver cheer ahead of National Day
                                                    </a>
                                                    <p>Community partners integral to holistic learning When schools want</p>
                                                </div>
                                            </div>

                                        ))
                                    }
                                </div>
                            </Col> */}
                        </Row>

                    </Container>

                </div>

            </>

        )
    }
}