import React, { Component} from 'react';
import api from '../services/api';
import Globals from '../Globals';
import MyMenu from "../components/MyMenu"
import Footer from "../components/Footer"
import ModalLogin from '../components/ModalLogin'
import ModalSignup from '../components/ModalSignup'
import ModalCookie from '../components/ModalCookie'
import LoaderOverlay from '../components/LoaderOverlay'
import Button from 'react-bootstrap/Button';
import MyDrawer from '../components/MyDrawer'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt,faBars } from '@fortawesome/free-solid-svg-icons'
import { Container, Row, Col } from 'react-bootstrap';
import './BasePageComponent.scss';
import _ from 'underscore/underscore'
import Toolbar from '../components/Toolbar'

export default class PageComponent extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isLoaded: false,
            userData:{},
            modalLogin: false,
            modalCookie: false,
            showLoadingOverlay:  false,
            resetPasswordToken: "",
            drawerOpen: false,
            toolbar: false,
            sections:[]
        }
    }

    
    async componentDidMount () {
        let userData = window.localStorage.getItem('@user')
        if (userData!==null){
            userData = atob(userData);
            userData = JSON.parse(userData)
            console.log("userData",userData)
            this.setState({userData: userData})
            Globals.userdata = userData

            // userData.modules
            let modules = JSON.parse(userData.modules)
            let toolbar = _.contains(modules,'CMS')
            this.setState({toolbar:toolbar})

            // Session Checks
            let res = await api.get(`/api/Mobile/isToken`)
            if (res.data.StatusCode==0){ // Invalid Session
                window.localStorage.removeItem("@user")
                window.location.reload()
            }
            setTimeout(()=>{
                if (!window.mySession.isSessionValid()){
                    window.localStorage.removeItem("@user")
                    window.location.reload()
                }else{
                    window.mySession.reset()
                }
            },500)

        }
        let cookieconsent = window.localStorage.getItem('@cookieconsent')
        if (cookieconsent==null){
            this.setState({modalCookie: true})
        }

        if (this.state.sectionName){
            let sectionName = this.state.sectionName
            let sections = this.state.sections 
            sections[sectionName] = this
            this.setState({sections:sections})
            // console.log("AAABBCCC",this.state)
            let res = await api.get(`/api/Common/Getpage?pagename=${sectionName}`)
            if (res.data.StatusCode==1){
                if (res.data.Data.length>0){
                    let data = JSON.parse(res.data.Data[0].jsonpage)
                    // console.log("AAAAAAABBBBBCCC",data)
                    this.setState({
                        attributes: data,
                        isLoaded: true
                    })
                }
            }
        }
        
    }

    openDrawer = () =>{
        this.setState({drawerOpen:true})
    }
    openMenu = () =>{
        this.setState({drawerOpen:!this.state.drawerOpen})
    }

    getFieldFromType = (dataName,type,field) =>{
        if (!this.state.attributes[dataName]){
            return ''
            // return `#01-data-not-set-${dataName}-${field}` 
        }
        let data = _.where(this.state.attributes[dataName],{type:type})
        if (data.length>0){
            // alert("HERE")
            // console.log("AAABBCCC111",data[0][field])
            return data[0][field]
        }else{
            return ''
            // return `#02-data-not-set-${dataName}-${field}` 
        }
        
    }

    getFieldArrayFromType = (dataName,type) =>{
        if (!this.state.attributes[dataName]){
            return []
        }
        return _.where(this.state.attributes[dataName],{type:type})
    }

    parseTextField = (d,field)=>{
        d = JSON.parse(d)
        return d[field]
    }

    Login = () => {
        this.setState({ modalLogin: true })
    }
    Signup = (e) => {
        e.preventDefault();
        this.setState({ modalSignup: true })
    }

    render(Page){
        return (
            <>
            <div className="BasePageComponent">
                <MyDrawer drawerOpen={this.state.drawerOpen} openMenu={this.openMenu}/>
                <Button variant="dark" className="menu-bar-button-icon" onClick={this.openDrawer}>
                <FontAwesomeIcon icon={faBars}  />
                </Button>
                    
                <MyMenu parent={this} />
                <ModalLogin parent={this} />
                <ModalSignup parent={this} />
                <ModalCookie parent={this}/>
                <LoaderOverlay parent={this}/>
                {!Globals.userdata.accountid && <>
                    <button className="button-red-signup-mobile" onClick={this.Signup}>Sign Up</button>
                    <button className="button-yellow-login-mobile"  onClick={this.Login} >
                        Login
                    <FontAwesomeIcon icon={faSignInAlt} />
                    </button>
                </>
                }

                {Page}
                <Footer parent={this} />
                
            </div>
             {this.state.toolbar && 
             <>
            <Toolbar parent={this}/>
            </>
            } 
            </>
        )
    }
}
