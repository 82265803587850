import React, { Component} from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../pages/Common.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebookF,  faInstagram, faLinkedin, faTiktok} from '@fortawesome/free-brands-svg-icons';

export default class StandardHeader extends Component  { 
    
    constructor(props){
        super(props)
        this.state = {
            text1: props.text1,
            text2: props.text2,
            text3: props.text3           
        };
    }

    componentDidMount() {
        
    }    
    render(){
        return (
            <div className="section-standard-header">                    
                <div className="section-standard-header-content text1">{this.state.text1}</div>
                <div className="section-standard-header-content text2">{this.state.text2}</div>
                <div className="section-standard-header-content social-media-grid">
                    <a target="_blank" href="https://www.tiktok.com/@heartwaresg"><FontAwesomeIcon icon={faTiktok} className="section-standard-header-social-media-icon"/></a>
                    <a target="_blank" href="https://www.linkedin.com/company/heartware-network?originalSubdomain=sg"><FontAwesomeIcon icon={faLinkedin} className="section-standard-header-social-media-icon"/></a>
                    <a target="_blank" href="https://www.facebook.com/youthbanksg"><FontAwesomeIcon icon={faFacebookF} className="section-standard-header-social-media-icon"/></a>
                    <a target="_blank" href="https://www.instagram.com/heartwaresg"><FontAwesomeIcon icon={faInstagram} className="section-standard-header-social-media-icon"/></a>
                </div>
                <div className="section-standard-header-content text3">"{this.state.text3}"</div>                    
            </div>  
        )
    }
}

