import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import "./PrivacyPolicy.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import StandardHeader from '../components/StandardHeader';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';

import BasePageComponent from '../components/BasePageComponent';


export default class PrivacyPolicy extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            // textEditor
            sections: [],
            sectionName: "PrivacyPolicy",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            }
            //eof textEditor
        }
    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;

        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    <Container className="privacypolicy-main-container" style={{ marginTop: "10%" }} dangerouslySetInnerHTML={{__html: this.getFieldFromType('data1','privacypolicy','text')}}>

                        
                    </Container>
                </div>

            </>
            }
            </>

        )
    }
}