import React, { Component} from 'react';
// import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Section7.scss';
import api from '../../services/api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Globals from '../../Globals'
import ShowMoreText from "react-show-more-text";


export default class Section7 extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            sectionName: "HomeSection7",
            initialized: false,
            isMobile: window.innerWidth<1024,
            data: [],
        }
    }
    componentDidMount() {
        window.addEventListener('resize', this.handleResize)   
        api.get("/api/front/GetHomepageEvents?query="+ btoa("eventtype = 'TRAINING'")).then((res) => {
            console.log(res.data.Data);
            this.setState({
                data: res.data.Data
            })
        })
    }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    render(){
        let isMobile = this.state.isMobile;
        const data = this.state.data
        return (
            <>
                <div className="Section7">
                <div className="section-container">
                    <div className="section-div-header-6">Workshops</div>
                    {data && data.length <1 && <p style={{fontSize:"20px", fontWeight:"600"}}>Coming soon!</p>}
                    <Carousel 
                            responsive={Globals.carouselEvents.responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={5000}
                            className="carousel-events"
                        >
                        {
                           Array.from(Array(data.length).keys()).map((i) => (
                                <div className="section-card-container" key={i}>
                                    <img className="section-img" src={"https://ydms-public.s3.ap-southeast-1.amazonaws.com/"+data[i].file_image}  />
                                    <div className="section-card-container-text">
                                        <div className="section-card-container-header">{data[i].eventname}</div>
                                        {/* <div className="section-card-container-by">By Choo Yun Ting, The Straits Times</div> */}
                                        <ShowMoreText 
                                            lines={10}
                                            className="show-more-content"
                                            truncatedEndingComponent={"... "}
                                            more=""
                                            less=""
                                        >
                                            {data[i].eventdescription.replace(/<[^>]+>/g, '')}
                                        </ShowMoreText>
                                    </div>
                                    <div className="section-card-container-readmore" onClick={()=>{window.location.href=`eventdetail/?eventid=${data[i].eventid}`}}>Read more</div>
                                    <button className="button-orange-joinnow" onClick={()=>{window.location.href=`eventdetail/?eventid=${data[i].eventid}`}}>Enrol Now</button>
                                </div>
                            ))
                        }
                        
                    </Carousel>
                </div>
                
                </div>
            </>
        )
    }
}