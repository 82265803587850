import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Faq.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import StandardHeader from '../components/StandardHeader';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';
import BasePageComponent from '../components/BasePageComponent'



export default class FAQ extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            // textEditor
            sections: [],
            sectionName: "Faq",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            }
            //eof textEditor
        }
    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;
        const { Panel } = Collapse;
        const text = "A dog is a type of domesticated animal.  Known for its loyalty and faithfulness,  it can be found as a welcome guest in many households across the world.";
        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    {/* <StandardHeader
                        text1="MAKE A DIFFERENCE WITH US"
                        text2="Get Involved"
                        text3="Sign up on Youthbank to start making a difference!"
                    /> */}
                     <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />

                    {/* Section faq */}
                    <Container className="faq-main-container" style={isMobile ? { marginTop: "2%" } : { marginTop: "3%", padding: "0em 10em" }}>
                        <div>FREQUENTLY ASKED QUESTIONS</div>
                        <Collapse
                            className="faq-collapse"
                            defaultActiveKey={['1']}
                            bordered={false}
                            ghost
                            expandIcon={({ isActive }) => <FontAwesomeIcon icon={isActive ? faChevronCircleUp : faChevronCircleRight} />}
                        >
                            {
                                this.getFieldArrayFromType('data1','faq-question-list').map((d) => (
                                    <Panel header={d.text} key={d.id}>
                                        <p>{d.url}</p>
                                    </Panel>
                                ))
                            }

                            {/* <Panel header="This is panel header 1" key="1">
                                <p>{text}</p>
                            </Panel>
                            <Panel header="This is panel header 2" key="2">
                                <p>{text}</p>
                            </Panel>
                            <Panel header="This is panel header 3" key="3">
                                <p>{text}</p>
                            </Panel>
                            <Panel header="This is panel header 4" key="4">
                                <p>{text}</p>
                            </Panel>
                            <Panel header="This is panel header 5" key="5">
                                <p>{text}</p>
                            </Panel> */}
                        </Collapse>
                        <p>If you still have any questions, please feel free to  <a href="/contactus">Contact Us</a></p>
                    </Container>
                </div>
            </>
            }
            </>

        )
    }
}