import BasePageComponent from '../components/BasePageComponent';
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Service.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt,  faFlag, faIdCard, faPeopleCarry, faSchool, faHandsHelping, faUser, faUsers, faHandHoldingHeart, faBoxes, faPhotoVideo } from '@fortawesome/free-solid-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import { Divider } from 'antd'
import { FullpageAccordion, Panel } from "react-fullpage-accordion";
import "react-fullpage-accordion/dist/react-fullpage-accordion.css";
import Globals from '../Globals'
import Carousel2 from 'react-multi-carousel';
import { Carousel as AntdCarousel } from 'antd'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import SingleService from './SingleService';
import api from '../services/api';
import ShowMoreText from "react-show-more-text";

const iconMap = { faCalendarAlt,  faFlag, faIdCard, faPeopleCarry, faSchool, faHandsHelping, faUser, faUsers, faHandHoldingHeart, faBoxes, faPhotoVideo };


export default class Service extends BasePageComponent {
    constructor(props) {
        super(props)
        // console.log("AAAAAABBBBBCCCC",this.props.match.params)
        this.state = {
            isMobile: window.innerWidth < 1024,
            headerImageData: [],
            servicename: this.props.match.params,
             // textEditor
             sections: [],
             sectionName: "Service-" + this.props.match.params.servicename,
             attributes: { 
                 data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // title & logo
                 data2: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // photo carousel
                 data3: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // service write up
                 data4: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // volunteer if you are
                 data5: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // volunteer roles
                 data6: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // what they say
             },
             events: [],
             photocarouselDesktop: [],
            //  dataHeader:{
            //     title: "Heartware Tuition Programme",
            //     imgsrc: "https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/07/home-icon-circle-4.png",
            //     subtitle: "SUPPORT LEARNING, BUILD CHARACTER",
            //     desc1: "For children whose parents are incarcerated and / or from low income backgrounds, keeping up academically is an uphill battle. The motivation to learn wanes rapidly with the lack of positive role models in their lives.",
            //     desc2: "Heartware Tuition Programme (HWTP) aims to place these children on equal footing with their peers through free weekly tuition. Our volunteers will guide primary school children in their studies and character development, forming meaningful bonds in the process.",
            //     desc3: "Join us to help these children discover their love for learning again!"
            // },
            // dataifyouare:[{
            //     icon: faIdCard,
            //     cardtext: "Aged between 17 and 35 years old"
            // }, {
            //     icon: faSchool,
            //     cardtext: "Holding an 'O' level (or equivalent) qualification and above"
            // }, {
            //     icon: faHandHoldingHeart,
            //     cardtext: "Keen to work with children and transform their lives"
            // }, {
            //     icon: faHandsHelping,
            //     cardtext: "Able to attend all training and weekly tuition sessions until the end of the academic year"
            // }],
            // dataRoles:[{
            //     icon: faUsers,
            //     cardtext: "Youth Liaison Committee (YLC)"
            // }, {
            //     icon: faUsers,
            //     cardtext: "Volunteer Leader"
            // }, {
            //     icon: faUser,
            //     cardtext: "General Volunteer"
            // }]

             //eof textEditor
        }
    }

    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    componentDidMount(...args) {
        super.componentDidMount.apply(this,args);
        // const{servicename} = this.props.match.params
        const { servicename } = this.state.servicename
        console.log('servicename', servicename);
        // this.FnGetData()
        
        let url = "/api/front/GetServiceEvents?servicename=" + servicename;
        console.log('url', url)
        api.get(url).then((res) => {
            this.setState({
                events: res.data.Data,
            })
            console.log('componentDidMount', this.state.events)
        })

        this.setState({isLoaded: true})
    }

    render() {
        let isMobile = this.state.isMobile;
        const { dataHeader, dataifyouare, dataRoles } = this.state

        let photocarouselDesktop = [];
        if (this.getFieldArrayFromType('data2','photo-carousel-list').length>0){
            let carousel = this.getFieldArrayFromType('data2','photo-carousel-list')
            let ctr = 0;
            for (let i=0;i<carousel.length;i++){
                if (ctr==3){
                    ctr=0
                }
                ctr = ctr + 1;
                if (ctr==1){
                    photocarouselDesktop.push({col1: carousel[i].imageurl,col2: "",col3:""})
                }else{
                    let len = photocarouselDesktop.length-1
                    photocarouselDesktop[len]["col"+ctr.toString()] = carousel[i].imageurl
                }
            }
            // console.log("photocarouselDesktop",photocarouselDesktop)
            
        }
        // return (
        return super.render(
            <>
            {this.state.isLoaded && 
            <>
             <div className="otherpagebody">

                {/* Section title */}
                <Container style={{ marginTop: "5%", padding: "0em 6em" }}>
                    <Row>
                        <Col lg="10" xs="12" className="col-center-content">
                            <div className="service-section-main-content-title">{this.getFieldFromType('data1','data-header-title','text')}</div>
                        </Col>
                        <Col lg="2" xs="12" className="col-center-content">
                            <img src={this.getFieldFromType('data1','data-header-imgsrc','imageurl')} />
                        </Col>
                    </Row>
                </Container>

                <Container className="service-carousel-container" style={{ marginTop: "2%" }}>
                    <AntdCarousel
                        arrows
                        autoplay
                        dots={false}
                        prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextArrow={<FontAwesomeIcon icon={faAngleRight} />}
                    >

                        {
                            isMobile ?
                            this.getFieldArrayFromType('data2','photo-carousel-list').map((d) => (
                                <Container key={d.id}>
                                    <Row  >
                                        <Col lg="4" xs="12">
                                            <img src={d.imageurl} />
                                        </Col>
                                    </Row>
                                </Container>
                            ))
                            :
                            photocarouselDesktop.map((d) => (
                                <Container key={d.id}>
                                    <Row  >
                                        <Col lg="4" xs="12">
                                            <img src={d.col1} />
                                        </Col>
                                        <Col lg="4" xs="12">
                                            <img src={d.col2} />
                                        </Col>
                                        <Col lg="4" xs="12">
                                            <img src={d.col3} />
                                        </Col>
                                    </Row>
                                </Container>
                            ))
                        }


                    </AntdCarousel>
                </Container>

                {/* Section Description */}
                <Container className="service-section-description-container" style={{ marginTop: "2%" }}>

                    <Row style={{ padding: "3em 0em" }}>
                        <Col lg="7" xs="12">
                            <div className="service-page-sub-header">{this.getFieldFromType('data1','data-header-subtitle','text')} </div>
                            <p>{this.getFieldFromType('data1','data-header-desc1','text')}</p>
                            <p>{this.getFieldFromType('data1','data-header-desc2','text')}</p>
                            <p>{this.getFieldFromType('data1','data-header-desc3','text')}</p>
                        </Col>
                        <Col lg="5" xs="12">
                            <div className="service-page-sub-header">{(this.state.events && this.state.events.length > 0) ? 'ENROL NOW' : 'PROGRAMMES' }</div>
                            <ul className="service-event-list-items">
                                {
                                    this.state.events.map((i) => (
                                        <li className="service-event-list-item" key={i}>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                            <a href={`/eventdetail/?eventid=${i.eventid}`}>{i.eventname}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                            {(this.state.events && this.state.events.length == 0) && <p>More programmes coming soon! {/* <br></br>If you want to express your interest in our service or if you have any questions, please feel free to <a href="https://www.youthbank.sg/contactus">contact us.</a> */}</p>}
                        </Col>
                    </Row>
                    <Divider style={{ borderTop: "2.5px solid #d1c9c3", minWidth: "65%", width: "65%", textAlign: "center", margin: "2.5em auto" }} />
                </Container>


                {/* Section Volunteer if you are */}
                <Container className="service-section-ifyouare" style={{ marginTop: "5%" }}>
                    <div className="service-page-sub-header">{this.getFieldFromType('data3','volunteer-ifyouare-header','text')}</div>
                    <Row style={{ backgroundColor: "white", paddingBottom: "2%" }}>
                        {
                            // Array.from(Array(dataifyouare.length).keys()).map(i => (
                            this.getFieldArrayFromType('data3','volunteer-ifyouare-icon-list').map((d) => (
                                <Col lg="6" xs="12" key={d.id}>
                                    <div>
                                        <FontAwesomeIcon className="service-section-ifyouare-card icon" icon={iconMap[d.imageurl]} />
                                        <div className="service-section-ifyouare-card text">{d.text}</div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Divider style={{ borderTop: "2.5px solid #d1c9c3", minWidth: "65%", width: "65%", textAlign: "center", margin: "2.5em auto" }} />
                </Container>

                {/* Section Volunteer roles */}
                <Container className="service-section-roles" style={{ marginTop: "7%" }}>
                    <div className="service-page-sub-header">{this.getFieldFromType('data4','volunteer-roles-header','text')}</div>
                    <Row style={{ backgroundColor: "white", paddingBottom: "2%" }}>
                        {
                            // Array.from(Array(dataRoles.length).keys()).map(i => (
                            this.getFieldArrayFromType('data4','volunteer-roles-icon-list').map((d) => (
                                <Col lg="4" xs="12" key={d.id}>
                                    <div>
                                        <FontAwesomeIcon className="service-section-roles-card icon" icon={iconMap[d.imageurl]} />
                                        <div className="service-section-roles-card text">{d.text}</div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>


                {/* Section volunteer testimonial */}
                <Container style={{ marginTop: "7%" }}>
                    <div className="section-center-header-text">{this.getFieldFromType('data5','whattheysay-header','text')}</div>
                    <Row>
                        <Carousel
                            // className="carousel-events"
                            showThumbs={false}
                            centerMode
                            centerSlidePercentage={100 / (isMobile ? 1 : 3)}
                            swipeable={true}
                            showArrows={true}
                            autoPlay={true}
                        >
                            {
                                // [0, 1, 2, 3 4, 5, 6, 7, 8].map((i) => (
                                this.getFieldArrayFromType('data5','whattheysay-content-list').map((d) => (
                                    <div className="service-section-testimonial-card-container" key={d.id}>
                                        {/* <div className="service-section-testimonial-card-container-text">{this.parseTextField(d.text,"content")}</div> */}
                                        <ShowMoreText 
                                            lines={3}
                                            className="service-section-testimonial-card-container-text"
                                            truncatedEndingComponent={"... "}
                                            more="Show more"
                                            less="Show less"
                                        >
                                            {this.parseTextField(d.text,"content")}
                                        </ShowMoreText>
                                        <Container>
                                            <Row>
                                                <Col lg="6" xs="6" style={{ textAlign: "right" }}>
                                                    <img src={this.parseTextField(d.text,"image")} />
                                                </Col>
                                                <Col lg="6" xs="6" style={{ textAlign: "left" }}>
                                                    <div className="service-section-testimonial-card-container-name">{this.parseTextField(d.text,"name")}</div>
                                                    <div className="service-section-testimonial-card-container-title">{this.parseTextField(d.text,"title")}</div>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </div>
                                ))
                            }
                        </Carousel>
                    </Row>
                </Container>


                {/* <SingleService
                    servicename="heartware-tuition-programme"
                    dataHeader={{
                        title: "Heartware Tuition Programme 123",
                        imgsrc: "https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/07/home-icon-circle-4.png",
                        subtitle: "SUPPORT LEARNING, BUILD CHARACTER",
                        desc1: "For children whose parents are incarcerated and / or from low income backgrounds, keeping up academically is an uphill battle. The motivation to learn wanes rapidly with the lack of positive role models in their lives.",
                        desc2: "Heartware Tuition Programme (HWTP) aims to place these children on equal footing with their peers through free weekly tuition. Our volunteers will guide primary school children in their studies and character development, forming meaningful bonds in the process.",
                        desc3: "Join us to help these children discover their love for learning again!"
                    }}
                    dataifyouare={[{
                        icon: faIdCard,
                        cardtext: "Aged between 17 and 35 years old"
                    }, {
                        icon: faSchool,
                        cardtext: "Holding an 'O' level (or equivalent) qualification and above"
                    }, {
                        icon: faHandHoldingHeart,
                        cardtext: "Keen to work with children and transform their lives"
                    }, {
                        icon: faHandsHelping,
                        cardtext: "Able to attend all training and weekly tuition sessions until the end of the academic year"
                    }]}
                    dataRoles={[{
                        icon: faUsers,
                        cardtext: "Youth Liaison Committee (YLC)"
                    }, {
                        icon: faUsers,
                        cardtext: "Volunteer Leader"
                    }, {
                        icon: faUser,
                        cardtext: "General Volunteer"
                    }]}
                /> */}


              </div>

            </>
            }
            </>

        )
    }
}