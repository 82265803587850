import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import "./Aboutus.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import StandardHeader from '../components/StandardHeader';
import { Collapse } from 'antd';
import 'antd/dist/antd.css';
import { Divider, Anchor, Carousel } from 'antd';
import BasePageComponent from '../components/BasePageComponent';
import Globals from '../Globals'
import Carousel2 from 'react-multi-carousel';



export default class Aboutus extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            sections: [],
            sectionName: "AboutUs",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
                data2: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // aboutUs
                data3: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // ourteam
                data4: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // ourtpartners
                data5: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // corporate partners
                data6: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // social community partners
                data7: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // public partners
            }
        }
    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;
        const { Link } = Anchor;
        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />

                   
                    <div className="top-links-mobile">
                        <div className="top-links-mobile-row1">
                            <a href="#aboutus-overview">{this.getFieldFromType('data2','aboutus-text1','text')}</a>
                            <a href="#aboutus-our-team">{this.getFieldFromType('data2','aboutus-text2','text')}</a>
                            <a href="#aboutus-our-partners-corporate-partners">{this.getFieldFromType('data2','aboutus-text3','text')}</a>
                        </div>
                        <div  className="top-links-mobile-row2">
                            <a href="#aboutus-our-partners-social-community-partners">{this.getFieldFromType('data2','aboutus-text4','text')}</a>
                            <a href="#aboutus-our-partners-school-partners">{this.getFieldFromType('data2','aboutus-text5','text')}</a>
                        </div>
                    </div>
                  

                    <Container className="aboutus-main-container" style={{ marginTop: "3%" }}>
                        <Anchor offsetTop={40} className="top-links-desktop">
                            <Link href="#aboutus-overview" title={this.getFieldFromType('data2','aboutus-text1','text')} />
                            <Link href="#aboutus-our-team" title={this.getFieldFromType('data2','aboutus-text2','text')} />
                            <Link href="#aboutus-our-partners" title={this.getFieldFromType('data2','aboutus-text3','text')}>
                                <Link href="#aboutus-our-partners-corporate-partners" title={this.getFieldFromType('data2','aboutus-text4','text')} />
                                <Link href="#aboutus-our-partners-public-partners" title={this.getFieldFromType('data2','aboutus-text9','text')} />
                                <Link href="#aboutus-our-partners-social-community-partners" title={this.getFieldFromType('data2','aboutus-text5','text')} />
                                <Link href="#aboutus-our-partners-school-partners" title={this.getFieldFromType('data2','aboutus-text6','text')} />
                            </Link>
                        </Anchor>
                        
                        <div id="aboutus-overview">
                            <h1>{this.getFieldFromType('data2','aboutus-text1','text')}</h1>
                            <Divider className="aboutus-main-header-divider" />
                            <p>{this.getFieldFromType('data2','aboutus-text7','text')}</p>
                            <p className='about-us-body' style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html: this.getFieldFromType('data2','aboutus-content','text')}} >
                            

                            {/* The Youthbank online community was designed to grow the spirit of volunteerism amongst youths in Singapore. Youthbank does this by facilitating the value engagement of youths in Singapore towards owning and benefiting their community with active volunteerism.<br></br><br></br>
                            Youthbank was initiated by Heartware Network and its 1st phase was powered by Microsoft Singapore with technical support from ITE College Central, Nanyang Polytechnic and AKSAAS Pte Ltd (a spin-off company from A*STAR’s Exploit Technologies).<br></br><br></br>
                            Youthbank was launched in May 2006 by then Minister George Yeo. Since its launch, more than 14,000 youths have signed up as volunteers. In total, they have successfully completed about 233,460 volunteer hours.<br></br><br></br>
                            The portal hosts a variety of volunteering opportunities curated by Heartware Network and her community partners. It provides opportunities for youth to serve a wide range of community causes, from children from disadvantaged backgrounds, to youth with intellectual disabilities, to seniors at risk of social isolation. Records of youth are also kept up to date within the portal to enable tracking of their progress across the years so as to actively tailor development opportunities to suit personal development needs.<br></br><br></br>
                            Moving ahead, Youthbank aspires to be a social capital bank where volunteers can connect to a myriad of meaningful community causes to serve in, to ‘deposit’ their social contributing hours, to monitor their personal and leadership development, and to rally fellow volunteers to initiate ground-up movements for the benefit of the wider community. */}
                            </p>
                            <img style={{ width: "100%", marginBottom: "5%", padding: "3%" }} src={this.getFieldFromType('data2','aboutus-photo','imageurl')}/>
                            <h3>Initiated by</h3>
                            {!isMobile && 
                            
                                // <img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Heartware-Network-Logo-pa1ev633gi3tnxhjncelgpjq659lkkc3g3esg8ur4g.png" />
                                <img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={this.getFieldFromType('data2','aboutus-heartware-logo','imageurl')} />
                            }
                            {isMobile && 
                                <img style={{ display: "block",width:"80%" }} src={this.getFieldFromType('data2','aboutus-heartware-logo','imageurl')}  />
                            }
                            
                        </div>
                        {this.getFieldFromType('data3','ourteam-text1','text') && <div id="aboutus-our-team" style={{ marginTop: "18%" }}>
                            <h1>{this.getFieldFromType('data3','ourteam-text1','text')}</h1>
                            <Divider className="aboutus-main-header-divider" />
                            <Carousel
                                arrows
                                autoplay
                                prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                                nextArrow={<FontAwesomeIcon icon={faAngleRight} />}
                            >
                                {
                                     this.getFieldArrayFromType('data3','ourteam-photo-list').map((d) => (
                                        <div key={d.id}>
                                            <img src={d.imageurl} />
                                            <div className="aboutus-our-team-carousel-desc">{d.text}</div>
                                        </div>
                                    ))

                                    // [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                    //     <div key={i}>
                                    //         <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />
                                    //         <div className="aboutus-our-team-carousel-desc">Picture Description</div>
                                    //     </div>
                                    // ))
                                }

                            </Carousel>
                        </div>}

                        <div id="aboutus-our-partners" style={{ marginTop: "18%" }}>
                            <h1>{this.getFieldFromType('data4','ourpartners-text1','text')}</h1>
                            <Divider className="aboutus-main-header-divider" />
                            <div id="aboutus-our-partners-corporate-partners">
                                <div className="aboutus-sub-header-container">
                                    <h2>{this.getFieldFromType('data4','ourpartners-text2','text')}</h2>
                                    <Divider style={{ width: "50%" }} className="aboutus-sub-header-divider" />
                                </div>
                                
                                <Carousel2
                                responsive={Globals.carouselEvents.responsive}
                                arrows
                                prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                                nextArrow={<FontAwesomeIcon icon={faAngleRight} />}>
                                {
                                    this.getFieldArrayFromType('data5','corporate-list').map((d) => (
                                        <div key={d.id}>
                                            <img src={d.imageurl} />
                                        </div>
                                    ))
                                }
                                </Carousel2>
                            </div>

                            <div id="aboutus-our-partners-public-partners">
                                <div className="aboutus-sub-header-container">
                                    <h2>{this.getFieldFromType('data4','ourpartners-text10','text')}</h2>
                                    <Divider style={{ width: "40%" }} className="aboutus-sub-header-divider" />
                                </div>
                                <Carousel2
                                responsive={Globals.carouselEvents.responsive}
                                arrows
                                prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                                nextArrow={<FontAwesomeIcon icon={faAngleRight} />}>
                                {
                                    this.getFieldArrayFromType('data7','public-list').map((d) => (
                                        <div key={d.id}>
                                            <img src={d.imageurl} />
                                        </div>
                                    ))
                                }
                                </Carousel2>
                            </div>

                            <div id="aboutus-our-partners-social-community-partners">
                                <div className="aboutus-sub-header-container">
                                    <h2>{this.getFieldFromType('data4','ourpartners-text3','text')}</h2>
                                    <Divider style={{ width: "40%" }} className="aboutus-sub-header-divider" />
                                </div>
                                {/* <Carousel2
                                responsive={Globals.carouselEvents.responsive}
                                arrows
                                prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                                nextArrow={<FontAwesomeIcon icon={faAngleRight} />}>
                                {
                                    this.getFieldArrayFromType('data6','social-community-list').map((d) => (
                                        <div key={d.id}>
                                            <img src={d.imageurl} />
                                        </div>
                                    ))
                                }
                                </Carousel2> */}
                                    <Container>
                                        <Row style={{marginLeft:"45px", marginBottom:"45px"}}>
                                            {/* <Col lg="4" xs="12">
                                            </Col> */}
                                            <Col lg="12" xs="12" className="aboutus-school-partner-text">
                                                {
                                                    this.getFieldArrayFromType('data6', 'social-community-list').map((d) => (
                                                        <div>{d.text}</div>
                                                    ))
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                            </div>
                            <div id="aboutus-our-partners-school-partners">
                                <div className="aboutus-sub-header-container">
                                    <h2>{this.getFieldFromType('data4','ourpartners-text4','text')}</h2>
                                    <Divider style={{ width: "50%" }} className="aboutus-sub-header-divider" />
                                </div>
                                
                            </div>

                            {/* {
                                    ["Central", "east", "north", "south", "west"].map((i) => ( */}
                                        <Container>
                                            <Row>
                                                <Col lg="4" xs="12">
                                                    <div className="aboutus-school-partner-region-text">{this.getFieldFromType('data4','ourpartners-text5','text')}</div>
                                                </Col>
                                                <Col lg="8" xs="12" className="aboutus-school-partner-text">
                                                {
                                                    this.getFieldArrayFromType('data4','ourpartners-central-list').map((d) => (
                                                        <div>{d.text}</div>
                                                    ))
                                                }
                                                    {/* <div>Holy Innocents' Primary School</div>
                                                    <div>Hougang Primary School</div>
                                                    <div>Hougang Sheng Hong Student Care Centre</div>
                                                    <div>Edgefield Primary School</div>
                                                    <div>Zhonghua Primary School</div>
                                                    <div>Pertapis Pch</div>
                                                    <div>Yio Chu Kang Primary School</div>
                                                    <div>Rivervale Primary School</div> */}
                                                </Col>
                                                {this.getFieldArrayFromType('data4','ourpartners-central-list').length> 0 && <Divider style={{ width: "70%", minWidth: "0", margin: "40px auto 40px 200px", borderTop: "1px solid #c5c5c5" }} />}
                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row>
                                                <Col lg="4" xs="12">
                                                    <div className="aboutus-school-partner-region-text">{this.getFieldFromType('data4','ourpartners-text7','text')}</div>
                                                </Col>
                                                <Col lg="8" xs="12" className="aboutus-school-partner-text">
                                                    {
                                                        this.getFieldArrayFromType('data4','ourpartners-north-list').map((d) => (
                                                            <div>{d.text}</div>
                                                        ))
                                                    }
                                                    {/* <div>Ahmad Ibrahim Primary School</div>
                                                    <div>Marsiling Primary School</div>
                                                    <div>Xishan Primary School</div>
                                                    <div>Yishun Primary School</div>
                                                    <div>Endeavor Primary School</div>
                                                    <div>First Toa Payoh Primary School</div>
                                                    <div>Townsville Primary School</div>
                                                    <div>Morningstar (Carenights Av)</div>
                                                    <div>Qihua Primary School</div>
                                                    <div>Naval Base Primary School</div>
                                                    <div>Innova Primary School</div>
                                                    <div>Ang Mo Kio Primary School</div>
                                                    <div>Huamin Primary School</div> */}
                                                </Col>
                                                {this.getFieldArrayFromType('data4','ourpartners-north-list').length> 0 && <Divider style={{ width: "70%", minWidth: "0", margin: "40px auto 40px 200px", borderTop: "1px solid #c5c5c5" }} />}
                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row>
                                                <Col lg="4" xs="12">
                                                    <div className="aboutus-school-partner-region-text">{this.getFieldFromType('data4','ourpartners-text8','text')}</div>
                                                </Col>
                                                <Col lg="8" xs="12" className="aboutus-school-partner-text">
                                                    {
                                                        this.getFieldArrayFromType('data4','ourpartners-south-list').map((d) => (
                                                            <div>{d.text}</div>
                                                        ))
                                                    }
                                                    {/* <div>Jalan Kukoh Rc/Catch Plus</div>
                                                    <div>New Town Primary School</div>
                                                    <div>Wells</div>
                                                    <div>Zhangde Primary School</div>
                                                    <div>Blangah Rise Primary School</div>
                                                    <div>Stamford Primary School</div>
                                                    <div>Radin Mas Primary School</div>
                                                    <div>Geylang Methodist School (Primary)</div> */}
                                                </Col>
                                                {this.getFieldArrayFromType('data4','ourpartners-south-list').length> 0 && <Divider style={{ width: "70%", minWidth: "0", margin: "40px auto 40px 200px", borderTop: "1px solid #c5c5c5" }} />}
                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row>
                                                <Col lg="4" xs="12">
                                                    <div className="aboutus-school-partner-region-text">{this.getFieldFromType('data4','ourpartners-text6','text')}</div>
                                                </Col>
                                                <Col lg="8" xs="12" className="aboutus-school-partner-text">
                                                    {
                                                        this.getFieldArrayFromType('data4','ourpartners-east-list').map((d) => (
                                                            <div>{d.text}</div>
                                                        ))
                                                    }
                                                    {/* <div>Morningstar (Blk 508)</div>
                                                    <div>Opera Estate Primary School</div>
                                                    <div>Park View Primary School</div>
                                                    <div>Angsana Primary School</div>
                                                    <div>Poi Ching School</div>
                                                    <div>Bedok Green Primary School</div> */}
                                                </Col>
                                                {this.getFieldArrayFromType('data4','ourpartners-east-list').length> 0 && <Divider style={{ width: "70%", minWidth: "0", margin: "40px auto 40px 200px", borderTop: "1px solid #c5c5c5" }} />}
                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row>
                                                <Col lg="4" xs="12">
                                                    <div className="aboutus-school-partner-region-text">{this.getFieldFromType('data4','ourpartners-text9','text')}</div>
                                                </Col>
                                                <Col lg="8" xs="12" className="aboutus-school-partner-text">
                                                    {
                                                        this.getFieldArrayFromType('data4','ourpartners-west-list').map((d) => (
                                                            <div>{d.text}</div>
                                                        ))
                                                    }
                                                    {/* <div>Boon Lay Garden Primary School</div>
                                                    <div>Clementi Primary School</div>
                                                    <div>Jurong West Primary School</div>
                                                    <div>Lakeside Primary School</div>
                                                    <div>Pioneer Primary School</div>
                                                    <div>South View Primary School</div>
                                                    <div>Lianhua Primary School</div>
                                                    <div>St Anthony Primary School</div>
                                                    <div>Pei Hwa Presbyterian Primary School</div>
                                                    <div>Qifa Primary School</div>
                                                    <div>Pertapis Bukit Panjang</div>
                                                    <div>Zhenghua Primary School</div> */}
                                                </Col>
                                                {this.getFieldArrayFromType('data4','ourpartners-west-list').length> 0 && <Divider style={{ width: "70%", minWidth: "0", margin: "40px auto 40px 200px", borderTop: "1px solid #c5c5c5" }} />}
                                            </Row>
                                        </Container>
                                    {/* ))
                                } */}

                        </div>
                    </Container>
                </div>
            </>
            }
            </>
        )
    }
}