
import BasePageComponent from '../components/BasePageComponent';
import { Container, Row, Col } from 'react-bootstrap';
import "./ServicesList.scss"
import "../../src/assets/Global.scss"
import Globals from "../Globals"
import _ from 'underscore/underscore'
import api from '../services/api'

export default class ServicesList extends BasePageComponent  { 
    constructor(props){
        super(props)
        this.state = {
            youthdeveloment: [],
            youthleadership: [],
            youthentrepreneur: []
        }
        console.log("youthdeveloment",this.state.youthdeveloment)
    }
    async componentDidMount(...args) {
        super.componentDidMount.apply(this,args);
        let res = await api.get(`/api/Common/Getpage?pagename=MenuServices`)
        if (res.data.StatusCode==1){
            if (res.data.Data.length>0){
                let data = JSON.parse(res.data.Data[0].jsonpage)
                let youthdeveloment = _.where(data.data1,{type:"youthdevelopment"})
                let youthleadership = _.where(data.data1,{type:"youthleadership"})
                let youthentrepreneur = _.where(data.data1,{type:"youthentrepreneurship"})
                this.setState({
                    youthdeveloment: youthdeveloment,
                    youthleadership: youthleadership,
                    youthentrepreneur: youthentrepreneur
                })
            }
        }
    }
    
    openUrl = (e, svc)=>{
        // console.log(typeof e.target)
        window.location.href = `${svc}`
    }

    render(){
        return super.render(
        <>    
        <Container className="ServicesList">
        <h2>YOUTH Development</h2>
        {this.state.youthdeveloment.map((d)=>(
            <div className="menu-item" onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
        ))}
        <h2>YOUTH Leadership</h2>
        {this.state.youthleadership.map((d)=>(
            <div className="menu-item" onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
        ))}
        <h2>YOUTH Entrepreneurship</h2>
        {this.state.youthentrepreneur.map((d)=>(
            <div className="menu-item" onClick={(e) => this.openUrl(e, d.url)}>{d.text}</div>
        ))}
        </Container>
           
        </>
        )
    }
}