import React, { Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import api from '../services/api';
import { getEnvVars } from '../Environment'

const { oAuthConfig } = getEnvVars();

export default class ModalCookie extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isReady: false,
            parent: props.parent,
        }
    }
    componentDidMount() {
        this.setState({isReady: true})
    }
    handleClose = ()=>{
        this.props.parent.setState({modalCookie: false})
        window.localStorage.setItem('@cookieconsent',1)
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleOK = ()=>{
        this.handleClose()
    }
    render(){
        const state = this.state.parent.state
        return (
            <>
            {this.state.isReady && 
            <Modal show={state.modalCookie} onHide={this.handleClose} dialogClassName="ModalCookie">
              <Modal.Header closeButton>
                <Modal.Title>Privacy Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                This website uses cookies to ensure you get the best experience on our website. <a href={"/privacypolicy"}>Learn more</a>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleOK}>
                    Got It!
                </Button>
              </Modal.Footer>
            </Modal>
            }
            </>
        )
    }
}