import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalFooter from "react-bootstrap/ModalFooter";
// import ModalTitle from "react-bootstrap/ModalTitle";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import api from '../services/api';
import { getEnvVars } from '../Environment'
import ModalMessage from './ModalMessage'
import { GlobalContext } from '../Globals'
import { Alert,Button as Buttonstrap } from 'react-bootstrap';

const { oAuthConfig } = getEnvVars();

export default class ModalSignup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            parent: props.parent,
            salutation: "",
            fullname: "",
            nric: "",
            email: "",
            password: "",
            confirmpassword: "",
            gender: "",
            mobilenumber: "",
            invalidSignup: false,
            msg: "",
            agree: false,
            modalMessage: false,
            errorMessage: ""
        }

    }   

    componentDidMount() {
        //FnGetData();
        this.setState({ isReady: true })
    }
    handleClose = () => {
        this.setState({
            salutation: "",
            fullname: "",
            nric: "",
            email: "",
            password: "",
            confirmpassword: "",
            gender: "",
            mobilenumber: "",
            msg: "",
            passwordShown: false,
            cfmPasswordShown: false
        })
        this.props.parent.setState({ modalSignup: false })
    }
    togglePassword = (e) => {
        e.preventDefault();
        this.setState({ passwordShown: !this.state.passwordShown })
    };
    toggleCfmPassword = (e) => {
        e.preventDefault();
        this.setState({ cfmPasswordShown: !this.state.cfmPasswordShown })
    };
    Login = async () => {
        var isPasswordValid = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])")
        var isPasswordInvalid = new RegExp("^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$")
        //console.log(isPasswordValid.test(this.state.password))

        if (this.state.salutation == "" || this.state.fullname == "" || this.state.email == "" || this.state.password == ""
            || this.state.confirmpassword == "" || this.state.gender == "" || this.state.mobilenumber == "") {
            this.setState({ invalidSignup: true, errorMessage: "Please fill in all details", modalMessage: true })
            return;
        }
        // else if (this.state.password.length<8 || this.state.password.search(/[0-9]/) <0 || this.state.password.search(/[a-z]/i) <0) {
        else if (isPasswordInvalid.test(this.state.password)){
            this.setState({ invalidSignup: true, errorMessage: "Please make sure your password meets the requirements", modalMessage: true })
            return;
        }
        else if (this.state.password != this.state.confirmpassword) {
            this.setState({ invalidSignup: true, errorMessage: "Passwords do not match", modalMessage: true })
            return;
        }
        else if (this.state.agree == false) {
            this.setState({ invalidSignup: true, errorMessage: "Please agree to Terms of Use", modalMessage: true })
            return;
        }
        
        //this.setState({ invalidSignup: false})
        var info = {
            salutation: this.state.salutation,
            fullname: this.state.fullname,
            nric: this.state.nric,
            email: this.state.email,
            password: this.state.password,
            gender: this.state.gender,
            mobilenumber: this.state.mobilenumber,
        }

        try {
            let res = api.post('/api/Mobile/RegisterAccount', info)
                .then((res) => {
                    console.log("SIGNUP", res)
                    if (res.data.StatusCode == 1) {
                        this.setState({
                            salutation: "",
                            fullname: "",
                            nric: "",
                            email: "",
                            password: "",
                            confirmpassword: "",
                            gender: "",
                            mobilenumber: "",
                            invalidSignup: true,
                            msg: "Please check your email for activation link",
                            modalMessage: true
                        })
                    } else {
                        this.setState({ invalidLogin: true, errorMessage: res.data.Message, modalMessage: true })
                    }
                })
                .then((res2) => { console.log("ERROR-MIKE2: ", res2) })
                .catch((err) => {
                    console.log("ERROR-MIKE3: ", err)
                    this.setState({ invalidLogin: true })
                })
            console.log(res)
        } catch (err) {
            console.log("ERROR-MIKE: ", err)
            this.props.parent.setState({ invalidLogin: true })
        }

        /* let param = `grant_type=password&username=${email}&password=${password}&client_id=${oAuthConfig.clientId}&client_secret=${oAuthConfig.clientSecret}`
        let url = oAuthConfig.issuer + "/connect/token"
        try {
            let res = api.post(url, param)
                .then((res) => {
                    console.log("MIKEEEEEE", res)
                    if (res.data.access_token) {
                        url = oAuthConfig.issuer + `/api/Mobile/GetAccountByEmail?email=${email}`
                        api.get(url).then((d) => {
                            let accountData = d.data.Data[0]
                            accountData = btoa(JSON.stringify(accountData))
                            window.localStorage.setItem("@user", accountData)
                            this.state.parent.setState({ modalSignup: false })
                            this.setState({ invalidLogin: false })
                            window.location.reload()
                        })
                    } else {
                        this.setState({ invalidLogin: true })
                    }
                })
                .then((res2) => { console.log("ERROR-MIKE2: ", res2) })
                .catch((err) => {
                    console.log("ERROR-MIKE3: ", err)
                    this.setState({ invalidLogin: true })
                })
            console.log(res)
        } catch (err) {
            console.log("ERROR-MIKE: ", err)
            this.props.parent.setState({ invalidLogin: true })
        } */
    }
    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    render() {
        const state = this.state.parent.state
        const globalContext = this.context;
        return (
            <>
                {this.state.isReady &&
                    <Modal show={state.modalSignup} onHide={this.handleClose} dialogClassName="ModalSignup" variant="primary">
                        <Modal.Header closeButton>
                            <Modal.Title>Sign up for a Youthbank account and start MAKING A DIFFERENCE!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert className='' style={{marginTop:"0px!important"}} show={this.state.errorMessage.length>0} variant="danger">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    {this.state.errorMessage}
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Buttonstrap onClick={() => this.setState({errorMessage: ""})} variant="outline-danger">
                                        OK
                                    </Buttonstrap>
                                </div>
                            </Alert>
                            <Alert className='' style={{marginTop:"0px!important"}} show={this.state.msg.length>0} variant="success">
                                <Alert.Heading>Success</Alert.Heading>
                                <p>
                                    {this.state.msg}
                                </p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Buttonstrap onClick={() => this.setState({msg: ""})} variant="outline-success">
                                        OK
                                    </Buttonstrap>
                                </div>
                            </Alert>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Salutation</Form.Label>
                                    <Form.Select name="salutation" onChange={this.handleChange}>
                                        <option>Select Salutation</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Mdm">Mdm</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Fullname (As in NRIC)</Form.Label>
                                    <Form.Control name="fullname" type="text" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                                {/* <Form.Group className="mb-3">
                                    <Form.Label>NRIC No.</Form.Label>
                                    <Form.Control name="nric" type="text" placeholder="" onChange={this.handleChange} />
                                </Form.Group> */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Gender</Form.Label>
                                    <Col>
                                        <Form.Check
                                            inline
                                            label="Male"
                                            name="gender"
                                            type='radio'
                                            id={`male`}
                                            checked={this.state.gender === "0"}
                                            onChange={(e) => { this.setState({ gender: e.target.value }) }}
                                            defaultValue="0"
                                        />
                                        <Form.Check
                                            inline
                                            label="Female"
                                            name="gender"
                                            type='radio'
                                            id={`female`}
                                            checked={this.state.gender === "1"}
                                            onChange={(e) => { this.setState({ gender: e.target.value }) }}
                                            defaultValue="1"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control name="mobilenumber" type="text" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control name="email" type="email" placeholder="" onChange={this.handleChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Password</Form.Label>
                                    <div style={{ display: "flex" }}>
                                        <Form.Control name="password" type={this.state.passwordShown ? "text" : "password"} placeholder="" onChange={this.handleChange} />
                                        {this.state.passwordShown ? <button className="eye-button" onClick={this.togglePassword}><i className="fa fa-eye"></i></button> : <button className="eye-button" onClick={this.togglePassword}><i className="fa fa-eye-slash"></i></button>}
                                    </div>
                                </Form.Group>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Confirm Password</Form.Label>
                                    <div style={{ display: "flex" }}>
                                        <Form.Control name="confirmpassword" type={this.state.cfmPasswordShown ? "text" : "password"} placeholder="" onChange={this.handleChange} />
                                        {this.state.cfmPasswordShown ? <button className="eye-button" onClick={this.toggleCfmPassword}><i className="fa fa-eye"></i></button> : <button className="eye-button" onClick={this.toggleCfmPassword}><i className="fa fa-eye-slash"></i></button>}
                                    </div>
                                    <p style={{ color: "red" }}>
                                        Please make sure your password contains at least one uppercase letter, one lowercase letter, one number, one special character and min length is 8.
                                    </p>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        value={this.state.agree}
                                        onChange={(e) => { this.setState({ agree: e.target.checked }) }}
                                        label={(globalContext.length >0 && globalContext.find(element => element.syscode == 'SIGNUPDISCTEXT')) ?
                                        (<>{JSON.parse(globalContext.find(element => element.syscode == 'SIGNUPDISCTEXT').value).pretext} <a href={JSON.parse(globalContext.find(element => element.syscode == 'SIGNUPDISCTEXT').value).url} target="_blank">{JSON.parse(globalContext.find(element => element.syscode == 'SIGNUPDISCTEXT').value).linktext}</a></>)
                                        : 
                                        (<>By signing up for an account on Youthbank, you agreed to the <a href={"/privacypolicy"} target="_blank">Terms of use and Privacy Policy</a></>)} />
                                </Form.Group>
                                {this.state.invalidSignup &&
                                    <p style={{ color: "red" }}>{this.state.msg}</p>
                                }
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.Login}>
                                Sign Up
                </Button>
                        </Modal.Footer>
                    </Modal>

                }
                {/* {this.state.modalMessage && <ModalMessage parent={this} />} */}
            </>
        )
    }
}
ModalSignup.contextType = GlobalContext;