import React, { Component, Fragment,useEffect, useRef } from 'react';
import api from '../services/api';

export default class Sso extends Component {
    constructor(props){
        super(props)
        this.state = {
           
        }
    }
    async componentDidMount() {
        //http://localhost:3001/sso?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InlvdXRoYmFua0BoZWFydHdhcmUtbmV0d29yay5vcmciLCJqdGkiOiIyMFkzT1RGWlJNIiwiaWF0IjoxNjQyMzk3MTk5LCJleHAiOjE2NDI0ODM1OTl9.ZVB8w1FqwldIPCsbbnOnGE0e9-jCEWyT3nmz_CXvNGw&l=L3F1ZXN0aW9ubmFpcmU/YXNzdWlkPTRiNGNlMjJlLTk4YmMtNDM1My05Y2VmLWRmNzNjYzAwN2ZjNQ==
        const params = new URLSearchParams(window.location.search)
        const t = params.get("t");
        const l = params.get("l");
        if (t && l){
            let res = await api.get(`/api/mobile/sso?t=${t}`)
            // console.log("aaaaabbbbccc",res.data.StatusCode)
            if (res.data.StatusCode==0){
                // alert("invalid sso to do here")
                window.location.href = "/"
            }else{
                // console.log(atob(l))
                window.mySession.start();
                let accountData = res.data.Data[0]
                accountData = btoa(JSON.stringify(accountData))
                console.log(accountData);
                window.localStorage.setItem("@user",accountData)
                window.location.href = atob(l)
            }
        }
    }
    
    render = ()=>{
        return(
            <>
                <div>Redirecting, please wait...</div>
            </>
          )
    }
    
  }