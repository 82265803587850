import React, { Component, Fragment,useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import Modal from 'react-bootstrap/Modal'
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import DynamicForm from 'dynamic-form-json';
import DataGrid, { Column, Editing, Paging, Lookup } from 'devextreme-react/data-grid';

import "../../node_modules/devextreme/dist/css/dx.common.css";             
import "../../node_modules/devextreme/dist/css/dx.light.css";             
import { ConstantLine } from 'devextreme-react/chart';
import './ToolbarModal.scss';

export default class ModalForm extends Component {
  customizeColumns = (columns) => {
    columns[0].visible = false;
  }

    constructor(props){
        super(props)
        this.state = {
            parent: props.parent,
            isReady: false,
        }
    }
    componentDidMount() {
        this.setState({isReady: true})
    }
    handleClose = ()=>{
        this.props.parent.setState({modalOpen: false})
    }
    onSaved = (data)=>{
        let modalAttributes = this.state.parent.state.modalAttributes;
        this.state.parent.setState({
            modalAttributes:modalAttributes
        })
        this.state.parent.saveAttributes()
        //console.log(state.modalAttributes)
    }
    onInitialized = (e)=>{
      // for (let i in this.state.parent.state.modalAttributes){

      // }
      // console.log("AABBCCDDDEEFF",document.getElementById("datagrid0"))
      // console.log("AABBCCDDDEEFF",this.refs.data0.columnOption("id", "visible", false))
    }
    render = ()=>{
        const state = this.state.parent.state
        return(
            <>
            {this.state.isReady && 
            <Modal show={state.modalOpen} onHide={this.handleClose} dialogClassName="ToolbarModal">
              <Modal.Header closeButton>
                <Modal.Title>Edit Attributes</Modal.Title>
              </Modal.Header>
              <Modal.Body>

              {/* */}
              {state.modalAttributes.map((data,i)=>(
                   <DataGrid
                   dataSource={data}
                   keyExpr="id"
                   allowColumnReordering={true}
                   showBorders={true}
                   showColumnLines={true}
                   showRowLines={true}
                  //  columnAutoWidth={true} 
                   columnMinWidth={50}
                   columnMaxWidth={80}
                   onSaved={this.onSaved}
                   onRowRemoving={this.onRowRemoving}
                   key={i}
                   onInitialized={this.onInitialized}
                   id={"datagrid"+i.toString()}
                   customizeColumns={this.customizeColumns}
                   >
                   <Paging enabled={true} />
                   <Editing
                       mode="row"
                       allowUpdating={true}
                       allowDeleting={true}
                       allowAdding={true} />
                    </DataGrid>
                )
              )}    

        
                 {/* <DynamicForm fields={this.state.formData}/> */}
              </Modal.Body>
              <Modal.Footer>
                {/* <Button variant="primary" onClick={handleSubmit}>
                  Submit 
                </Button> */}
              </Modal.Footer>
            </Modal>
            }
            </>
          )
    }
    
  }