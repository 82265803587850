import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Service.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faChalkboardTeacher, faChild, faFlag, faPeopleCarry, faSchool, faSeedling, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import { Divider } from 'antd'
import { FullpageAccordion, Panel } from "react-fullpage-accordion";
import "react-fullpage-accordion/dist/react-fullpage-accordion.css";
import Globals from '../Globals'
import Carousel2 from 'react-multi-carousel';
import { Carousel as AntdCarousel } from 'antd'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import api from '../services/api';




export default class SingleService extends Component {
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            headerImageData: [],
            servicename: props.servicename,
            dataHeader: props.dataHeader,
            dataifyouare: props.dataifyouare,
            dataRoles: props.dataRoles,
            // events: props.event
            events: [],

        }
    }

    componentDidMount(){
        this.FnGetData()
    }

    FnGetData = async() => {
        const { servicename } = this.state
        let url = "/api/front/GetServiceEvents?servicename=" + servicename;
        console.log('url', url)
        api.get(url).then((res) => {
            this.setState({
                events: res.data.Data,
            })
            console.log('componentDidMount', this.state.events)
        })
    }


    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    render() {
        let isMobile = this.state.isMobile;
        const { dataHeader, dataifyouare, dataRoles } = this.state
        return (
            <>

                {/* Section title */}
                <Container style={{ marginTop: "5%", padding: "0em 6em" }}>
                    <Row>
                        <Col lg="10" xs="12" className="col-center-content">
                            <div className="service-section-main-content-title">{dataHeader.title}</div>
                        </Col>
                        <Col lg="2" xs="12" className="col-center-content">
                            <img src={dataHeader.imgsrc} />
                        </Col>
                    </Row>
                </Container>

                <Container className="service-carousel-container" style={{ marginTop: "2%" }}>
                    <AntdCarousel
                        arrows
                        autoplay
                        dots={false}
                        prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
                        nextArrow={<FontAwesomeIcon icon={faAngleRight} />}
                    >

                        {
                            isMobile ?
                                [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                    <Container key={i}>
                                        <Row  >
                                            <Col lg="4" xs="12">
                                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />

                                            </Col>

                                        </Row>
                                    </Container>


                                ))
                                :
                                [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                    <Container key={i}>
                                        <Row  >
                                            <Col lg="4" xs="12">
                                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />

                                            </Col>
                                            <Col lg="4" xs="12">
                                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />

                                            </Col>
                                            <Col lg="4" xs="12">
                                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/1e6094bf1801f3d55c9b5714b032954.png" />

                                            </Col>
                                        </Row>
                                    </Container>


                                ))
                        }


                    </AntdCarousel>
                </Container>

                {/* Section Description */}
                <Container className="service-section-description-container" style={{ marginTop: "2%" }}>

                    <Row style={{ padding: "3em 0em" }}>
                        <Col lg="7" xs="12">
                            <div className="service-page-sub-header">{dataHeader.subtitle}</div>
                            <p>{dataHeader.desc1}</p>
                            <p>{dataHeader.desc2}</p>
                            <p>{dataHeader.desc3}</p>
                            {/* <button className="service-button-join-event">Join Events</button> */}
                        </Col>
                        <Col lg="5" xs="12">
                            <div className="service-page-sub-header">ENROL NOW</div>
                            <ul className="service-event-list-items">
                                {
                                    this.state.events.map((i) => (
                                        <li className="service-event-list-item" key={i}>
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                            <a href={`/eventdetail/?eventid=${i.eventid}`}>{i.eventname}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Col>
                    </Row>
                    <Divider style={{ borderTop: "2.5px solid #d1c9c3", minWidth: "65%", width: "65%", textAlign: "center", margin: "2.5em auto" }} />
                </Container>


                {/* Section Volunteer if you are */}
                <Container className="service-section-ifyouare" style={{ marginTop: "5%" }}>
                    <div className="service-page-sub-header">VOLUNTEER IF YOU ARE</div>
                    <Row style={{ backgroundColor: "white", paddingBottom: "2%" }}>
                        {
                            Array.from(Array(dataifyouare.length).keys()).map(i => (
                                <Col lg="6" xs="12" key={i}>
                                    <div>
                                        <FontAwesomeIcon className="service-section-ifyouare-card icon" icon={dataifyouare[i].icon} />
                                        <div className="service-section-ifyouare-card text">{dataifyouare[i].cardtext}</div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                    <Divider style={{ borderTop: "2.5px solid #d1c9c3", minWidth: "65%", width: "65%", textAlign: "center", margin: "2.5em auto" }} />
                </Container>

                {/* Section Volunteer roles */}
                <Container className="service-section-roles" style={{ marginTop: "7%" }}>
                    <div className="service-page-sub-header">VOLUNTEER ROLES</div>
                    <Row style={{ backgroundColor: "white", paddingBottom: "2%" }}>
                        {
                            Array.from(Array(dataRoles.length).keys()).map(i => (
                                <Col lg="4" xs="12">
                                    <div>
                                        <FontAwesomeIcon className="service-section-roles-card icon" icon={dataRoles[i].icon} />
                                        <div className="service-section-roles-card text">{dataRoles[i].cardtext}</div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>



                {/* Section volunteer testimonial */}
                <Container style={{ marginTop: "7%" }}>
                    <div className="section-center-header-text">What They Say</div>
                    <Row>
                        <Carousel
                            // className="carousel-events"
                            showThumbs={false}
                            centerMode
                            centerSlidePercentage={100 / (isMobile ? 1 : 3)}
                            swipeable={true}
                            showArrows={true}
                            autoPlay={true}
                        >
                            {
                                [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                    <div className="service-section-testimonial-card-container" key={i}>
                                        <div className="service-section-testimonial-card-container-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</div>
                                        <Container>
                                            <Row>
                                                <Col lg="6" xs="6" style={{ textAlign: "right" }}>
                                                    <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/plugins/elementor/assets/images/placeholder.png" />
                                                </Col>
                                                <Col lg="6" xs="6" style={{ textAlign: "left" }}>
                                                    <div className="service-section-testimonial-card-container-name">John Doe</div>
                                                    <div className="service-section-testimonial-card-container-title">CEO</div>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </div>
                                ))
                            }
                        </Carousel>
                    </Row>
                </Container>





            </>

        )
    }
}