import React, { Component} from 'react';
import api from '../services/api';

export default class SectionComponent extends Component  { 
    constructor(props){
        super(props)
    }

    async componentDidMount () {
        let sectionName = this.state.sectionName
        let sections = this.props.parent.state.sections 
        sections[sectionName] = this
        this.props.parent.setState({sections:sections})
        let res = await api.get(`/api/Common/Getpage?pagename=${sectionName}`)
        if (res.data.StatusCode==1){
            if (res.data.Data.length>0){
                console.log("componentDidMount",res.data.Data[0].jsonpage)
                let data = JSON.parse(res.data.Data[0].jsonpage)
                this.setState({attributes: data})
            }
        }
        
    }
}