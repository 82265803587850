import React, { Component } from 'react';
import { Container, Row, Col, Alert, Button as Buttonstrap } from 'react-bootstrap';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./Eventdetail.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArchive, faBook, faCalendar, faCalendarAlt, faCalendarCheck, faCalendarDay, faClock, faFile, faMapMarkerAlt, faUserClock, faUsers } from '@fortawesome/free-solid-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import qs from 'querystring'
import api from '../services/api';
import { S3_PUBLIC_URL } from './common'
import { Modal, Form, Radio, Input, Checkbox, Button } from 'antd'
import _, { map } from 'underscore';
import moment from 'moment'
import ProForm, {
    ModalForm
} from '@ant-design/pro-form';
import { ContactSupportOutlined } from '@material-ui/icons';
import BasePageComponent from '../components/BasePageComponent'
import Globals from '../Globals'
import { GlobalContext } from '../Globals'

export default class OpportunityDetail extends BasePageComponent {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 1024,
            eventid: 0,
            data: [],
            dataDoc: [],
            visible: false,
            dataEventActivity: [],
            activities: [],
            isLoginModalVisible: false,
            isLoginModalMessage: "",
            agree: false,
            errorMsg: "",
            successMessage: ""
        }

    }
    componentWillReceiveProps = (props) => {

    }
    componentDidMount(...args) {

        super.componentDidMount.apply(this, args);
        // let account = JSON.parse(window.atob(window.localStorage.getItem("@user"))).accountid
        // const obj = JSON.parse('{"name":"John", "age":30, "city":"New York"}');
        // console.log('test', account);
        // console.log('testdecode',window.atob(test))
        this.setState({ isLoaded: true })
        // Restore coming from API
        const { search } = this.props.location;
        const { oppid } = qs.parse(search.slice(1))
        let url = "/api/front/GetOppDetail?eventid=" + oppid;
        console.log('url', url)
        api.get(url).then((res) => {
            this.setState({
                data: res.data.Data,
                eventid: oppid,
                activities: []
            })
            console.log('componentDidMount', res.data.Data)
        })

        let urlDoc = "/api/front/GetOppDetailDoc?eventid=" + oppid;
        console.log('urlDoc', urlDoc)
        api.get(urlDoc).then((res) => {
            this.setState({
                dataDoc: res.data.Data
            })
            console.log('componentDidMountdoc', res.data.Data)
        })




    }
    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }

    setVisible = (v) => {
        this.setState({
            visible: v
        })
    }

    checkisLogin = async () => {
        console.log(this.state.activities)

        if (!Globals.userdata.accountid) {
            console.log('userData', Globals.userdata.accountid);
            let msg = "Please login to apply";
            this.setState({
                isLoginModalMessage: msg,
                isLoginModalVisible: true
            })
        } else {
            let eventid = this.state.eventid
            let account = Globals.userdata.accountid

            let urlcheckJoined = "/api/front/checkExistingOppApplication?accountid=" + account + "&eventid=" + eventid;
            let data_applied = await api.get(urlcheckJoined)
            console.log(data_applied, "applied guid")
            if (data_applied.data.Data.length > 0) {
                this.setState({ applicationguid: data_applied.data.Data[0].guid })
            }
            else {

                let url = `/Opportunity/MobileApplyOpportunity?accountid=${Globals.userdata.accountid}&opportunityid=${this.state.eventid}&type=APPLICATION`
                let data_guid = await api.get(url);
                let guid = data_guid.guid;
                this.setState({ applicationguid: guid });
            }
            console.log(window.location.protocol + "//" + window.location.host + `/questionnaire?assuid=${this.state.applicationguid}`);
            window.open(window.location.protocol + "//" + window.location.host + `/questionnaire?assuid=${this.state.applicationguid}`);
        }
    }


    onCreate = async () => {
        if (this.state.data[0] && this.state.data[0].requiredisclaimer && !this.state.agree) {
            this.setState({
                isLoginModalMessage: "Please agree to the Terms of Use.",
                isLoginModalVisible: true
            })
            return;
        }
        let _data = _.clone(this.state.activities)
        api.post("/api/front/EnrollActivities", _data)
            .then((res) => {
                this.setState({ successMessage: "HWN will call or email back to schedule an interview" })
                console.log("EnrollActivities", res)
            }).catch((err) => {
                console.log("err", err)
            })
        this.setVisible(false);
    }

    handleCheckisLoginModalOk = () => {
        this.setState({
            isLoginModalVisible: false
        })

    }

    handleCheckisLoginModalCancel = () => {
        this.setState({
            isLoginModalVisible: false
        })

    }

    viewFactsheet = async (e) => {
        e.preventDefault()
        const { search } = this.props.location;
        const { oppid } = qs.parse(search.slice(1))
        var info = {
            reportnum: 'Opportunity_Factsheet_01',
            params: [{ "paraname": "@opportunityid", "parareplace": String(oppid), "type": "int" }
            ]
        };
        var result = await api.post(`api/report/GetFormReport`, info);
        if (result) {
            const bytes = atob(result.data);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            const file = new Blob([out], { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }
    }



    // onCreate = (values) => {
    //     console.log('onCreate', this.state.activities);
    //     console.log('Received values of form: ', values);
    //     let eventid = this.state.data[0].eventid;
    //     let accountid = 21262;
    //     let remark = 'testRemark'

    //     this.setVisible(false);
    // }




    render() {

        const globalContext = this.context;
        const { isMobile, data, dataDoc, dataEventActivity } = this.state;
        console.log('render', data)
        let isover = data[0] ? data[0].isover : false;
        let showfactsheet = data[0] ? data[0].showfactsheet : false;
        let requiredisclaimer = data[0] ? data[0].requiredisclaimer : false;
        console.log('isover', isover)
        return super.render(
            <>
                <>
                    <Alert show={this.state.successMessage.length > 0} variant="success">
                        <Alert.Heading>Enrol Sent</Alert.Heading>
                        <p>
                            {this.state.successMessage}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end">
                            <Buttonstrap onClick={() => this.setState({ successMessage: "" })} variant="outline-success">
                                OK
                            </Buttonstrap>
                        </div>
                    </Alert>
                </>

                <div className="otherpagebody">
                    {/* Section title  */}
                    {
                        // [0,1,2,3,4,5,6,7,8,9,10].map((i) => (
                        Array.from(Array(data.length).keys()).map((i) => (
                            <Container key={i} style={{ marginTop: "5%" }} className='event-detail-header-container'>
                                <Row>
                                    <Col lg="9" xs="12">
                                        <div className="section-main-content-title">{data[0].opportunityname}</div>
                                    </Col>
                                    <Col lg="3" xs="12" className="col-center-content" style={isover ? { visibility: "hidden" } : { visibility: "visible" }}>
                                        <button onClick={this.checkisLogin} className="button-join-event">Apply Now</button>
                                    </Col>
                                </Row>
                            </Container>
                        ))
                    }

                    {/*  Section main content */}
                    {
                        // [0,1,2,3,4,5,6,7,8,9,10].map((i) => (
                        Array.from(Array(data.length).keys()).map((i) => (
                            <Container key={i} style={{ marginTop: "2%" }}>
                                <Row>
                                    <Col lg="6" xs="12">
                                        <img className="section-main-content-image" src={S3_PUBLIC_URL + data[0].posterimg} />
                                    </Col>
                                    <Col lg="6" xs="12">
                                        <div className="section-main-content-info">
                                            {/* <p>{data[0].eventdescription}</p> */}
                                            <div dangerouslySetInnerHTML={{ __html: data[0].opportunitydescription }}></div>
                                            <div className="info-header-text">Information</div>
                                            <div className="info-list-container">
                                                <div>
                                                    <div className="info-list-item-title"><FontAwesomeIcon icon={faCalendarAlt} />APPLICATION PERIOD</div>
                                                    <div className="info-list-item-text">{data[0].schedule == null ? -'-' : data[0].schedule}</div>
                                                </div>
                                                <div>
                                                    <div className="info-list-item-title"><FontAwesomeIcon icon={faUsers} />MAX AWARDEES</div>
                                                    <div className="info-list-item-text">{data[0].maxvolunteers == null ? '-' : data[0].maxvolunteers}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        ))
                    }


                    {/* Section document */}
                    <Container className="section-document-container" style={{ marginTop: "7%" }}>
                        <div style={{ marginBottom: "20px" }}>
                            <FontAwesomeIcon className="section-document-title icon" icon={faFile} />
                            <div className="section-document-title text">DOCUMENTS</div>
                        </div>
                        {/* {showfactsheet && */}
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a onClick={this.viewFactsheet}>Factsheet</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        {/* } */}
                        {
                            Array.from(Array(dataDoc.length).keys()).map((i) => (
                                <table key={i}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {dataDoc[i].documentpath_s3 ?
                                                    <a href={S3_PUBLIC_URL + dataDoc[i].documentpath_s3}>{dataDoc[i].documentpath_s3.substring(dataDoc[i].documentpath_s3.lastIndexOf('/') + 1)}</a>
                                                    : <a href="https://www.youthbank.sg/service/event/downloadDocument/UPLOAD_20210629054732524.jpg/HWSOP21%20Digital%20Learning%20Workshop%20for%20Seniors%20Q3%20Volunteer%20EDM%20(UD%2029%20June%202021).jpg">{dataDoc[i].documenttitle}</a>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            ))
                        }

                        <div className="col-center-content" style={{ marginTop: "60px" }}>
                            <button onClick={this.checkisLogin} className="button-join-event" style={isover ? { visibility: "hidden" } : { visibility: "visible" }}>Apply Now</button>
                        </div>


                    </Container>

                    <Modal title="Unable to apply" visible={this.state.isLoginModalVisible}
                        footer={[
                            <Button key="back" onClick={this.handleCheckisLoginModalOk}>OK</Button>]}
                        onCancel={this.handleCheckisLoginModalOk}>
                        <p>{this.state.isLoginModalMessage}</p>
                    </Modal>


                    {/* Section Organizedby */}
                    {/* <Container className="section-organizedby-container" style={{ marginTop: "7%" }}>
                        <div className="section-center-header-text">Organized By</div>
                        <Row>
                            <Col lg="4" xs="12">
                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/Heartware-Network-Logo-pa1ev6328nbyiio3ki7vvqjdini66p3k5sbgbswhts.png" />
                            </Col>
                            <Col lg="4" xs="12">
                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/youthbank_logo3-4-p9fo7swnwkltkc54u7kxq7xbhcjab2m0sd4dkiuhh4.png" />
                            </Col>
                            <Col lg="4" xs="12">
                                <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/elementor/thumbs/sg-cares-logo-pa1f2wb8ltr18z7sbl7e0btadgroeaxayks671ikp6.png" />
                            </Col>
                        </Row>
                    </Container> */}

                    {/* Section volunteer testimonial */}
                    {/* <Container style={{ marginTop: "7%" }}>
                        <div className="section-center-header-text">Our Volunteers Say</div>
                        <Row>
                            <Carousel
                                // className="carousel-events"
                                showThumbs={false}
                                centerMode
                                centerSlidePercentage={100 / (isMobile ? 1 : 3)}
                                swipeable={true}
                                showArrows={true}
                                autoPlay={true}
                            >
                                {
                                    [0, 1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                                        <div className="section-testimonial-card-container" key={i}>
                                            <div className="section-testimonial-card-container-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</div>
                                            <Container>
                                                <Row>
                                                    <Col lg="6" xs="6" style={{ textAlign: "right" }}>
                                                        <img src="https://ydmsdesign.bizpoint-intl.com/wp-content/plugins/elementor/assets/images/placeholder.png" />
                                                    </Col>
                                                    <Col lg="6" xs="6" style={{ textAlign: "left" }}>
                                                        <div className="section-testimonial-card-container-name">John Doe</div>
                                                        <div className="section-testimonial-card-container-title">CEO</div>
                                                    </Col>
                                                </Row>
                                            </Container>

                                        </div>
                                    ))
                                }
                            </Carousel>
                        </Row>
                    </Container> */}


                    <Modal className="event-activity-join-event-modal"
                        visible={this.state.visible}
                        title="Join Event"
                        okText="Enrol Now"
                        cancelText="Cancel"
                        onCancel={() => {
                            this.setVisible(false);
                        }}
                        onOk={() => {
                            console.log(this.state.activities[0])
                            // console.log(this.state.activities[0]['remark'])
                            var lError = false;
                            for (let i in this.state.activities) {
                                if (this.state.activities[i]['selection'] == 'UNABLE' && this.state.activities[i]['remark'] == "") {
                                    lError = true;
                                    break
                                }
                            }
                            if (lError) {
                                this.setState({ errorMsg: "Please provide reason why you are unable to attend" })
                                return
                            }
                            if (this.state.activities[0]['selection'] == 'UNABLE' && this.state.activities[0]['remark'] == "") {
                                this.setState({ errorMsg: "Please provide reason why you are unable to attend" })
                                return
                            }
                            this.formRef.current
                                .validateFields()
                                .then((values) => {
                                    this.formRef.current.resetFields();
                                    this.onCreate(values);
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                    >
                        <Form
                            layout="vertical"
                            ref={this.formRef}
                            name="form_in_modal"
                        >
                            {dataEventActivity.length == 0 && <p>No Available Activities At The Moment.</p>}
                            {
                                dataEventActivity.map(grp => (
                                    <div className="event-activity-group" key={grp[0]}>
                                        <div className="event-activity-group-title">{grp[0].split('|')[1] == '' ? '-' : grp[0].split('|')[1]}</div>
                                        {
                                            grp[1].map(item => (
                                                <div className="event-activity-section" key={item.guid}>
                                                    <div className="event-activity-name">{item.activityname}</div>
                                                    <div className="event-activity-date"><FontAwesomeIcon icon={faCalendar} />{moment.utc(item.eventdate).local().format("DD/MM/YYYY") + " " + item.dstarttime + " - " + item.dendtime}</div>
                                                    <Form.Item name={item.activityid} noStyle>
                                                        <Input type="text" value={item.activityid} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={item.activityid}
                                                        initialValue={'ABLE'}
                                                    >
                                                        <Radio.Group defaultValue="ABLE" buttonStyle="solid"
                                                            onChange={(value) => {
                                                                let activities = this.state.activities;
                                                                activities[_.findIndex(activities, { guid: item.guid })]["selection"] = value.target.value
                                                                this.setState({
                                                                    activities: activities,
                                                                    errorMsg: ""
                                                                })
                                                            }}
                                                        >
                                                            <Radio.Button value="ABLE">ABLE</Radio.Button>
                                                            <Radio.Button value="UNABLE">UNABLE</Radio.Button>
                                                            <Radio.Button value="LATE">ARRIVE LATE</Radio.Button>
                                                            <Radio.Button value="EARLY">LEAVE EARLY</Radio.Button>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <Form.Item noStyle>
                                                        <Input type="text"
                                                            placeholder="Please provide reason if unable, arrive late or leave early"
                                                            onChange={(e) => {
                                                                let activities = this.state.activities;
                                                                activities[_.findIndex(activities, { guid: item.guid })]["remark"] = e.target.value
                                                                this.setState({ activities: activities })
                                                            }} />
                                                    </Form.Item>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                                )
                            }
                            {requiredisclaimer && <Form.Item>
                                <Checkbox
                                    checked={this.state.agree}
                                    onChange={(e) => { this.setState({ agree: e.target.checked }) }}>
                                    {(globalContext.length > 0 && globalContext.find(element => element.syscode == 'ENROLTEXT')) ?
                                        (<>{JSON.parse(globalContext.find(element => element.syscode == 'ENROLTEXT').value).pretext} <a href={(JSON.parse(globalContext.find(element => element.syscode == 'ENROLTEXT').value).url).replace('<eventid>', this.state.eventid)} target="_blank">{JSON.parse(globalContext.find(element => element.syscode == 'ENROLTEXT').value).linktext}</a></>)
                                        :
                                        (<>By signing up for an account on Youthbank, you agreed to the <a href={"/privacypolicy"} target="_blank">Terms of use and Privacy Policy</a></>)}
                                </Checkbox>
                            </Form.Item>}
                            <p className="error-message">{this.state.errorMsg}</p>
                        </Form>
                    </Modal>

                </div>

            </>

        )
    }
}
OpportunityDetail.contextType = GlobalContext;

