import React, { Component} from 'react';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers,faClock,faSchool,faGrinHearts} from '@fortawesome/free-solid-svg-icons'
import { Container,Row,Col } from 'react-bootstrap';
import './Section10.scss';
import CountUp from 'react-countup';
import api from '../../services/api'
import _ from 'underscore/underscore'

export default class Section10 extends Component  { 
    constructor(props){
        super(props)
        this.state = {
            isMobile: window.innerWidth<1024,
            stats: []
        }
    }
    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)   

        let res = await api.get(`api/mobile/GetStatsData`)
        if (res.data.StatusCode==1){
            if (res.data.Data.length>0){
                console.log("AAAA",res.data.Data)
                this.setState({
                    stats: res.data.Data
                })
            }
        }
    }
    handleResize =(e)=>{
        this.setState({isMobile:window.innerWidth<1024})
    }
    getStats = (stateName)=>{
        let data = _.where(this.state.stats,{statename: stateName})
        if (data.length>0){
            return data[0]
        }else{
            return {}
        }
    }
    render(){
        let isMobile = this.state.isMobile;
        return (
            <>
                <div className="Section10">
                <Container className="container-nopad-nomargin">
                        <Row>
                            <Col>
                                <div className="section-impact-container">
                                    <Container>
                                        <Row>
                                            <Col lg="6" xs="12" className="section-impact-col1">
                                                <div className="section-impact-header">Our Impact</div>
                                                <div className="section-impact-content">We engage our youth to be resilient, innovative in serving others, and to be entrepreneurs rooted to Singapore as their home.</div>
                                            </Col>
                                            <Col lg="6" xs="12">
                                               <Container>
                                                   <Row style={{paddingBottom: "10px"}}>
                                                       <Col lg="6" xs="12" className="section-impact-boxes">
                                                            <FontAwesomeIcon icon={faUsers} className="section-impact-icons" />
                                                            <div className="section-impact-boxes-text1">
                                                                {/* 20,075 */}
                                                                <CountUp
                                                                    start={0}
                                                                    end={this.getStats("VOLUNTEER ENGAGED")["statvalue"]}
                                                                    duration={15}
                                                                    separator=","
                                                                />
                                                            </div>
                                                            <div className="section-impact-boxes-text2">Volunteer Engaged</div>
                                                       </Col>
                                                       <Col lg="6" xs="12" className="section-impact-boxes">
                                                            <FontAwesomeIcon icon={faClock} className="section-impact-icons" />
                                                            <div className="section-impact-boxes-text1">
                                                                {/* 768,845 */}
                                                                <CountUp
                                                                    start={0}
                                                                    end={this.getStats("VOLUNTEER HOURS")["statvalue"]}
                                                                    duration={15}
                                                                    separator=","
                                                                />
                                                            </div>
                                                            <div className="section-impact-boxes-text2">Volunteer Hours</div>
                                                       </Col>
                                                   </Row>
                                                   <Row>
                                                       <Col lg="6" xs="12" className="section-impact-boxes">
                                                            <FontAwesomeIcon icon={faSchool} className="section-impact-icons" />
                                                            <div className="section-impact-boxes-text1">
                                                                {/* 153 */}
                                                                <CountUp
                                                                    start={0}
                                                                    end={this.getStats("PARTNERS")["statvalue"]}
                                                                    duration={15}
                                                                    separator=","
                                                                />
                                                            </div>
                                                            <div className="section-impact-boxes-text2">Partners</div>
                                                       </Col>
                                                       <Col lg="6" xs="12" className="section-impact-boxes">
                                                            <FontAwesomeIcon icon={faGrinHearts} className="section-impact-icons" />
                                                            <div className="section-impact-boxes-text1">
                                                                {/* 3,754 */}
                                                                <CountUp
                                                                    start={0}
                                                                    end={this.getStats("BENEFICIARIES")["statvalue"]}
                                                                    duration={15}
                                                                    separator=","
                                                                />
                                                            </div>
                                                            <div className="section-impact-boxes-text2">Social Impact</div>
                                                       </Col>
                                                   </Row>
                                               </Container>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        </Row>
                </Container>
                
                </div>
            </>
        )
    }
}