
import BasePageComponent from '../components/BasePageComponent';
import { Container, Row, Col } from 'react-bootstrap';
import "./Home.scss"
import "../../src/assets/Global.scss"
import MyMenu from "../components/MyMenu"
import HomeSection1 from '../components/home/Section1'
import HomeSection2 from '../components/home/Section2'
import HomeSection3 from '../components/home/Section3'
import HomeSection4 from '../components/home/Section4'
import HomeSection5 from '../components/home/Section5'
import HomeSection6 from '../components/home/Section6'
import HomeSection7 from '../components/home/Section7'
import HomeSection8 from '../components/home/Section8'
import HomeSection9 from '../components/home/Section9'
import HomeSection10 from '../components/home/Section10'
import HomeSection11 from '../components/home/Section11'
import HomeSectionBanner from '../components/home/SectionBanner'
import Toolbar, { PageComponent } from '../components/Toolbar'
import ModalLogin from '../components/ModalLogin'
import ModalSignup from '../components/ModalSignup'
import ModalCookie from '../components/ModalCookie'
import LoaderOverlay from '../components/LoaderOverlay'
//import VideoJS from '../components/videojs'
//import * as vjsyt from 'videojs-youtube'
import React from 'react';


export default class Home extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            sections: [],
            // toolbar: false
        }
        this.playerRef = React.createRef(null);
    }
    componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        const params = new URLSearchParams(window.location.search)
        const p = params.get("reset");
        if (p != null) {
            this.setState({
                modalLogin: true,
                resetPasswordToken: p
            })
        }
        let t = params.get("t");
        // this.setState({toolbar:t=="YAZ1mmrtVBCfMuCtaD9D0CFJkPLbybsQoDqMzM6zghEim6qycKwEPqRtJHXaG7QN"})
    }

    /* videoJsOptions = {
        autoplay: true,
        //controls: true,
        responsive: true,
        //fluid: true,
        //techOrder: ['youtube'],
        width: 640,
        height: 264,
        liveui: true,
        sources: [{
            src: 'https://www.youtube.com/XWq5kBlakcQ',
            type: 'video/youtube'
        }]
    };

    handlePlayerReady = (player) => {
        this.playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            VideoJS.log('player is waiting');
        });

        player.on('dispose', () => {
            VideoJS.log('player will dispose');
        });
    }; */


    render() {
        // return(
        return super.render(
            <>
                {/* Section 1 */}
                {/* <MyMenu parent={this} />
            <ModalLogin parent={this} />
            <ModalSignup parent={this} />
            <ModalCookie parent={this}/>
            <LoaderOverlay parent={this}/> */}
                <HomeSection1 pageName={"HomeSection1"} parent={this} />
                {/* <VideoJS options={this.videoJsOptions} onReady={this.handlePlayerReady} /> */}
                <Container className="Home">
                    <Row>
                        <Col>
                            <HomeSectionBanner></HomeSectionBanner>
                        </Col>
                    </Row>
                    {/* Section 2 */}
                    <Row>
                        <Col>
                            <HomeSection2 pageName={"HomeSection2"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection3 pageName={"HomeSection3"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection4 pageName={"HomeSection4"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection5 pageName={"HomeSection5"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection6 pageName={"HomeSection6"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection7 pageName={"HomeSection7"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection8 pageName={"HomeSection8"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection9 pageName={"HomeSection9"} parent={this} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <HomeSection10 pageName={"HomeSection10"} parent={this} />
                        </Col>
                    </Row>
                    {/* <Row>
                    <Col>
                        <HomeSection11 pageName={"HomeSection11"} parent={this}/>
                    </Col>
                </Row> */}

                </Container>
                {/* {this.state.toolbar &&  */}
                {/* <>
            <Toolbar parent={this}/>
            </> */}
                {/* } */}
            </>
        )
    }
}