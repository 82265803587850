import {
    Switch,
    Route,
  } from "react-router-dom";
  


import Home from './pages/Home'
import Events from './pages/Events'
import EventDetail from './pages/Eventdetail'
import Service from './pages/Service'
import FAQ from './pages/Faq'
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import News from "./pages/News";
import NewsDetail from "./pages/Newsdetail"
import Training from "./pages/Training"
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AttendanceTaking from "./pages/AttendanceTaking";
import Imsg from "./pages/Imsg";
import Disclaimer from "./pages/Disclaimer";
import EventDisclaimer from "./pages/EventDisclaimer";
import Questionnaire from "./pages/Questionnaire";
import ServicesList from "./pages/ServicesList";
import Sso from "./pages/Sso";
import OpportunityDetail from "./pages/Opportunitydetail"
import Statement from "./pages/Statement"
import InterestSurvey from "./pages/InterestSurvey";

export default function Routes() {
      
    return (
        <Switch>
        <Route path="/events">
          <Events />
        </Route>
        <Route path="/workshops">
          <Training />
        </Route>
        <Route path="/eventdetail"  component={EventDetail}>
        </Route>
        <Route path="/opportunitydetail"  component={OpportunityDetail}>
        </Route>
        {/* <Route path="/service">
          <Service />
        </Route> */}
        <Route path="/service/:servicename" component={Service}>
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/aboutus">
          <Aboutus />
        </Route>
        <Route path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/contactus">
          <Contactus />
        </Route>
        <Route path="/news">
          <News />
        </Route>
        <Route path="/newsdetail" component={NewsDetail}>
        </Route>
        <Route path="/at">
          <AttendanceTaking />
        </Route>
        <Route path="/imsg">
          <Imsg />
        </Route>
        <Route path="/disclaimer">
          <Disclaimer />
          </Route>
        <Route path="/eventdisclaimer" component={EventDisclaimer}>
        </Route>
        <Route path="/questionnaire">
          <Questionnaire />
        </Route>
        <Route path="/interestsurvey">
          <InterestSurvey />
        </Route>
        <Route path="/serviceslist">
          <ServicesList/>
        </Route>
        <Route path="/statement">
          <Statement/>
        </Route>
        <Route path="/sso">
          <Sso/>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    );
  }
  