import React, { Component } from 'react';
// import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faBars } from '@fortawesome/free-solid-svg-icons'
import ReactDOM from 'react-dom';
import './Section1.scss';
import SectionComponent from '../SectionComponent';
import Globals from '../../Globals'
// import Carousel from 'react-bootstrap/Carousel'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Button from 'react-bootstrap/Button';
import MyDrawer from '../../components/MyDrawer';

export default class Section1 extends SectionComponent {
    constructor(props) {
        super(props)
        this.state = {
            sectionName: "HomeSection1",
            attributes: { data1: [{ id: 1, text1: "", text2: "", image: "" }] }, // Structure ONLY if no backend
            modalLogin: false,
            modalSignup: false,
            parent: props.parent,
            // drawerOpen: false
        }
    }
    // componentDidMount(){
    //  IMPORTANT: DONOT ENABLE THIS OTHERWISE BASE COMPONENT WILL NOT WORK
    // }
    // Login = () => {
    //     this.state.parent.setState({ modalLogin: true })
    // }
    // openDrawer = () =>{
    //     this.setState({drawerOpen:true})
    // }
    // openMenu = () =>{
    //     this.setState({drawerOpen:!this.state.drawerOpen})
    // }
    // Signup = (e) => {
    //     console.log("Sign up")
    //     e.preventDefault();
    //     this.state.parent.setState({ modalSignup: true })
    // }
    render() {
        // let link1 = "https://ydmsdesign.bizpoint-intl.com/wp-content/uploads/2021/06/youthbank_logo3-4.png";
        let link1 = "/youthbank_logo3-4.png";

        return (
            <>
                <div className="Section1" style={{position:'relative'}}>
                    <img className="ydmslogo" src={link1} />
                    {/* {this.state.attributes.data1.length>0 &&  */}
                    <Carousel
                        responsive={Globals.carouselBanner.responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        className="carousel-events"
                    >

                        {this.state.attributes.data1.map((d, i) => (

                            <div key={i}>
                                <img className="carousel-image" src={d.image} />
                                <div className="carousel-text">{d.text1}</div>
                                <div className="carousel-text2">{d.text2}</div>

                                <div className="overlay"></div>
                            </div>



                        )
                        )}

                    </Carousel>


                    {/* {!Globals.userdata.accountid && <React.Fragment>
                        <button className="button-red-signup" onClick={this.Signup}>Sign Up</button>
                        <button className="button-yellow-login"  onClick={this.Login} >
                            Login
                        <FontAwesomeIcon icon={faSignInAlt} />
                        </button>
                    </React.Fragment>
                    } */}

                    <div style={{position:'absolute',zIndex:9999,bottom:"30px",left:"30px"}}>
                        <div className="section-footer-subheader" style={{color:"white",marginBottom:"5px"}}>Download Youthbank from</div>
                        <a href="https://apps.apple.com/sg/app/youthbank-v2/id1589713212">
                        <img src="https://ydms-public.s3.ap-southeast-1.amazonaws.com/assets/download-app-store.png" className="img-apple-download" style={{width:"110px"}}/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.app.youthbank">
                        <img src="https://ydms-public.s3.ap-southeast-1.amazonaws.com/assets/download-google-play.png" className="img-android-download" style={{marginLeft:"10px",width:"110px"}}/>
                        </a>
                    </div>

                </div>

            </>
        )
    }
}