import BasePageComponent from '../components/BasePageComponent'
import "./Disclaimer.scss"


export default class Disclaimer extends BasePageComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { isMobile,data } = this.state;
        return super.render(
            <div className="Disclaimer">
            <h1>Discliamer</h1>
            </div>
        )
    }
}