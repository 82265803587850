import React, { Component} from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAlt, faEye, faPlus,faWindowClose,
    faClone,faEdit,faUndo,faSave,faTrash} from '@fortawesome/free-solid-svg-icons'

import HomeSection1 from '../components/home/Section1'
import HomeSection2 from '../components/home/Section2'
import HomeSection3 from '../components/home/Section3'
import HomeSection4 from '../components/home/Section4'
import HomeSection5 from '../components/home/Section5'
import HomeSection6 from '../components/home/Section6'
import HomeSection7 from '../components/home/Section7'
import HomeSection8 from '../components/home/Section8'
import HomeSection9 from '../components/home/Section9'
import HomeSection10 from '../components/home/Section10'
import HomeSection11 from '../components/home/Section11'
import { LensTwoTone } from '@material-ui/icons';


import ToolbarModal from './ToolbarModal';
import _ from 'underscore/underscore'
import api from '../services/api';

const ReactGridLayout = WidthProvider(RGL);
const components = {
    HomeSection1: HomeSection1,
    HomeSection2: HomeSection2,
    HomeSection3: HomeSection3,
    HomeSection4: HomeSection4,
    HomeSection5: HomeSection5,
    HomeSection6: HomeSection6,
    HomeSection7: HomeSection7,
    HomeSection8: HomeSection8,
    HomeSection9: HomeSection9,
    HomeSection10: HomeSection10,
    HomeSection11: HomeSection11,
};

export default class Toolbar extends Component  { 
    static defaultProps = {
        isDraggable: true,
        isResizable: true,
        items: 20,
        rowHeight: 30,
        onLayoutChange: function() {},
        cols: 12,
        verticalCompact:false
    };
    componentList = [
        "HomeSection1",
        "HomeSection2",
        "HomeSection3",
        "HomeSection4",
        "HomeSection5",
        "HomeSection6",
        "HomeSection7",
        "HomeSection8",
        "HomeSection9",
        "HomeSection10",
        "HomeSection11",
        "MenuServices",
        "FooterSection",
        // "AboutUs",
        // "ProgrammesListing",
        // "Workshops",
        // "News",
        // "ContactUs",
        // "Faq",
        // "PrivacyPolicy",
        // "Service"
    ]
    constructor(props){
        super(props)
        console.log("Toolbar",props)
        this.state = {
           openAddComponent: false,
           parent: props.parent,
           modalOpen: false,
           modalAttributes: [],
           componentName: "",
           editMode: false
        }
    }
    componentDidMount() {
        // require('../pages/Home.css')
        
        if (this.props.parent.state.sectionName){
            this.componentList.push(this.props.parent.state.sectionName)
        }
    
        
        require('../components/Toolbar.css')
        // document.body.addEventListener('click', this.clickElement, true); 
        // require('./Toolbar.css')
    }
    // clickElement =(e)=>{
    //     console.log(e)
    //     // e.target.innerHTML = "Hello world"
    // }
    onLayoutChange(layout) {
        this.props.onLayoutChange(layout);
    }
    onResize(layout, oldLayoutItem, layoutItem, placeholder) {
        // `oldLayoutItem` contains the state of the item before the resize.
        // You can modify `layoutItem` to enforce constraints.
    
        if (layoutItem.h < 3 && layoutItem.w > 2) {
          layoutItem.w = 2;
          placeholder.w = 2;
        }
    
        if (layoutItem.h >= 3 && layoutItem.w < 2) {
          layoutItem.w = 2;
          placeholder.w = 2;
        }
    }
    dragClick = ()=>{
        
        this.props.parent.setState({dragMode:!this.props.parent.state.dragMode})
        console.log(this.props.parent.state.dragMode)
    }
    addComponent = ()=>{
        this.setState({openAddComponent: !this.state.openAddComponent})
    }
    menuClick = (e)=>{
        // alert(idx)
        const idx = e.target.attributes.idx.value
        let pageName = this.componentList[idx]
        // console.log(this.props.parent.state.sections[pageName].state.attributes)
        // console.log(this.props.parent.state.sections)
        if (this.props.parent.state.sectionName){
            pageName = this.props.parent.state.sectionName
        }
        if (this.props.parent.state.sections[pageName]==undefined){
            alert("No Attributes to set, data is coming from backend")
            this.setState({
                editMode:!this.state.editMode,
                openAddComponent: !this.state.openAddComponent
            })
            return
        }
        
        let data = []
        let attributes = this.props.parent.state.sections[pageName].state.attributes
        for (let i in attributes){
            data.push(attributes[i])
        }
        this.setState({
            editMode:!this.state.editMode,
            openAddComponent: !this.state.openAddComponent,
            modalOpen:true,
            modalAttributes: data,
            componentName: pageName
        })

        // const idx = e.target.attributes.idx.value
        // this.setState({openAddComponent: false})
        // if (typeof this.props.parent.sectionClick == "function"){
        //     const pageName = this.componentList[idx]
        //     this.props.parent.sectionClick(pageName)
        // }
    }
    copyComponent =()=>{
        if (typeof this.props.parent.copyComponent == "function"){
            this.props.parent.copyComponent()
        }
    }
    removeComponent =()=>{
        if (typeof this.props.parent.removeComponent == "function"){
            this.props.parent.removeComponent()
        }
    }
    editLabel = ()=>{
        // let editMode = this.state.parent.state.editMode
        // this.state.parent.setState({editMode:!editMode})
        // open={this.props.open}

        
        this.setState({
            editMode:!this.state.editMode,
            openAddComponent: !this.state.openAddComponent
        })
       
        // let componentSelected = this.state.parent.state.componentSelected
        // if (componentSelected.length!==1){
        //     alert("Select only one component to amend")
        //     return
        // } 
        // let componentName = componentSelected[0]
        // let sectionSelected = this.props.parent.state.sections[componentName]
        // let data = []
        // for (let i in sectionSelected.state.attributes){
        //     data.push(sectionSelected.state.attributes[i])
        // }
        // this.setState({
        //     modalOpen:true,
        //     modalAttributes: data,
        //     componentName: componentName
        // }) 
    }
    contentEditableOnInput = (e)=>{
        console.log(e.target.attributes['data-field'].value) //.attributes("data-field").value)
        //console.log(e.target.innerHTML)
    }
    saveLayout = ()=>{
        if (typeof this.state.parent.saveLayout == "function"){
            this.state.parent.saveLayout()
        }
    }
    closeModal = ()=>{
        this.setState({modalOpen: false})
    }
    saveAttributes = ()=>{
        let data = {}
        let ctr = 0
        for (let i in this.state.modalAttributes){
            ctr=ctr+1
            let attrName = "data" + ctr
            data[attrName] =  _.clone(this.state.modalAttributes[i])
        }
        let param = {
            pageName: this.state.componentName,
            jsonPage: data
        }
        // console.log(param)
        api.post("/api/Common/Savepage",param).then((res)=>{
            if (res.data.StatusCode == 1){
                alert("Section " + this.state.componentName + " saved!")
            }else{
                alert("Error Section " + this.state.componentName + " NOT saved!!!")
            }
        })
    }
    render(){
        const layout = [
            {i: 'toolbar', x: 0, y: 0, w: 20, h: 20}
        ];
        return (
            <>
            <div key="toolbar" style={style.toolbar}>
                <button onClick={this.editLabel} style={style.button}>
                    {!this.state.editMode && 
                        <FontAwesomeIcon icon={faEdit}/> 
                    }
                    {this.state.editMode && 
                        <FontAwesomeIcon icon={faUndo}/> 
                    }
                </button>

                {this.state.openAddComponent && 
                <div style={style.menuComponents}>
                    <ul className="drop-down">
                        {this.componentList.map((comp,idx)=>(
                            <li style={style.menuItem} idx={idx} onClick={this.menuClick} key={idx}>{comp}</li>
                        ))}
                        {/* <li style={style.menuItem} onClick={this.menuClick}>Menu-item-1</li>
                        <li style={style.menuItem}>Menu-item-2</li>
                        <li style={style.menuItem}>Menu-item-3</li> */}
                    </ul>
                </div>
                }
            </div>

            <ToolbarModal 
                closeModal={this.closeModal} 
                // isOpen={this.state.modalOpen} 
                parent={this}
                modalAttributes={this.state.modalAttributes}
               
            />
           
            </>
        )
    }
}

const style = {
    toolbar: {
        position: "fixed",
        bottom: "20px",
        right:"30%",
        border: '1px solid grey',
        height:"35px",
        width:"300px",
        padding:"2px",
        background: "#151515",
        borderRadius: "3px"
    },
    button: {
        background: "transparent",
        border: "1px solid grey",
        color: "white",
        marginTop: "1px",
        width:"30px"
    },
    menuComponents:{
        position:"relative",
        bottom:"590px",
        // top:"135px"
    },
    menuItem:{
        fontSize:"12px",
        background: "#151515",
        color:"white",
        cursor: "pointer"
    }
    
}


const BindSectionOnInput = (e,section) => {
    const dataField = e.target.attributes['data-field'].value;
    const dataText = e.target.innerHTML
    section.state.data[dataField] = dataText
    window.localStorage.setItem(section.constructor.name,JSON.stringify(section.state.data))
}


export const PageComponent = (data)=>{
    console.log("BindSection",data.parent)
    const Page = components[data.pageName] 
    return <Page BindSectionOnInput={BindSectionOnInput} parent={data.parent}/>  
}
