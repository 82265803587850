import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./Contactus.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faFax, faMapMarkedAlt, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlus, faGooglePlusG, faInstagram, faTwitter, faFacebook, faTiktok, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import MyMenu from "../components/MyMenu"
import MyDrawer from '../components/MyDrawer'
import StandardHeader from '../components/StandardHeader';
import 'antd/dist/antd.css';
import { Form, Input, Button, Select, Divider } from 'antd';
import GoogleMapReact from 'google-map-react';
import BasePageComponent from '../components/BasePageComponent';
import api from '../services/api';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

// Begin Resolve missing graphic issue
import Leaflet from 'leaflet'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png' 
import iconUrl from 'leaflet/dist/images/marker-icon.png' 
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import _ from 'underscore/underscore'

Leaflet.Icon.Default.imagePath = '../node_modules/leaflet' 
delete Leaflet.Icon.Default.prototype._getIconUrl 
Leaflet.Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl, })
// End Resolve missing graphic issue

export default class Contactus extends BasePageComponent {
    constructor(props) {
        super(props)
        this.state = {
            isMobile: window.innerWidth < 1024,
            subject: "",
            name: "",
            email: "",
            msg: "",
            // textEditor
            sections: [],
            sectionName: "ContactUs",
            attributes: { 
                data1: [{ id: 1, text: "",url: "",imageurl: "",type:"" }], // header
            },
            //eof textEditor
            emails: [
                {text: "General inquiries",email:"admin@heartware-network.org", type:"general"},
                {text: "Heartware Tuition Programme",email:"hwtpadmin@heartware-network.org",type:"programmes"},
                {text: "Heartware NDP Hospitality Management",email:"hwndpadmin@heartware-network.org",type:"others1"},
                {text: "Heartware Support Our Pioneers",email:"hwsopadmin@heartware-network.org",type:"others2"},
                {text: "Heartware Academy",email:"hwa.admin@heartware-network.org",type:"others3"},
                {text: "President’s Challenge Volunteer Drive inquiries",email:"hwpcadmin@heartware-network.org",type:"others4"}
            ]
        }
    }

    handleResize = (e) => {
        console.log(window.innerWidth)
        this.setState({ isMobile: window.innerWidth < 1024 })
    }
    submit = (e) => {
        console.log(this.state)
        this.setState({error: false})
        if(this.state.subject == "" || this.state.name == "" || this.state.email == "" || this.state.msg == "")
        {
            this.setState({error: true, errorMsg: 'Please fill in all details.'})
            return;
        }

        let yemail = _.where(this.state.emails,{type:this.state.subject})
        // console.log(email[0]['email'])

        var info = {
            subject : this.state.subject,
            name : this.state.name,
            email : this.state.email,
            msg : this.state.msg,
            yemail: yemail
        }

        try {
            let res = api.post('/api/Front/SubmitContactUs', info)
                .then((res) => {
                    console.log("SIGNUP", res)
                    if (res.data.StatusCode == 1) {
                        this.setState({
                            name: "",
                            email: "",
                            msg: "",
                            error: true,
                            errorMsg: "Your message has been sent.",
                        })
                    } else {
                        this.setState({ error: true, errorMsg: res.data.Message })
                    }
                })
                .then((res2) => { console.log("ERROR-MIKE2: ", res2) })
                .catch((err) => {
                    console.log("ERROR-MIKE3: ", err)
                    this.setState({ error: true })
                })
            console.log(res)
        } catch (err) {
            console.log("ERROR-MIKE: ", err)
            this.props.parent.setState({ error: true })
        }


    }
    render() {
        let isMobile = this.state.isMobile;
        const defaultProps = {
            center: {
                lat: 1.29004,
                lng: 103.830923
            },
            zoom: 11
        };
        const AnyReactComponent = ({ text }) => <div>{text}</div>;

        return super.render(
            <>
            {this.state.isLoaded && 
            <>
                <div className="otherpagebody">
                    {/* Section standard header */}
                    {/* <StandardHeader
                        text1="WE'D LOVE TO HEAR FROM YOU"
                        text2="Stay Connected"
                        text3="We are happy to help you with your questions. Please email us using the form below and we will respond to you as soon as possible."
                    /> */}
                     <StandardHeader
                        text1={this.getFieldFromType('data1','header1-text','text')}
                        text2={this.getFieldFromType('data1','header2-text','text')}
                        text3={this.getFieldFromType('data1','header3-text','text')}
                    />

                    <Container style={{ marginTop: "3%", backgroundColor: "#fff" }} className="contactus-main-container">
                        <Row>
                            <Col lg="6" xs="12">
                                {/* <div style={{ height: '45vh', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "" }}
                                                defaultCenter={defaultProps.center}
                                                defaultZoom={defaultProps.zoom}
                                            >
                                                <AnyReactComponent
                                                    lat={1.29004}
                                                    lng={103.830923}
                                                    text="My Marker"
                                                />
                                            </GoogleMapReact>
                                            
                                        </div> */}
                                <MapContainer center={[1.29004, 103.830923]} zoom={18} scrollWheelZoom={false} style={{height:"300px"}}>
                                    <TileLayer
                                        attribution='<img src="https://www.onemap.gov.sg/docs/maps/images/oneMap64-01.png" style="height:20px;width:20px;"/> OneMap | Map data &copy; contributors, <a href="http://SLA.gov.sg">Singapore Land Authority</a>'
                                        url="https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={[1.29004, 103.830923]}>
                                        <Popup>
                                            Heartware Network
                                        </Popup>
                                    </Marker>
                                </MapContainer>
                                <Container style={{ padding: "0px" }}>
                                    <Row>
                                        <Col lg="6" xs="12">
                                            <div className="contactus-subtitle">SOCIAL NETWORKS</div>
                                            <div className="contactus-list-item"><a href={"mailto:" + this.getFieldFromType('data1','contact-email','url')} style={{color:"#7A7A7A"}}><FontAwesomeIcon icon={faEnvelope} />{this.getFieldFromType('data1','contact-email','url')}</a></div>
                                            <div className="contactus-list-item"><a href={this.getFieldFromType('data1','social-facebook','url')} style={{color:"#7A7A7A"}}><FontAwesomeIcon icon={faFacebook} />{this.getFieldFromType('data1','social-facebook','url')}</a></div>
                                            <div className="contactus-list-item"><a href={this.getFieldFromType('data1','social-instagram','url')} style={{color:"#7A7A7A"}}><FontAwesomeIcon icon={faInstagram} />{this.getFieldFromType('data1','social-instagram','url')}</a></div>
                                            <div className="contactus-list-item"><a href={this.getFieldFromType('data1','social-tiktok','url')} style={{color:"#7A7A7A"}}><FontAwesomeIcon icon={faTiktok} />{this.getFieldFromType('data1','social-tiktok','url')}</a></div>
                                            <div className="contactus-list-item"><a href={this.getFieldFromType('data1','social-linkedin','url')} style={{color:"#7A7A7A"}}><FontAwesomeIcon icon={faLinkedin} />{this.getFieldFromType('data1','social-linkedin','url').length>32 ? this.getFieldFromType('data1','social-linkedin','url').slice(0,33)+String.fromCharCode(10)+this.getFieldFromType('data1','social-linkedin','url').slice(33) : this.getFieldFromType('data1','social-linkedin','url')}</a></div>
                                        </Col>
                                        <Col lg="6" xs="12">
                                            <div className="contactus-subtitle">REACH US THROUGH</div>
                                            <div className="contactus-list-item"><FontAwesomeIcon icon={faMapMarkerAlt} />{this.getFieldFromType('data1','reachus-address','url')}</div>
                                            <div className="contactus-list-item"><FontAwesomeIcon icon={faPhone} />{this.getFieldFromType('data1','reachus-phone','url')}</div>
                                            <div className="contactus-list-item"><FontAwesomeIcon icon={faFax} />{this.getFieldFromType('data1','reachus-phone2','url')}</div>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container style={{ padding: "0px" }}>
                                    <Row>
                                        <div className="contactus-subtitle">EMAIL</div>
                                        <Col lg="6" xs="12">
                                            <div className="contactus-email-list-item">
                                                <div>General inquiries:</div>
                                                <div><a href="mailto:admin@heartware-network.org">{this.getFieldFromType('data1','email-general','url')}</a></div>
                                            </div>
                                            <div className="contactus-email-list-item">
                                                <div>Heartware Tuition Programme:</div>
                                                <div><a href="mailto:hwtpadmin@heartware-network.org">{this.getFieldFromType('data1','email-tuition','url')}</a></div>
                                            </div>
                                            <div className="contactus-email-list-item">
                                                <div>Heartware NDP Hospitality Management:</div>
                                                <div><a href="mailto:hwndpadmin@heartware-network.org">{this.getFieldFromType('data1','email-ndp-hospitality','url')}</a></div>
                                            </div>
                                        </Col>
                                        <Col lg="6" xs="12">
                                            <div className="contactus-email-list-item">
                                                <div>Heartware Support Our Pioneers:</div>
                                                <div><a href="mailto:hwsopadmin@heartware-network.org">{this.getFieldFromType('data1','email-heartware-support','url')}</a></div>
                                            </div>
                                            <div className="contactus-email-list-item">
                                                <div>Heartware Academy :</div>
                                                <div><a href="mailto:hwa.admin@heartware-network.org">{this.getFieldFromType('data1','email-academy','url')}</a></div>
                                            </div>
                                            <div className="contactus-email-list-item">
                                                <div>President’s Challenge Volunteer Drive inquiries:</div>
                                                <div><a href="mailto:hwpcadmin@heartware-network.org">{this.getFieldFromType('data1','email-president-challenge','url')}</a></div>
                                            </div>

                                        </Col>
                                    </Row>
                                </Container>

                            </Col>
                            <Col lg="6" xs="12" className="contact-us-send-us" >
                                <div className="contactus-form-title">Send Us A Message</div>
                                <Divider className="contactus-form-title-divider" />
                                {this.state.error &&
                                    <p style={{ color: "red" }}>{this.state.errorMsg}</p>
                                }
                                <Form
                                    layout="vertical"
                                    size="large"
                                >
                                    <Form.Item label="Message's Subject" required>
                                        <Select
                                            placeholder="Please select topic"
                                            allowClear
                                            onChange={(e) => {this.setState({subject: e})}}
                                        >
                                            {this.state.emails.map((d)=>{
                                                return <Select.Option value={d.type}>{d.text}</Select.Option>        
                                            })}
                                            {/* <Select.Option value="general">General inquiries</Select.Option>
                                            <Select.Option value="programmes">Programmes</Select.Option>
                                            <Select.Option value="others">Others</Select.Option> */}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Full Name" required>
                                        <Input placeholder="Please enter your name" 
                                        value = {this.state.name}
                                        onChange = {(e) => {this.setState({name: e.target.value})}}/>
                                    </Form.Item>
                                    <Form.Item label="Email Address" required>
                                        <Input placeholder="Please enter your email" 
                                        value = {this.state.email}
                                        onChange = {(e) => {this.setState({email: e.target.value})}}/>
                                    </Form.Item>
                                    <Form.Item label="Message" required>
                                        <Input.TextArea rows={10}
                                        value = {this.state.msg}
                                        onChange = {(e) => {this.setState({msg: e.target.value})}}/>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" onClick={this.submit}>Send Message</Button>
                                    </Form.Item>
                                </Form>


                            </Col>
                        </Row>
                    </Container>

                </div>
            
            </>
            }
            </>

        )
    }
}