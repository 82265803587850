import BasePageComponent from '../components/BasePageComponent'
import "./Questionnaire.scss"
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import api from '../services/api';
import moment from 'moment';
import * as Survey from "survey-react";
import "survey-react/modern.css";
import {nominationData} from '../data/nominationData';
import Globals from '../Globals'  
import { getEnvVars } from '../Environment'

Survey.StylesManager.applyTheme("modern");
// const survey = new Survey.Model(nominationData);
var mylocalization = Survey.surveyLocalization.locales["en"];
mylocalization.cleanCaption = "Delete";


const { apiUrl } = getEnvVars();
let temporaryFilesStorage = {}

export default class AttendanceTaking extends BasePageComponent {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: window.innerWidth < 1024,
            pageSelected: "listing",
            modules: [],
            survey: [],
            assessmentGuid: "",
            completed: false,
            //surveyInterval: 0
        }
    }
    
    async componentDidMount(...args) {
        super.componentDidMount.apply(this, args);
        const params = new URLSearchParams(window.location.search)
        
        let acctGuid = Globals.userdata.guid  //"f40bb6ea-11db-4d08-ad5a-3302cdc493ec" 
        // alert(acctGuid)
        let res = await api.get(`/api/questionbank/GetAssessmentListingByAccount?accountguid=${acctGuid}`)
        this.setState({modules:res.data.data})
        console.log(res.data.data)
        
        //http://localhost:3000/questionnaire?assuid=4b4ce22e-98bc-4353-9cef-df73cc007fc5
        const assuid = params.get("assuid");
        if (assuid!=null){
            this.startModule({assessmentguid: assuid})
        }
    }

    submit = async ()=>{
        this.submitSurvey(4)
    }

    save = async ()=>{
        this.submitSurvey(3)
        alert("Save Successfully!")
    }

    submitSurvey = async (status)=>{
        // 1 - Havent started 
        // 2 - Started 
        // 3 - Incomplete 
        // 4 - Complete 
        let survey = this.state.survey 
        let assessmentGuid = this.state.assessmentGuid
        // let acctId = 21262 //Globals.userdata.accountid
        let acctId = Globals.userdata.accountid  
        let param = {
            "accountid": acctId,
            "assessmentguid": assessmentGuid,
            "result":survey.data,
            "status": status
        }
        // console.log(survey.data)
        let res = await api.post(`api/questionbank/SubmitSurvey`,param)
        if (res.data.StatusCode==1){
            if (status==4){
                // alert(this.state.surveyInterval)
                //clearInterval(this.state.surveyInterval)
                this.setState({
                    completed: true,
                    //surveyInterval:0
                })
            }
            
        }
    }

     startModule = async (d)=>{
        // console.log("startModule",d)
        
        let acctGuid = Globals.userdata.guid //"f40bb6ea-11db-4d08-ad5a-3302cdc493ec"
        temporaryFilesStorage = {}
        let res = await api.get(`api/questionbank/GetTemplateJSON?assessmentguid=${d.assessmentguid}&accountguid=${acctGuid}&application=1`)
        console.log(res.data) 
        if (res.data.StatusCode==0){
            alert(res.data.Message)
            return 
        }

        var status = res.data.surveystatus;
        
        //let question = {questions:res.data.surveyjson}
        /* let question = {
            "progressBarType": "buttons",
            "showProgressBar": "top",
            pages: res.data.surveyjson
            //questions: result.surveyjson
          } */
        var question = res.data.surveyjson;
        let survey = new Survey.Model(question)
        if (!res.data.data || Object.keys(res.data.data).length === 0){
            console.log('empty');
        }
        else{
            survey.data = res.data.data
        }

        if (status == 4){
            survey.mode = 'display';
        }
        
        console.log(question)
        this.setState({
            pageSelected:"entry", 
            survey: survey,
            assessmentGuid: d.assessmentguid,
            completed: status === 4 ? true: false
        })

        if (status != 4){
            this.submitSurvey(3) // Started

            /* let surveyInterval = setInterval(()=>{
                console.log("autosending...")
                if (!this.state.completed){
                    this.submitSurvey(3) // Auto saving, incomplete status    
                }
            },5000)
            this.setState({surveyInterval: surveyInterval}) */
        }
        

        survey
        .onUploadFiles
        .add(function (survey, options) {
            // Add files to the temporary storage
            if (temporaryFilesStorage[options.name] !== undefined) {
                temporaryFilesStorage[options.name].concat(options.files);
            } else {
                temporaryFilesStorage[options.name] = options.files;
            }
    
            // Load previews in base64. Until survey not completed files are loaded temporary as base64 in order to get previews
            var question = survey.getQuestionByName(options.name);
            var content = [];
            options
                .files
                .forEach(function (file) {
                    let fileReader = new FileReader();
                    fileReader.onload = function (e) {
                        content = content.concat([
                            {
                                name: file.name,
                                type: file.type,
                                content: fileReader.result,
                                file: file
                            }
                        ]);
                        if (content.length === options.files.length) {
                            //question.value = (question.value || []).concat(content);
                            options.callback("success", content.map(function (fileContent) {
                                return {file: fileContent.file, content: fileContent.content};
                            }));
                        }
                    };
                    fileReader.readAsDataURL(file);
                });
        });

        let assessmentGuid = this.state.assessmentGuid
        let acctGUID = Globals.userdata.guid
        
    survey
    .onComplete
    .add(function (result) {
        // In this handler we upload the files to the server from the temporary storage
        //alert("Uploading here....")
        // alert("Uploading files");
        console.log("Uploading files");

        // // You need here to wait all files to be uploaded
        // // And only then show the results
        // function onFilesUploaded() {
        //     document
        //         .querySelector('#surveyResult')
        //         .textContent = "Result JSON:\n" + JSON.stringify(result.data, null, 3);
        // }

        // temporaryFilesStorage keys are the question names
        var questionsToUpload = Object.keys(temporaryFilesStorage);
      

        var uploadedQuestionsCount = 0;
        questionsToUpload.forEach(function (questionName) {
            var question = survey.getQuestionByName(questionName);
            var filesToUpload = temporaryFilesStorage[questionName];

            var formData = new FormData();
            filesToUpload.forEach(function (file) {
                formData.append('Files', file);
                formData.append('FileName', `assessment#${assessmentGuid}#${acctGUID}`);
            });
            var xhr = new XMLHttpRequest();
            xhr.open("POST", apiUrl+"/api/questionbank/UploadFile"); 
            xhr.onload = function () {
                var data = JSON.parse(xhr.responseText);
                question.value = filesToUpload.map(function (file) {
                    return {
                        name: file.name,
                        type: file.type,
                        content: data[file.name]
                    };
                });
                uploadedQuestionsCount++;
                // If all files has been uploaded then show the results
                // if (uploadedQuestionsCount === questionsToUpload.length) {
                //     onFilesUploaded();
                // }
            };
            xhr.onerror = function () {
                question.value = [
                    {
                        name: "file1",
                        type: "image/jpeg",
                        content: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg"
                    }
                ];
                uploadedQuestionsCount++;
                // If all files has been uploaded then show the results
                // if (uploadedQuestionsCount === questionsToUpload.length) {
                //     onFilesUploaded();
                // }
            };
            xhr.send(formData);

        });


        // // If nothing to upload then show the results
        // if (0 === questionsToUpload.length) {
        //     onFilesUploaded();
        // }

    });


        
    }
    

    render() {
        const { isMobile,modules,pageSelected,survey } = this.state;
        return super.render(
            <div class="Questionnaire">
                
                {pageSelected=="listing" && 
                <>
                    <h1>My Assessments</h1>
                    {modules.length==0 && 
                    <p>No assessment found</p>
                    }
                    <ListGroup>
                        { modules.map((d) => (
                            <ListGroup.Item action onClick={()=>{
                                this.startModule(d)
                            }} >
                                <div class="module-header">{d.activityname} - {d.eventname}</div>
                                <p>{moment(d.availablefrom).format("DD/MM/YYYY")} - {moment(d.availableto).format("DD/MM/YYYY")}</p>
                            </ListGroup.Item>
                        ))
                        }
                    </ListGroup>
                </>
                }
                {pageSelected=="entry" &&
                <>
                 <Survey.Survey
                     model={survey}
                     onComplete={this.submit}
                 />
                 {!this.state.completed && <div className="col-center-content" style={{ marginTop: "60px" }}>
                 <Button onClick={this.save} style={{ width: "200px", height: "50px" }}>Save Progress</Button>
                        </div>
                 
            }

{/* {this.state.completed && <div className="col-center-content" style={{ marginTop: "60px" }}>
                 <Button onClick={this.save} style={{ width: "200px", height: "50px" }}>Retry</Button>
                        </div> 
                 
            }*/}
                </>
                }
                 
            </div>
          
        )
    }
}